import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class TwodDemosService {
  ardemoApiKey: any;
  constructor(private http: HttpClient) {
    this.ardemoApiKey = environment.ardemos_api_key;
  }
  getImmersionTwodDemobyID(immersionId: any) {
    return this.http.get(this.ardemoApiKey + "2ddemo/" + immersionId);
  }

  getAllActive2dDemos() {
    return this.http.get(this.ardemoApiKey + "2ddemos/active");
  }

  getAllDemosTags() {
    return this.http.get(this.ardemoApiKey + "2ddemotags");
  }

  GetAllUsecases() {
    return this.http.get(this.ardemoApiKey + "usecases");
  }

  getUsecaseById(useCaseId: any) {
    return this.http.get(this.ardemoApiKey + "usecase/" + useCaseId);
  }

  //Projects Related
  getProjectById(projectId: any) {
    return this.http.get(this.ardemoApiKey + "project/" + projectId);
  }

  getAllProjects() {
    return this.http.get(this.ardemoApiKey + "projects");
  }
}
