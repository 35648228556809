<!-- Skeleton Container starts -->
<section *ngIf="!ToggleAllUsecases">
  <div class="container">
    <div class="text-center">
      <ngx-skeleton-loader [theme]="{
          'border-radius': '20px',
          height: '40px',
          width: '200px',
          'margin-right': '18px',
          'background-color': '#131740',
          border: '1px solid #131740',
          'animation-duration': '2s'
        }">
      </ngx-skeleton-loader>
    </div>
    <div class="mt-3 text-center">
      <ngx-skeleton-loader [count]="skeletonCount" appearance="circle" [theme]="themeWidth">
      </ngx-skeleton-loader>
    </div>
    <div class="mt-3 text-center">
      <ngx-skeleton-loader [count]="skeletonCount" appearance="circle" [theme]="themeWidth">
      </ngx-skeleton-loader>
    </div>
  </div>
</section>
<!-- Skeleton Container End -->

<div class="container" *ngIf="ToggleAllUsecases">
  <h2 class="font-display text-jacarta-700 mb-8 text-center text-5xl dark:text-white py-2"
    style="text-shadow: 1px 1px #000000">
    USECASES
  </h2>
  <h2 class="font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white"></h2>
  <div class="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-3 usecaseContainer" id="usecaseContainer">
    <div *ngFor="let usecase of usecases_list">
      <a href="{{ arDemoHostURL }}/usecases" target="_blank" style="cursor: pointer">
        <div
          class="relative rounded-2.5xl border border-jacarta-100 bg-white p-6 shadow-[0_5px_0_0_#8358ff] transition-shadow hover:shadow-[0_16px_24px_-8px_rgba(131,88,255,.3)] dark:border-jacarta-700 dark:bg-jacarta-700">
          <div class="mb-2 inline-flex rounded-full p-3">
            <div>
              <img src="{{ usecase.cloudfront_url + usecase.usecase_video }}" style="border-radius: 5%" />
            </div>
          </div>
          <h3 class="font-display text-center mt-3 text-jacarta-700 mb-4 text-lg dark:text-white">
            {{ usecase.usecase_title }}
          </h3>
        </div>
      </a>
    </div>
  </div>

  <div class="py-3 text-center" id="buttonContainer" style="margin-top: 2rem">
    <a href="{{ arDemoHostURL }}/usecases" target="_blank"
      class="rounded-full mb-4 bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark mr-3">
      View complete list in the studio
    </a>
    <button *ngIf="usecases_list?.length > 6"
      class="rounded-full bg-white py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume viewMoreBtnClass"
      (click)="viewMoreUsecases()">
      <span *ngIf="usecaseViewStatus" class="font-display text-jacarta-700 text-md">
        View More &nbsp; <a class="fa fa-arrow-down fa-x"></a>
      </span>
      <span *ngIf="!usecaseViewStatus" class="font-display text-jacarta-700 text-md">View Less &nbsp;<a
          class="fa fa-arrow-up fa-1x"></a></span>
    </button>
  </div>
</div>