<main class="pt-[5.5rem] lg:pt-24">
	<ng-container *ngIf="!isDataFetched && !isErrorOccured; else show_else">
		<div class="spinnerCenterDiv" style="padding-top: 14rem; padding-top: 7rem">
			<div class="spinner-border text-danger" role="status"></div>
		</div>
	</ng-container>
	<ng-template #show_else>
		<ng-container *ngIf="isErrorOccured; else other_one">
			<app-error500></app-error500>
		</ng-container>
		<ng-template #other_one>
			<!-- Banner -->
			<div class="relative">
				<img src="../../assets/images/banner.jpg" alt="banner" class="h-[18.75rem] object-cover" />
			</div>
			<!-- end banner -->
			<!-- Profile -->
			<section class="dark:bg-jacarta-800 bg-light-base relative pt-28" style="padding-bottom: 1rem">
				<!-- Avatar -->
				<div class="absolute left-1/2 top-0 z-10 flex -translate-x-1/2 -translate-y-1/2 items-center justify-center">
					<figure class="relative">
						<img src="{{ cloundfrontUrl + SpecificMemberData?.member_image }}" alt="collection avatar"
							class="dark:border-jacarta-600 rounded-xl border-[5px] border-white" width="350" height="350" />
						<div
							class="dark:border-jacarta-600 bg-green absolute -right-3 bottom-0 flex h-6 w-6 items-center justify-center rounded-full border-2 border-white"
							data-tippy-content="Verified Collection">
							<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
								class="h-[.875rem] w-[.875rem] fill-white">
								<path fill="none" d="M0 0h24v24H0z"></path>
								<path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
							</svg>
						</div>
					</figure>
				</div>

				<div class="container imgMarginTop">
					<div class="text-center">
						<h2 class="font-display text-jacarta-700 mb-2 text-4xl font-medium dark:text-white">
							{{ SpecificMemberData?.member_name }}
						</h2>
						<div
							class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 mb-5 inline-flex items-center justify-center rounded-full border bg-white py-1.5 px-4">
							<span data-tippy-content="ETH">
								<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0" y="0" viewBox="0 0 1920 1920"
									xml:space="preserve" class="mr-1 h-4 w-4">
									<path fill="#8A92B2" d="M959.8 80.7L420.1 976.3 959.8 731z"></path>
									<path fill="#62688F" d="M959.8 731L420.1 976.3l539.7 319.1zm539.8 245.3L959.8 80.7V731z"></path>
									<path fill="#454A75" d="M959.8 1295.4l539.8-319.1L959.8 731z"></path>
									<path fill="#8A92B2" d="M420.1 1078.7l539.7 760.6v-441.7z"></path>
									<path fill="#62688F" d="M959.8 1397.6v441.7l540.1-760.6z"></path>
								</svg>
							</span>
							<button
								class="js-copy-clipboard dark:text-jacarta-200 select-none overflow-hidden text-ellipsis whitespace-nowrap"
								data-tippy-content="Copy Url">
								<span>{{ SpecificMemberData?.member_designation }}</span>
							</button>
						</div>

						<app-social-media-icons [member_social_links]="SpecificMemberData?.member_social_links" [pageClass]="false">
						</app-social-media-icons>
					</div>
				</div>
			</section>
			<!-- end profile -->

			<!-- Collection -->
			<section class="relative py-24 pt-10">
				<div class="container">
					<!-- Tabs Nav -->
					<ul
						class="nav nav-tabs hideLiDots scrollbar-custom dark:border-jacarta-600 border-jacarta-100 mb-12 flex items-center justify-start overflow-x-auto overflow-y-hidden border-b pb-px md:justify-center"
						role="tablist">
						<li class="nav-item hideLiDots" role="presentation">
							<button
								class="nav-link active hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
								id="owned-tab" data-bs-toggle="tab" data-bs-target="#owned" type="button" role="tab"
								aria-controls="owned" aria-selected="false">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
									class="mr-1 h-5 w-5 fill-current">
									<path fill="none" d="M0 0h24v24H0z" />
									<path
										d="M12.414 5H21a1 1 0 0 1 1 1v14a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1h7.414l2 2zM4 5v14h16V7h-8.414l-2-2H4zm9 8h3l-4 4-4-4h3V9h2v4z" />
								</svg>
								<span class="font-display text-base font-medium">Published Blogs</span>
							</button>
						</li>
						<li class="nav-item hideLiDots" role="presentation">
							<button
								class="nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
								id="created-tab" data-bs-toggle="tab" data-bs-target="#created" type="button" role="tab"
								aria-controls="created" aria-selected="false">
								<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
									class="mr-1 h-5 w-5 fill-current">
									<path fill="none" d="M0 0h24v24H0z" />
									<path
										d="M5 5v3h14V5H5zM4 3h16a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4a1 1 0 0 1 1-1zm2 9h6a1 1 0 0 1 1 1v3h1v6h-4v-6h1v-2H5a1 1 0 0 1-1-1v-2h2v1zm11.732 1.732l1.768-1.768 1.768 1.768a2.5 2.5 0 1 1-3.536 0z" />
								</svg>
								<span class="font-display text-base font-medium">Contributed Blogs</span>
							</button>
						</li>
					</ul>

					<div class="tab-content">
						<!-- Punlished Tab -->
						<div class="tab-pane fade show active" id="owned" role="tabpanel" aria-labelledby="owned-tab">
							<ng-container *ngIf="memberPublishedBlogs?.length > 0; else other_temp">
								<!-- Grid -->
								<div class="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-3">
									<article *ngFor="let blog of memberPublishedBlogs; let j = index">
										<app-blog-card [blog]="blog"> </app-blog-card>
									</article>
								</div>
								<!-- end grid -->
							</ng-container>
							<ng-template #other_temp>
								<h1 class="font-display text-jacarta-700 text-center text-xl dark:text-white" style="margin-bottom: 2%">
									There is no related blogs yet .
								</h1>
							</ng-template>
						</div>
						<!-- end Published tab -->

						<!-- Contributed Tab -->
						<div class="tab-pane fade" id="created" role="tabpanel" aria-labelledby="created-tab">
							<ng-container *ngIf="memberContributedBlogs?.length > 0; else other_temp_two">
								<!-- Grid -->
								<div class="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-3">
									<article *ngFor="let blog of memberContributedBlogs; let j = index">
										<app-blog-card [blog]="blog"> </app-blog-card>
									</article>
								</div>
								<!-- end grid -->
							</ng-container>
							<ng-template #other_temp_two>
								<h1 class="font-display text-jacarta-700 text-center text-xl dark:text-white" style="margin-bottom: 2%">
									There is no related blogs yet .
								</h1>
							</ng-template>
						</div>
						<!-- end contrbuted tab -->
					</div>
				</div>
			</section>
			<!-- end collection -->
		</ng-template>
	</ng-template>
</main>