<main class="pt-[5.5rem] lg:pt-24">
	<ng-container *ngIf="!isDataFetched && !isErrorOccured; else show_else">
		<!-- Skeleton Container Start -->
		<section>
			<div class="container">
				<div class="text-center" style="margin-top: 4rem">
					<ngx-skeleton-loader [theme]="{
              'border-radius': '20px',
              height: '40px',
              width: '200px',
              'margin-right': '18px',
              'background-color': '#131740',
              border: '1px solid #131740',
              'animation-duration': '2s'
            }">
					</ngx-skeleton-loader>
				</div>
				<div class="mt-3 text-center">
					<ngx-skeleton-loader [count]="skeletonCount" appearance="circle" [theme]="themeWidth">
					</ngx-skeleton-loader>
				</div>
				<div class="mt-3 text-center">
					<ngx-skeleton-loader [count]="skeletonCount" appearance="circle" [theme]="themeWidth">
					</ngx-skeleton-loader>
				</div>
			</div>
		</section>
		<!-- Skeleton Container End -->
		<!-- <div class="spinnerCenterDiv" style="padding-top: 14rem;padding-top: 7rem;">
            <div class="spinner-border text-danger" role="status"></div>
        </div> -->
	</ng-container>
	<ng-template #show_else>
		<ng-container *ngIf="isErrorOccured; else other_one">
			<app-error500></app-error500>
		</ng-container>
		<ng-template #other_one>
			<section class="relative py-16">
				<div *ngIf="blogsData?.length == 0" class="p-3">
					<h1 class="font-display text-jacarta-700 text-center text-xl dark:text-white" style="margin-bottom: 2%">
						No blogs data currently !
					</h1>
				</div>

				<div class="container" *ngIf="blogsData?.length > 0">
					<!-- Heading -->
					<div class="mx-auto max-w-2xl text-center">
						<h1
							class="mb-10 font-display text-3xl text-jacarta-700 dark:text-white lg:text-5xl xl:text-5xl gradientNamesClass">
							Explore Our Blogs
						</h1>
					</div>

					<!-- Blogs -->
					<div class="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-3 sm:grid-cols-2">
						<article *ngFor="let blog of displayedBlogsArray; let j = index">
							<app-blog-card [blog]="blog"> </app-blog-card>
						</article>
					</div>

					<!-- Load More -->
					<div *ngIf="displayedBlogsArray.length < blogsData.length" class="mt-10 text-center">
						<a (click)="loadMoreBlogs()"
							class="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all cursor-pointer">Load
							More</a>
					</div>
				</div>
			</section>
		</ng-template>
	</ng-template>
</main>