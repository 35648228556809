// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // Dev Keys
  apiKeyardemos: "https://sbbackend.immersionslabs.com/ardemos/",
  apiKeywebiste: "https://sbbackend.immersionslabs.com/website/",
  apiKeyanalytics: "https://sbbackend.immersionslabs.com/analytics/",
  apiKeygenToken: "https://sbbackend.immersionslabs.com/auth/gentoken",
  apiKey: "https://sbbackend.immersionslabs.com/",

  ardemos_host_url: "https://sbar.immersionslabs.com/",
  cloudFrontURL: "https://d9hu70l3wv27a.cloudfront.net/",
  // Local Host Live Keys
  // apiKeyardemos: "http://localhost:3000/ardemos/",
  // apiKeywebiste: "http://localhost:3000/website/",
  // apiKeyanalytics: "http://localhost:3000/analytics/",
  // apiKeygenToken: "http://localhost:3000/auth/gentoken",
  // apiKey: "http://localhost:3000/",


  bucket_name_dev: "isl-projects-resources",
  bucket_access_type: "public-read",
  bucket_location: "ap-south-1",

  // secrets_accessKey: "AKIAYUJBWLFJC6R6PKFV",
  // secrets_secretKey: "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",
  // secrets_accessKey: "AKIAYUJBWLFJJWCWDUU2",
  // secrets_secretKey: "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",

  // New Keys
  secrets_accessKey: "AKIAYUJBWLFJOQU5HC7U",
  secrets_secretKey: "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",
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
