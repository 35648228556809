import { Component, OnInit } from "@angular/core";
import { ToastrService } from "ngx-toastr";
import { AboutUsService } from "../shared/services/about-us.service";
import { S3ResourceService } from "../shared/services/s3-resource.service";
import { environment } from "../../environments/environment";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-about-us",
  templateUrl: "./about-us.component.html",
  styleUrls: ["./about-us.component.css"],
})
export class AboutUsComponent implements OnInit {
  gradient_dark: string = "";

  constructor(
    private aboutus: AboutUsService,
    public toastr: ToastrService,
    public s3serviceurl: S3ResourceService,
    public router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.getPrimaryTeam();
    this.aboutUs();
    this.getSecondaryTeam();

    this.gradient_dark = this.s3serviceurl.gradient_dark;
    // Members section Navigation related //
    this.scrollString = this.route.snapshot.params["members"];
    if (this.scrollString) {
      this.scrollToIdFromUrl();
    }
    // Members section Navigation related //
  }
  scrollString!: any;

  // About us
  aboutUsArray: any;
  erraboutUsArray: any;
  aboutUsData: any;
  aboutUs() {
    this.aboutus.getAboutus().subscribe({
      next: (val) => {
        // console.log(val);
        this.aboutUsArray = val;
      },
      error: (err) => {
        // console.log(err);
      },
      complete: () => {
        if (this.aboutUsArray.status == 1) {
          this.aboutUsData = this.aboutUsArray.aboutus;
          this.aboutUsData.cloudfront_url = environment.cloudFrontURL;
        }
      },
    });
  }

  // Primary Team
  primaryTeamArray: any;
  errPrimaryTeamArray: any;
  primaryTeamData: any;
  getPrimaryTeam() {
    this.aboutus.getprimaryTeam().subscribe({
      next: (val) => {
        this.primaryTeamArray = val;
      },
      error: (err) => {
        this.errPrimaryTeamArray = err;
        // console.log(this.errPrimaryTeamArray.error.message);
      },
      complete: async () => {
        if (this.primaryTeamArray.status == 1) {
          this.primaryTeamData = this.primaryTeamArray.team;
          // console.log("Primary team Data", this.primaryTeamData);
          let i = 1;
          let length = this.primaryTeamData.length;
          await this.primaryTeamData.forEach((team_list: any) => {
            team_list.cloudfront_url = environment.cloudFrontURL;
            team_list.offset_cls = length % 2 != 0 && i == length ? "" : "";
            i++;
          });
        } else {
          // console.log(this.primaryTeamArray.message);
        }
      },
    });
  }

  scrollToIdFromUrl() {
    setTimeout(() => {
      const el = document.getElementById(this.scrollString);
      el?.scrollIntoView({ behavior: "smooth" });
    }, 1000);
  }

  // Secondary Team
  secondaryTeamArray: any;
  errsecondaryTeamArray: any;
  secondaryTeamData: any;
  secondaryteam_status!: boolean;
  getSecondaryTeam() {
    this.aboutus.getsecondaryTeam().subscribe({
      next: (val) => {
        this.secondaryTeamArray = val;
      },
      error: (err) => {
        this.errsecondaryTeamArray = err;
        // console.log(this.errsecondaryTeamArray.error.message);
      },
      complete: async () => {
        if (this.secondaryTeamArray.status == 1) {
          this.secondaryTeamData = this.secondaryTeamArray.team;
          if (this.secondaryTeamData.length === 0) {
            this.secondaryteam_status = true;
          }
          let i = 1;
          let length = this.secondaryTeamData.length;
          await this.secondaryTeamData.forEach((team_list: any) => {
            team_list.cloudfront_url = environment.cloudFrontURL;
            team_list.offset_cls =
              length % 2 != 0 && i == length ? "mx-auto" : "";
            i++;
          });
          // console.log("Secondary team Data", this.secondaryTeamData);
        } else {
          console.log(this.secondaryTeamArray.message);
        }
      },
    });
  }

  naviagteToDetail(id: any, title: any) {
    const memberName = title.split(" ").join("-").toLowerCase();
    let finalmemberName = memberName.replace(
      /[&\/\\#,+()$~%!@^;|.'":*?<>{}]/g,
      ""
    );
    this.router.navigate(["/about-us/members/details", id, finalmemberName]);
  }
}
