<!-- 3d model list start -->
<section class="hero relative py-5 md:pt-32">
  <picture class="pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden">
    <img [src]="gradient" alt="gradient" />
  </picture>
  <picture class="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
    <img [src]="gradient_dark" alt="gradient dark" />
  </picture>

  <div class="container">
    <div class="mx-auto max-w-2xl pt-20 text-center">
      <!-- Heading -->
      <h1
        class="mb-10 font-display text-5xl text-jacarta-700 dark:text-white lg:text-6xl xl:text-7xl gradientNamesClass">
        Discover<br />
        <span class="animategradient">Pure 3D Cards & Hybrid Cards <br />in AR</span>
      </h1>
      <!-- Search Bar  -->
      <div class="relative mb-4">
        <input type="search" [(ngModel)]="searchtext" (ngModelChange)="onSearchChange($event)"
          class="w-full rounded-2xl border border-jacarta-100 py-4 px-4 pl-10 text-md text-jacarta-700 placeholder-jacarta-300 focus:ring-accent dark:border-transparent dark:bg-white/[.15] dark:text-white dark:placeholder-white"
          placeholder="Search by Card Name" />
        <span class="absolute left-0 top-0 flex h-full w-12 items-center justify-center rounded-2xl">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
            class="h-4 w-4 fill-jacarta-500 dark:fill-white">
            <path fill="none" d="M0 0h24v24H0z"></path>
            <path d="M18.031 16.617l4.283 4.282-1.415 1.415-4.282-4.283A8.96 8.96 0 0 1 11 
              20c-4.968 0-9-4.032-9-9s4.032-9 9-9 9 4.032 9 9a8.96 8.96 0 0 1-1.969 
              5.617zm-2.006-.742A6.977 6.977 0 0 0 18 11c0-3.868-3.133-7-7-7-3.868 0-7 
              3.132-7 7 0 3.867 3.132 7 7 7a6.977 6.977 0 0 0 4.875-1.975l.15-.15z"></path>
          </svg>
        </span>
      </div>
      <!-- Navigation Buttons -->
      <!-- <div class="py-5 demosbuttonsClass" > -->
      <div class="mb-8 flex flex-wrap items-center justify-between">
        <div class="flex flex-wrap items-center">
          <div class="my-1 mr-2.5 btnTopMargin">
            <a class="bg-accent shadow-accent-volume hover:bg-accent-dark w-36 cursor-pointer rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
              (click)="scroll('threeDCards')">
              Pure 3D Cards (Mono)
            </a>
          </div>
          <div class="my-1 mr-2.5 btnTopMargin">
            <a class="bg-white shadow-white-volume hover:bg-accent-dark hover:shadow-accent-volume w-36 rounded-full py-3 px-8 cursor-pointer text-center font-semibold text-accent transition-all hover:text-white"
              (click)="scroll('twoDCards')">
              Hybrid Cards
            </a>
          </div>
          <div class="my-1 mr-2.5 btnTopMargin">
            <a class="bg-accent shadow-accent-volume hover:bg-accent-dark w-36 cursor-pointer rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
              (click)="scroll('NewthreeDCards')">
              Pure 3D Cards (Multi)
            </a>
          </div>
        </div>
      </div>

      <!-- Filters  Start-->
      <div class="mb-3 py-5">
        <div class="mb-3 flex flex-wrap items-center justify-center">
          <div class="flex space-x-4">
            <!-- project dropdown -->
            <div *ngIf="show3dProject && show2dProject && show3dDemoProject">
              <div class="dropdown relative my-1 cursor-pointer">
                <div
                  class="dark:bg-jacarta-700 dropdown-toggle border-jacarta-100 dark:border-jacarta-600 inline-flex items-center justify-between rounded-lg border bg-white py2 px-3 text-sm dark:text-white dropdownBtnsClass"
                  role="button" id="categoriesSort" data-bs-toggle="dropdown" (click)="onProjectClick()"
                  [attr.aria-expanded]="projectDropDown == false ? false : true">
                  <span class="font-display">Sort By Project</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                    class="fill-jacarta-500 h-4 w-4 dark:fill-white">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                  </svg>
                </div>

                <div
                  class="dropdown-menu dark:bg-jacarta-800 z-10 hidden w-full whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl"
                  [ngClass]="projectDropDown == false ? '' : 'show'" aria-labelledby="categoriesSort">
                  <div *ngFor="let project of projectsList">
                    <button
                      class="dropdown-item font-display text-jacarta-700 dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-1 py-3 text-left transition-colors dark:text-white dropdownBtnsfont"
                      (click)="showParticularCards(project.project_name)">
                      {{ project.project_name }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- usecase dropdown -->
            <div *ngIf="show3dUsecase && show2dUsecase && show3dDemoUsecase">
              <div class="dropdown relative my-1 cursor-pointer">
                <div
                  class="dark:bg-jacarta-700 dropdown-toggle border-jacarta-100 dark:border-jacarta-600 inline-flex items-center justify-between rounded-lg border bg-white py2 px-3 text-sm dark:text-white dropdownBtnsClass"
                  role="button" id="UsecasesSort" data-bs-toggle="dropdown" (click)="onUsecaseClick()"
                  [attr.aria-expanded]="useCasedropDown == false ? false : true">
                  <span class="font-display">Sort By UseCase</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                    class="fill-jacarta-500 h-4 w-4 dark:fill-white">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                  </svg>
                </div>

                <div
                  class="dropdown-menu dark:bg-jacarta-800 z-10 hidden w-full whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl"
                  [ngClass]="useCasedropDown == false ? '' : 'show'" aria-labelledby="UsecasesSort">
                  <div *ngFor="let usecase of usecases_list">
                    <button
                      class="dropdown-item font-display text-jacarta-700 dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-1 py-3 text-left transition-colors dark:text-white dropdownBtnsfont"
                      (click)="showParticularCards(usecase.usecase_title)">
                      {{ usecase.usecase_title }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="mb-8">
          <ul class="ulList">
            <li class="my-1 mr-2.5 text-center" >
              <div class="dropdown relative my-1 cursor-pointer">
                <div
                  class="dark:bg-jacarta-700 dropdown-toggle border-jacarta-100 dark:border-jacarta-600 inline-flex w-48 items-center justify-between rounded-lg border bg-white py2 px-3 text-sm dark:text-white"
                  role="button" id="categoriesSort" data-bs-toggle="dropdown" (click)="onProjectClick()"
                  [attr.aria-expanded]="projectDropDown == false ? false : true">
                  <span class="font-display">Sort By Project</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                    class="fill-jacarta-500 h-4 w-4 dark:fill-white">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                  </svg>
                </div>
  
                <div
                  class="dropdown-menu dark:bg-jacarta-800 z-10 hidden w-full whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl"
                  [ngClass]="projectDropDown == false ? '' : 'show'" aria-labelledby="categoriesSort">
                  <div *ngFor="let project of projectsList">
                    <button
                      class="dropdown-item font-display text-jacarta-700 dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-2 py-3 text-left text-sm transition-colors dark:text-white"
                      (click)="showParticularCards(project.project_name)">
                      {{ project.project_name }}
                    </button>
                  </div>
                </div>
              </div>
            </li>
            <li class="my-1 mr-2.5 text-center" >
              <div class="dropdown relative my-1 cursor-pointer">
                <div
                  class="dark:bg-jacarta-700 dropdown-toggle border-jacarta-100 dark:border-jacarta-600 inline-flex w-48 items-center justify-between rounded-lg border bg-white py2 px-3 text-sm dark:text-white"
                  role="button" id="UsecasesSort" data-bs-toggle="dropdown" (click)="onUsecaseClick()"
                  [attr.aria-expanded]="useCasedropDown == false ? false : true">
                  <span class="font-display">Sort By UseCase</span>
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                    class="fill-jacarta-500 h-4 w-4 dark:fill-white">
                    <path fill="none" d="M0 0h24v24H0z" />
                    <path d="M12 13.172l4.95-4.95 1.414 1.414L12 16 5.636 9.636 7.05 8.222z" />
                  </svg>
                </div>
  
                <div
                  class="dropdown-menu dark:bg-jacarta-800 z-10 hidden w-full whitespace-nowrap rounded-xl bg-white py-4 px-2 text-left shadow-xl"
                  [ngClass]="useCasedropDown == false ? '' : 'show'" aria-labelledby="UsecasesSort">
                  <div *ngFor="let usecase of usecases_list">
                    <button
                      class="dropdown-item font-display text-jacarta-700 dark:hover:bg-jacarta-600 hover:bg-jacarta-50 flex w-full items-center justify-between rounded-xl px-1 py-3 text-left text-sm transition-colors dark:text-white"
                      (click)="showParticularCards(usecase.usecase_title)">
                      {{ usecase.usecase_title }}
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>   -->
      </div>
      <!-- Filter End -->
    </div>
  </div>
</section>
<!-- End  -->

<!-- All Demos start -->
<section id="threeDCards" class="relative pb-24 dark:bg-jacarta-800">
  <div class="container">
    <!--======================== Models start  =========================-->
    <div class="mb-6 mt-2 py-3 text-center font-display text-3xl text-jacarta-700 dark:text-white">
      <h2 class="">
        <span class="mr-1 inline-block h-6 w-6 bg-contain bg-center text-xl"
          [ngStyle]="{ 'background-image': 'url(' + thunderPng + ')' }"></span>
        Top collections of Pure 3D Cards (Mono)
      </h2>
    </div>

    <!-- Tags Related -->
    <div class="container py-6 mb-10">
      <h2 class="font-display text-jacarta-700 mb-4 text-sm dark:text-white">
        Pure 3D Cards Tags
      </h2>
      <div class="mb-1 flex items-center">
        <div class="space-x-2 tagsContainer" id="tagContainer">
          <div *ngFor="let tag of modelTags" class="chip bg-violetColor text-light">
            <div (click)="searchByTags(tag)">
              {{ tag }}
            </div>
          </div>
        </div>
      </div>
      <div class="text-end" id="buttonContainer" *ngIf="showModelTags">
        <div (click)="viewMoreTags()"
          class="dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white">
          <span *ngIf="modeltagsStatus" class="font-display text-jacarta-700 text-sm dark:text-white">View More</span>
          <span *ngIf="!modeltagsStatus" class="font-display text-jacarta-700 text-sm dark:text-white">View Less</span>
        </div>
      </div>
    </div>
    <!-- Tags Related -->

    <div class="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-4 mb-5 mt-3">
      <div
        class="dark:bg-jacarta-700 rounded-2lg dark:border-jacarta-600 border-jacarta-100 flex items-center justify-center border bg-white p-8 text-center transition-shadow hover:shadow-lg cursor-pointer"
        data-bs-toggle="modal" data-bs-target="#bookingformModal">
        <span class="font-display text-jacarta-700 text-[1.125rem] dark:text-white">Book a Demo</span>
      </div>
      <ng-container *ngIf="!usecaseSearchStatus; else show_else">
        <div *ngFor="let models_data of models_data | searchFilter : searchtext">
          <a target="_blank" href="{{ arSiteUrl }}/{{ models_data.model_url }}" class="relative block cursor-pointer">
            <div
              class="block rounded-2.5xl border border-jacarta-100 bg-white rounded-[1.25rem] p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
              <figure>
                <img [defaultImage]="defaultImage" [lazyLoad]="
                    models_data.cloudfront_url + models_data.model_gif
                  " [alt]="models_data.model_name" title="{{ models_data.model_name }}"
                  class="w-full rounded-[0.625rem]" />
              </figure>
              <div class="mt-4 flex items-center justify-between" style="margin-bottom: 2rem; margin-top: 1rem">
                <span class="font-display text-base text-jacarta-700 hover:text-accent dark:text-white">{{
                  models_data.model_name }}</span>
              </div>

              <!-- Only Image AR -->
              <div class="mt-4 flex items-center justify-between" *ngIf="models_data.model_ar == 3">
                <div class="flex items-center space-x-1">
                  <div class="flex justify-between items-center">
                    <div class="mr-4">
                      <p class="dark:text-jacarta-300 text-sm mb-1">Image AR</p>
                    </div>
                    <div class="flex justify-end p-1s">
                      <div
                        class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white"
                        data-tippy-content="Verified Collection">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                          class="h-4 w-4 fill-white">
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center space-x-1">
                  <div class="flex justify-between items-center">
                    <div class="mr-4">
                      <p class="dark:text-jacarta-300 text-sm mb-1">
                        Surface AR
                      </p>
                    </div>
                    <div class="flex justify-end p-1s">
                      <div class="dark:border-jacarta-600 flex items-center justify-center rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22"
                          viewBox="0 0 48 48">
                          <path fill="#f44336"
                            d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
                          <path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z">
                          </path>
                          <path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z">
                          </path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Only Surface AR -->
              <div class="mt-4 flex items-center justify-between" *ngIf="models_data.model_ar == 1">
                <div class="flex items-center space-x-1">
                  <div class="flex justify-between items-center">
                    <div class="mr-4">
                      <p class="dark:text-jacarta-300 text-sm mb-1">Image AR</p>
                    </div>
                    <div class="flex justify-end p-1s">
                      <div class="dark:border-jacarta-600 flex items-center justify-center rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22"
                          viewBox="0 0 48 48">
                          <path fill="#f44336"
                            d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
                          <path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z">
                          </path>
                          <path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z">
                          </path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center space-x-1">
                  <div class="flex justify-between items-center">
                    <div class="mr-4">
                      <p class="dark:text-jacarta-300 text-sm mb-1">
                        Surface AR
                      </p>
                    </div>
                    <div class="flex justify-end p-1s">
                      <div
                        class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white"
                        data-tippy-content="Verified Collection">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                          class="h-4 w-4 fill-white">
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Both I & S -->
              <div class="mt-4 flex items-center justify-between" *ngIf="models_data.model_ar == 2">
                <div class="flex items-center space-x-1">
                  <div class="flex justify-between items-center">
                    <div class="mr-4">
                      <p class="dark:text-jacarta-300 text-sm mb-1">Image AR</p>
                    </div>
                    <div class="flex justify-end p-1s">
                      <div
                        class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white"
                        data-tippy-content="Verified Collection">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                          class="h-4 w-4 fill-white">
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center space-x-1">
                  <div class="flex justify-between items-center">
                    <div class="mr-4">
                      <p class="dark:text-jacarta-300 text-sm mb-1">
                        Surface AR
                      </p>
                    </div>
                    <div class="flex justify-end p-1s">
                      <div
                        class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white"
                        data-tippy-content="Verified Collection">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                          class="h-4 w-4 fill-white">
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-4 flex items-center justify-between">
                <div class="flex items-center space-x-1" *ngIf="models_data.rating == 0 || models_data.rating == 'NaN'">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    (New)
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="models_data.rating == 1">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="models_data.rating == 2">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="models_data.rating == 3">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="models_data.rating == 4">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="models_data.rating > 4">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1">
                  <i class="fa fa-eye text-success"></i>
                  <span class="text-accent text-sm">{{
                    models_data.model_views
                    }}</span>
                </div>
                <div class="flex items-center space-x-1">
                  <span
                    class="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4 before:bg-[url('../img/heart-fill.svg')] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                    data-tippy-content="Favorite">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                      class="h-4 w-4 fill-jacarta-500 hover:fill-red dark:fill-jacarta-200 dark:hover:fill-red">
                      <path fill="none" d="M0 0H24V24H0z"></path>
                      <path
                        d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z">
                      </path>
                    </svg>
                  </span>
                  <span class="text-sm text-accent">{{
                    models_data.likes
                    }}</span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-container>
      <ng-template #show_else>
        <div *ngFor="let models_data of models_data | searchByUseCase : searchtext">
          <a target="_blank" href="{{ arSiteUrl }}/{{ models_data.model_url }}" class="relative block cursor-pointer">
            <div
              class="block rounded-2.5xl border border-jacarta-100 bg-white rounded-[1.25rem] p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
              <figure>
                <img [defaultImage]="defaultImage" [lazyLoad]="models_data.model_gif" alt="{{ models_data.model_name }}"
                  class="w-full rounded-[0.625rem]" />
              </figure>
              <div class="mt-4 flex items-center justify-between" style="margin-bottom: 2rem; margin-top: 1rem">
                <span class="font-display text-base text-jacarta-700 hover:text-accent dark:text-white">{{
                  models_data.model_name }}</span>
              </div>

              <!-- Only Image AR -->
              <div class="mt-4 flex items-center justify-between" *ngIf="models_data.model_ar == 3">
                <div class="flex items-center space-x-1">
                  <div class="flex justify-between items-center">
                    <div class="mr-4">
                      <p class="dark:text-jacarta-300 text-sm mb-1">Image AR</p>
                    </div>
                    <div class="flex justify-end p-1s">
                      <div
                        class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white"
                        data-tippy-content="Verified Collection">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                          class="h-4 w-4 fill-white">
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center space-x-1">
                  <div class="flex justify-between items-center">
                    <div class="mr-4">
                      <p class="dark:text-jacarta-300 text-sm mb-1">
                        Surface AR
                      </p>
                    </div>
                    <div class="flex justify-end p-1s">
                      <div class="dark:border-jacarta-600 flex items-center justify-center rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22"
                          viewBox="0 0 48 48">
                          <path fill="#f44336"
                            d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
                          <path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z">
                          </path>
                          <path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z">
                          </path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Only Surface AR -->
              <div class="mt-4 flex items-center justify-between" *ngIf="models_data.model_ar == 1">
                <div class="flex items-center space-x-1">
                  <div class="flex justify-between items-center">
                    <div class="mr-4">
                      <p class="dark:text-jacarta-300 text-sm mb-1">Image AR</p>
                    </div>
                    <div class="flex justify-end p-1s">
                      <div class="dark:border-jacarta-600 flex items-center justify-center rounded-full">
                        <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="22" height="22"
                          viewBox="0 0 48 48">
                          <path fill="#f44336"
                            d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
                          <path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z">
                          </path>
                          <path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z">
                          </path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center space-x-1">
                  <div class="flex justify-between items-center">
                    <div class="mr-4">
                      <p class="dark:text-jacarta-300 text-sm mb-1">
                        Surface AR
                      </p>
                    </div>
                    <div class="flex justify-end p-1s">
                      <div
                        class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white"
                        data-tippy-content="Verified Collection">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                          class="h-4 w-4 fill-white">
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Both I & S -->
              <div class="mt-4 flex items-center justify-between" *ngIf="models_data.model_ar == 2">
                <div class="flex items-center space-x-1">
                  <div class="flex justify-between items-center">
                    <div class="mr-4">
                      <p class="dark:text-jacarta-300 text-sm mb-1">Image AR</p>
                    </div>
                    <div class="flex justify-end p-1s">
                      <div
                        class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white"
                        data-tippy-content="Verified Collection">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                          class="h-4 w-4 fill-white">
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center space-x-1">
                  <div class="flex justify-between items-center">
                    <div class="mr-4">
                      <p class="dark:text-jacarta-300 text-sm mb-1">
                        Surface AR
                      </p>
                    </div>
                    <div class="flex justify-end p-1s">
                      <div
                        class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white"
                        data-tippy-content="Verified Collection">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                          class="h-4 w-4 fill-white">
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="mt-4 flex items-center justify-between">
                <div class="flex items-center space-x-1" *ngIf="models_data.rating == 0 || models_data.rating == 'NaN'">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    (New)
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="models_data.rating == 1">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="models_data.rating == 2">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="models_data.rating == 3">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="models_data.rating == 4">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="models_data.rating > 4">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1">
                  <i class="fa fa-eye text-success"></i>
                  <span class="text-accent text-sm">{{
                    models_data.model_views
                    }}</span>
                </div>
                <div class="flex items-center space-x-1">
                  <span
                    class="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4 before:bg-[url('../img/heart-fill.svg')] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                    data-tippy-content="Favorite">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                      class="h-4 w-4 fill-jacarta-500 hover:fill-red dark:fill-jacarta-200 dark:hover:fill-red">
                      <path fill="none" d="M0 0H24V24H0z"></path>
                      <path
                        d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z">
                      </path>
                    </svg>
                  </span>
                  <span class="text-sm text-accent">{{
                    models_data.likes
                    }}</span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-template>
    </div>
    <!--============================ Models End =====================================-->

    <hr id="NewthreeDCards" class="hrClass" />
    <!--=======================  3d Cards ( Multi ) start ================================-->
    <div class="mb-12 mt-5 text-center font-display text-3xl text-jacarta-700 dark:text-white">
      <span class="mr-1 inline-block h-6 w-6 bg-contain bg-center text-xl"
        [ngStyle]="{ 'background-image': 'url(' + blastPng + ')' }"></span>
      <h2 class="inline">Top collections of Pure 3D Cards (Multi)</h2>
    </div>
    <!-- Tags-->
    <div class="container py-6" [ngClass]="showThreedDemoTags ? 'mb-10' : ''" style="margin-top: 25px">
      <h2 class="font-display text-jacarta-700 mb-4 text-sm dark:text-white">
        3D Cards (Multi) Tags
      </h2>
      <div class="mb-0 flex items-center">
        <div class="space-x-2" [ngClass]="
            showThreedDemoTags
              ? 'tagsThreedContainer'
              : 'tagsThreedContainerTwo'
          " id="tagsThreedContainer">
          <div *ngFor="let tag of threedDemoTags" class="chip bg-violetColor text-light">
            <div (click)="searchByTwodCardTag(tag)">
              {{ tag }}
            </div>
          </div>
        </div>
      </div>
      <div class="text-end" id="buttonThreedContainer" *ngIf="showThreedDemoTags">
        <div (click)="viewMoreThreedDemoTags()"
          class="dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white">
          <span *ngIf="threedDemotagsStatus" class="font-display text-jacarta-700 text-sm dark:text-white">View
            More</span>
          <span *ngIf="!threedDemotagsStatus" class="font-display text-jacarta-700 text-sm dark:text-white">View
            Less</span>
        </div>
      </div>
    </div>
    <!-- Tags-->
    <div class="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-4 mb-5">
      <div
        class="dark:bg-jacarta-700 rounded-2lg dark:border-jacarta-600 border-jacarta-100 flex items-center justify-center border bg-white p-8 text-center transition-shadow hover:shadow-lg cursor-pointer"
        data-bs-toggle="modal" data-bs-target="#bookingformModal">
        <span class="font-display text-jacarta-700 text-[1.125rem] dark:text-white">Book a Demo</span>
      </div>
      <!-- 3D Demos start-->
      <ng-container *ngIf="!usecaseSearchStatus; else show_threeddemo">
        <div *ngFor="let demos_data of active_Models | searchDemo : searchtext">
          <a target="_blank" href="{{ arSiteUrl }}/{{ demos_data.demo_url }}" class="relative block cursor-pointer">
            <div
              class="block h-full rounded-2.5xl border border-jacarta-100 bg-white rounded-[1.25rem] p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
              <!-- <img [src]="demos_data.demo_marker" alt="{{demos_data.demo_name}}" class="w-100 img-fluid" loading="lazy"> -->
              <figure class="h-230">
                <img [defaultImage]="defaultImage" [lazyLoad]="demos_data.cloudfront_url + demos_data.demo_gif"
                  alt="{{ demos_data.demo_name }}" title="{{ demos_data.demo_name }}"
                  class="w-full rounded-[0.625rem]" />
              </figure>
              <div class="mt-4 flex items-center justify-between" style="margin-bottom: 2rem">
                <span class="font-display text-base text-jacarta-900 hover:text-accent dark:text-white">{{
                  demos_data.demo_name }}</span>
              </div>
              <div class="mt-4 flex items-center justify-between">
                <span *ngIf="demos_data.demo_type == 0" class="text-sm text-white">3D Content
                </span>
                <span *ngIf="demos_data.demo_type == 1" class="text-sm text-white">2D Content
                </span>
              </div>
              <!--  I-AR S-AR -->
              <div class="mt-4 flex items-center justify-between">
                <div class="flex items-center space-x-1" *ngIf="demos_data.demo_iar == 1">
                  <div class="flex justify-between items-center">
                    <div class="mr-4">
                      <p class="dark:text-jacarta-300 text-sm mb-1">Image AR</p>
                    </div>
                    <div class="flex justify-end p-1s">
                      <div
                        class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white"
                        data-tippy-content="Verified Collection">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                          class="h-4 w-4 fill-white">
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.demo_sar == 1">
                  <div class="flex justify-between items-center">
                    <div class="mr-4">
                      <p class="dark:text-jacarta-300 text-sm mb-1">
                        Surface AR
                      </p>
                    </div>
                    <div class="flex justify-end p-1s">
                      <div
                        class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white"
                        data-tippy-content="Verified Collection">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                          class="h-4 w-4 fill-white">
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- rating , view , likes -->
              <div class="mt-4 flex items-center justify-between">
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 0 || demos_data.rating == 'NaN'">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    (New)
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 1">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 2">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 3">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 4">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating > 4">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1">
                  <i class="fa fa-eye text-success"></i>
                  <span class="text-accent text-sm">{{
                    demos_data.demo_views
                    }}</span>
                </div>
                <div class="flex items-center space-x-1">
                  <span
                    class="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4 before:bg-[url('../img/heart-fill.svg')] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                    data-tippy-content="Favorite">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                      class="h-4 w-4 fill-jacarta-500 hover:fill-red dark:fill-jacarta-200 dark:hover:fill-red">
                      <path fill="none" d="M0 0H24V24H0z"></path>
                      <path
                        d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z">
                      </path>
                    </svg>
                  </span>
                  <span class="text-sm text-accent">{{
                    demos_data.likes
                    }}</span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-container>
      <ng-template #show_threeddemo>
        <div *ngFor="
            let demos_data of active_Models | searchByUseCase : searchtext
          ">
          <a target="_blank" href="{{ arSiteUrl }}/{{ demos_data.demo_url }}" class="relative block cursor-pointer">
            <div
              class="block h-full rounded-2.5xl border border-jacarta-100 bg-white rounded-[1.25rem] p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
              <!-- <img [src]="demos_data.demo_marker" alt="{{demos_data.demo_name}}" class="w-100 img-fluid" loading="lazy"> -->
              <figure class="h-230">
                <img [defaultImage]="defaultImage" [lazyLoad]="demos_data.demo_gif" alt="{{ demos_data.demo_name }}"
                  class="w-full rounded-[0.625rem]" loading="lazy" />
              </figure>
              <div class="mt-4 flex items-center justify-between" style="margin-bottom: 2rem">
                <span class="font-display text-base text-jacarta-900 hover:text-accent dark:text-white">{{
                  demos_data.demo_name }}</span>
              </div>
              <div class="mt-4 flex items-center justify-between">
                <span *ngIf="demos_data.demo_type == 0" class="text-sm text-white">3D Content
                </span>
                <span *ngIf="demos_data.demo_type == 1" class="text-sm text-white">2D Content
                </span>
              </div>
              <!--  I-AR S-AR -->
              <div class="mt-4 flex items-center justify-between">
                <div class="flex items-center space-x-1" *ngIf="demos_data.demo_iar == 1">
                  <div class="flex justify-between items-center">
                    <div class="mr-4">
                      <p class="dark:text-jacarta-300 text-sm mb-1">Image AR</p>
                    </div>
                    <div class="flex justify-end p-1s">
                      <div
                        class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white"
                        data-tippy-content="Verified Collection">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                          class="h-4 w-4 fill-white">
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.demo_sar == 1">
                  <div class="flex justify-between items-center">
                    <div class="mr-4">
                      <p class="dark:text-jacarta-300 text-sm mb-1">
                        Surface AR
                      </p>
                    </div>
                    <div class="flex justify-end p-1s">
                      <div
                        class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white"
                        data-tippy-content="Verified Collection">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                          class="h-4 w-4 fill-white">
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- rating , view , likes -->
              <div class="mt-4 flex items-center justify-between">
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 0 || demos_data.rating == 'NaN'">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    (New)
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 1">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 2">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 3">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 4">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating > 4">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1">
                  <i class="fa fa-eye text-success"></i>
                  <span class="text-accent text-sm">{{
                    demos_data.model_views
                    }}</span>
                </div>
                <div class="flex items-center space-x-1">
                  <span
                    class="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4 before:bg-[url('../img/heart-fill.svg')] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                    data-tippy-content="Favorite">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                      class="h-4 w-4 fill-jacarta-500 hover:fill-red dark:fill-jacarta-200 dark:hover:fill-red">
                      <path fill="none" d="M0 0H24V24H0z"></path>
                      <path
                        d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z">
                      </path>
                    </svg>
                  </span>
                  <span class="text-sm text-accent">{{
                    demos_data.likes
                    }}</span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-template>
    </div>
    <!--============================ 3d Cards (Multi) End =====================================-->

    <hr id="twoDCards" class="hrClass" />
    <!--==================================  2d Cards start ===============================================-->
    <!-- Cards -->
    <div class="mb-12 mt-5 text-center font-display text-3xl text-jacarta-700 dark:text-white">
      <span class="mr-1 inline-block h-6 w-6 bg-contain bg-center text-xl"
        [ngStyle]="{ 'background-image': 'url(' + blastPng + ')' }"></span>
      <h2 class="inline">Top collections of Hybrid Cards</h2>
    </div>
    <!-- Tags-->
    <div class="container py-6 mb-10" style="margin-top: 25px">
      <h2 class="font-display text-jacarta-700 mb-4 text-sm dark:text-white">
        Hybrid Cards Tags
      </h2>
      <div class="mb-0 flex items-center">
        <div class="space-x-2 tagsTwodContainer" id="tagsTwodContainer">
          <div *ngFor="let tag of demoTags" class="chip bg-violetColor text-light">
            <div (click)="searchByTwodCardTag(tag)">
              {{ tag }}
            </div>
          </div>
        </div>
      </div>
      <div class="text-end" id="buttonTwodContainer" *ngIf="showTwodDemoTags">
        <div (click)="viewMoreTwodDTags()"
          class="dark:border-jacarta-600 dark:bg-jacarta-900 dark:hover:bg-accent group hover:bg-accent border-jacarta-100 font-display text-jacarta-500 flex h-9 items-center rounded-lg border bg-white px-4 text-sm font-semibold transition-colors hover:border-transparent hover:text-white dark:text-white dark:hover:border-transparent dark:hover:text-white">
          <span *ngIf="demotagsStatus" class="font-display text-jacarta-700 text-sm dark:text-white">View More</span>
          <span *ngIf="!demotagsStatus" class="font-display text-jacarta-700 text-sm dark:text-white">View Less</span>
        </div>
      </div>
    </div>

    <div class="grid grid-cols-1 gap-3 md:grid-cols-2 md:gap-[1.875rem] lg:grid-cols-4 mb-5">
      <div
        class="dark:bg-jacarta-700 rounded-2lg dark:border-jacarta-600 border-jacarta-100 flex items-center justify-center border bg-white p-8 text-center transition-shadow hover:shadow-lg cursor-pointer"
        data-bs-toggle="modal" data-bs-target="#bookingformModal">
        <span class="font-display text-jacarta-700 text-[1.125rem] dark:text-white">Book a Demo</span>
      </div>
      <!-- 2D Demos start-->
      <ng-container *ngIf="!usecaseSearchStatus; else show_demo">
        <div *ngFor="let demos_data of demos_data | searchDemo : searchtext">
          <a target="_blank" href="{{ arSiteUrl }}/{{ demos_data.demo_url }}" class="relative block cursor-pointer">
            <div
              class="block h-full rounded-2.5xl border border-jacarta-100 bg-white rounded-[1.25rem] p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
              <!-- <img [src]="demos_data.demo_marker" alt="{{demos_data.demo_name}}" class="w-100 img-fluid" loading="lazy"> -->
              <figure class="h-230">
                <img [defaultImage]="defaultImage" [lazyLoad]="demos_data.demo_cloudfront_image"
                  [alt]="demos_data.demo_name" title="{{ demos_data.demo_name }}" class="w-full rounded-[0.625rem]" />
              </figure>
              <div class="mt-4 flex items-center justify-between" style="margin-bottom: 2rem">
                <span class="font-display text-base text-jacarta-900 hover:text-accent dark:text-white">{{
                  demos_data.demo_name }}</span>
              </div>
              <div class="mt-4 flex items-center justify-between">
                <span *ngIf="demos_data.demo_content == 0" class="text-sm text-white">2D Content
                </span>
                <span *ngIf="demos_data.demo_content == 1" class="text-sm text-white">2D & 3D Content
                </span>
                <span *ngIf="demos_data.demo_content == 2" class="text-sm text-white">3D Content
                </span>
              </div>
              <!-- Only Image AR -->
              <div class="mt-4 flex items-center justify-between">
                <div class="flex items-center space-x-1">
                  <div class="flex justify-between items-center">
                    <div class="mr-4">
                      <p class="dark:text-white text-sm mb-1">Image AR</p>
                    </div>
                    <div class="flex justify-end p-1s">
                      <div
                        class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white"
                        data-tippy-content="Verified Collection">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                          class="h-4 w-4 fill-white">
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center space-x-1">
                  <i class="fa fa-eye text-success"></i>
                  <span class="text-accent text-sm">{{
                    demos_data.demo_views
                    }}</span>
                </div>
              </div>
              <div class="mt-4 flex items-center justify-between">
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 0 || demos_data.rating == 'NaN'">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    (New)
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 1">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 2">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 3">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 4">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating > 4">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1">
                  <span
                    class="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4 before:bg-[url('../img/heart-fill.svg')] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                    data-tippy-content="Favorite">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                      class="h-4 w-4 fill-jacarta-500 hover:fill-red dark:fill-jacarta-200 dark:hover:fill-red">
                      <path fill="none" d="M0 0H24V24H0z"></path>
                      <path
                        d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z">
                      </path>
                    </svg>
                  </span>
                  <span class="text-sm text-accent">{{
                    demos_data.likes
                    }}</span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-container>
      <ng-template #show_demo>
        <div *ngFor="let demos_data of demos_data | searchByUseCase : searchtext">
          <a target="_blank" href="{{ arSiteUrl }}/{{ demos_data.demo_url }}" class="relative block cursor-pointer">
            <div
              class="block h-full rounded-2.5xl border border-jacarta-100 bg-white rounded-[1.25rem] p-[1.1875rem] transition-shadow hover:shadow-lg dark:border-jacarta-700 dark:bg-jacarta-700">
              <!-- <img [src]="demos_data.demo_marker" alt="{{demos_data.demo_name}}" class="w-100 img-fluid" loading="lazy"> -->
              <figure class="h-230">
                <img [defaultImage]="defaultImage" [lazyLoad]="demos_data.demo_marker" alt="{{ demos_data.demo_name }}"
                  class="w-full rounded-[0.625rem]" />
              </figure>
              <div class="mt-4 flex items-center justify-between" style="margin-bottom: 2rem">
                <span class="font-display text-base text-jacarta-900 hover:text-accent dark:text-white">{{
                  demos_data.demo_name }}</span>
              </div>
              <div class="mt-4 flex items-center justify-between">
                <span *ngIf="demos_data.demo_content == 0" class="text-sm text-white">2D Content
                </span>
                <span *ngIf="demos_data.demo_content == 1" class="text-sm text-white">2D & 3D Content
                </span>
                <span *ngIf="demos_data.demo_content == 2" class="text-sm text-white">3D Content
                </span>
              </div>
              <!-- Only Image AR -->
              <div class="mt-4 flex items-center justify-between">
                <div class="flex items-center space-x-1">
                  <div class="flex justify-between items-center">
                    <div class="mr-4">
                      <p class="dark:text-white text-sm mb-1">Image AR</p>
                    </div>
                    <div class="flex justify-end p-1s">
                      <div
                        class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white"
                        data-tippy-content="Verified Collection">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                          class="h-4 w-4 fill-white">
                          <path fill="none" d="M0 0h24v24H0z"></path>
                          <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="flex items-center space-x-1">
                  <i class="fa fa-eye text-success"></i>
                  <span class="text-accent text-sm">{{
                    demos_data.demo_views
                    }}</span>
                </div>
              </div>
              <div class="mt-4 flex items-center justify-between">
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 0 || demos_data.rating == 'NaN'">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star"></i></span>
                    (New)
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 1">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 2">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 3">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating == 4">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1" *ngIf="demos_data.rating > 4">
                  <span class="text-sm dark:text-jacarta-200">
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                    <span class="mr-1"><i class="fa fa-star checked-star"></i></span>
                  </span>
                </div>
                <div class="flex items-center space-x-1">
                  <span
                    class="js-likes relative cursor-pointer before:absolute before:h-4 before:w-4 before:bg-[url('../img/heart-fill.svg')] before:bg-cover before:bg-center before:bg-no-repeat before:opacity-0"
                    data-tippy-content="Favorite">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
                      class="h-4 w-4 fill-jacarta-500 hover:fill-red dark:fill-jacarta-200 dark:hover:fill-red">
                      <path fill="none" d="M0 0H24V24H0z"></path>
                      <path
                        d="M12.001 4.529c2.349-2.109 5.979-2.039 8.242.228 2.262 2.268 2.34 5.88.236 8.236l-8.48 8.492-8.478-8.492c-2.104-2.356-2.025-5.974.236-8.236 2.265-2.264 5.888-2.34 8.244-.228zm6.826 1.641c-1.5-1.502-3.92-1.563-5.49-.153l-1.335 1.198-1.336-1.197c-1.575-1.412-3.99-1.35-5.494.154-1.49 1.49-1.565 3.875-.192 5.451L12 18.654l7.02-7.03c1.374-1.577 1.299-3.959-.193-5.454z">
                      </path>
                    </svg>
                  </span>
                  <span class="text-sm text-accent">{{
                    demos_data.likes
                    }}</span>
                </div>
              </div>
            </div>
          </a>
        </div>
      </ng-template>
    </div>
  </div>
</section>
<!--  All Demos  end -->

<!-- Booking Form -->
<div class="modal fade" id="bookingformModal" tabindex="-1" aria-labelledby="bookingformModalLabel" aria-hidden="true">
  <div class="modal-dialog max-w-2xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="bookingformModalLabel">Booking Form</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
            class="fill-jacarta-700 h-6 w-6 dark:fill-white">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
          </svg>
        </button>
      </div>
      <!-- Body -->
      <div class="modal-body">
        <div class="container" style="margin-top: 2rem">
          <app-booking-form></app-booking-form>
        </div>
      </div>
    </div>
  </div>
</div>