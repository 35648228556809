<div *ngIf="contributors_section">
	<!-- Contributors -->
	<section *ngIf="contributorsData?.length !== 0" class="relative">
		<div class="container">
			<div class="py-10">
				<h2 class="font-display text-jacarta-700 mb-8 text-center text-5xl dark:text-white"
					style="text-shadow: 1px 1px #000000">
					Technology Contributors
				</h2>
				<div class="grid grid-cols-2 gap-[1.875rem] md:grid-cols-3 lg:grid-cols-4 text-center py-8">
					<div
						class="mt-1 dark:bg-jacarta-700 block rounded-2xl bg-white p-[0.1875rem] transition-shadow hover:shadow-lg mr-2"
						*ngFor="let i of contributorsData">
						<a href="{{ i.contributor_url }}" target="_blank" class="cardCenter">
							<img [src]="i.contributor_image" [alt]="i.contributor_name" [title]="i.contributor_name" width="175px"
								class="rounded-[0.625rem] clintImage hidden paddingTopBottomOneRem dark:block" />
							<img [src]="i.contributor_image_bw" [alt]="i.contributor_name" [title]="i.contributor_name" width="175px"
								class="rounded-[0.625rem] clintImage paddingTopBottomOneRem dark:hidden" />
						</a>
					</div>
				</div>
			</div>
		</div>
	</section>

	<!-- Clients -->
	<section *ngIf="clientsdata?.length !== 0" class="dark:bg-jacarta-800 bg-light-base relative">
		<div class="container">
			<!-- clients -->
			<div class="py-10">
				<h2 class="font-display text-jacarta-700 mb-8 text-center text-5xl dark:text-white"
					style="text-shadow: 1px 1px #000000">
					Our Clients
				</h2>
				<div class="grid grid-cols-2 gap-[1.875rem] md:grid-cols-3 lg:grid-cols-4 text-center py-8">
					<div
						class="mt-1 dark:bg-jacarta-700 block rounded-2xl bg-white p-[0.1875rem] transition-shadow hover:shadow-lg mr-2"
						*ngFor="let i of clientsdata">
						<a href="{{ i.contributor_url }}" target="_blank" class="cardCenter">
							<img [src]="i.contributor_image" [alt]="i.contributor_name" [title]="i.contributor_name" width="175px"
								class="rounded-[0.625rem] clintImage hidden paddingTopBottomOneRem dark:block" />
							<img [src]="i.contributor_image_bw" [alt]="i.contributor_name" [title]="i.contributor_name" width="175px"
								class="rounded-[0.625rem] clintImage paddingTopBottomOneRem dark:hidden" />
						</a>
					</div>
				</div>
			</div>
		</div>
	</section>

	<!-- Partners -->
	<section *ngIf="partnersdata?.length !== 0" class="relative">
		<div class="container">
			<!-- Partners -->
			<div class="py-10">
				<h2 class="font-display text-jacarta-700 mb-8 text-center text-5xl dark:text-white"
					style="text-shadow: 1px 1px #000000">
					Our Partners
				</h2>
				<div class="grid grid-cols-2 gap-[1.875rem] md:grid-cols-3 lg:grid-cols-4 text-center py-8">
					<div
						class="mt-1 dark:bg-jacarta-700 block rounded-2xl bg-white p-[0.1875rem] transition-shadow hover:shadow-lg mr-2"
						*ngFor="let i of partnersdata">
						<a href="{{ i.contributor_url }}" target="_blank" class="cardCenter">
							<img [src]="i.contributor_image" [alt]="i.contributor_name" [title]="i.contributor_name" width="175px"
								class="rounded-[0.625rem] clintImage paddingTopBottomOneRem hidden dark:block" />
							<img [src]="i.contributor_image_bw" [alt]="i.contributor_name" [title]="i.contributor_name" width="175px"
								class="rounded-[0.625rem] clintImage paddingTopBottomOneRem dark:hidden" />
						</a>
					</div>
				</div>
			</div>
		</div>
	</section>
</div>