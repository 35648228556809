import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class ModelService {
  ardemos_api_key: any;
  constructor(private http: HttpClient) {
    this.ardemos_api_key = environment.ardemos_api_key;
  }

  getAllModels() {
    return this.http.get(this.ardemos_api_key + "models");
  }

  getAllActive3DModels() {
    return this.http.get(this.ardemos_api_key + "models/active");
  }

  getModelbyId(model_id: any) {
    return this.http.get(this.ardemos_api_key + "model/" + model_id);
  }

  addRating(list: any) {
    return this.http.put(this.ardemos_api_key + "rating", list);
  }

  addModelLikes(list: any) {
    return this.http.put(this.ardemos_api_key + "like", list);
  }

  getModelTags() {
    return this.http.get(this.ardemos_api_key + "modeltags");
  }

  // ======================= 3D Demos Apis ========================== //

  getAllActiveModels() {
    return this.http.get(this.ardemos_api_key + "3ddemos/1");
  }

  getThreedDemoTags() {
    return this.http.get(this.ardemos_api_key + "3ddemotags");
  }
}
