import { Injectable } from "@angular/core";
import * as S3 from "aws-sdk/clients/s3";
import { environment } from "../../../environments/environment";
import { ToastrService } from "ngx-toastr";
import { SecretsManager } from "aws-sdk";

@Injectable({
  providedIn: "root",
})
export class S3FileService {
  bucket_name: any;
  bucket_access_type: any;
  bucket_location: any;
  bucket_accessKey: any;
  bucket_secretKey: any;
  production!: boolean;
  secrets_accesskey: any;
  secrets_secretkey: any;

  constructor(private toaster: ToastrService) {
    this.bucket_name = environment.bucket_name;
    this.bucket_access_type = environment.bucket_access_type;
    this.bucket_location = environment.bucket_location;
    // this.bucket_accessKey = environment.bucket_accessKey;
    // this.bucket_secretKey = environment.bucket_secretKey;
    this.secrets_accesskey = environment.secrets_accessKey;
    this.secrets_secretkey = environment.secrets_secretKey;
    this.production = environment.production;
  }

  private gets3Bucket(): any {
    const bucket = new S3({
      // accessKeyId: this.bucket_accessKey,
      // secretAccessKey: this.bucket_secretKey,
      accessKeyId: this.secrets_accesskey,
      secretAccessKey: this.secrets_secretkey,
      region: this.bucket_location,
      apiVersion: "2006-03-01",
    });
    return bucket;
  }

  // private getSecrets(): any {
  //   const secretsManager = new SecretsManager({
  //     accessKeyId: this.secrets_accesskey,
  //     secretAccessKey: this.secrets_secretkey,
  //     region: this.bucket_location,
  //   });
  //   return secretsManager;
  // }

  // async getKeys(): Promise<any> {
  //   const secretName = "dev/isl/ar";

  //   let secretData: any;
  //   // Retrieve the secret value
  //   await new Promise((resolve) => {
  //     this.getSecrets().getSecretValue(
  //       { SecretId: secretName },
  //       (err: any, data: any) => {
  //         if (err) {
  //           console.error("Error retrieving secrets:", err);
  //         } else {
  //           if ("SecretString" in data) {
  //             // console.log('SECRET DATA', data);
  //             secretData = JSON.parse(data.SecretString);
  //             // console.log('SECRET STRING', secretData);
  //             // Get the access key and secret key from the secret
  //             // console.log('AWS Access Key: ', secretData.isl_ar_access_key);
  //             // console.log('AWS Secret Key: ', secretData.isl_ar_secret_key);
  //             this.bucket_accessKey = secretData.isl_ar_access_key;
  //             this.bucket_secretKey = secretData.isl_ar_secret_key;
  //           }
  //         }
  //         resolve(secretData);
  //       }
  //     );
  //     return secretData;
  //   });
  // }

  async getSignedImage(image_name: any) {
    var bucketName = this.bucket_name;
    const params = {
      Bucket: bucketName,
      Key: image_name,
    };
    // let secretKeys = await this.getKeys();
    // if (this.bucket_accessKey && this.bucket_secretKey) {
    const url = this.gets3Bucket().getSignedUrl("getObject", params);
    return url;
    // }
  }

  uploadResume(file: any, uid: any, jobid: any, apname: any) {
    return new Promise(async (resolve, reject) => {
      var renameResume = file?.name.split(".")[0];
      var finalresume = apname + "_" + "cv" + ".pdf";
      var upload_folder;
      if (this.production === false) {
        upload_folder =
          "dev/website/jobapplications/" + jobid + "/" + uid + "/";
      } else if (this.production === true) {
        upload_folder =
          "prod/website/jobapplications/" + jobid + "/" + uid + "/";
      }
      const contentType = file.type;
      // const bucket = new S3({
      //   accessKeyId: this.bucket_accessKey,
      //   secretAccessKey: this.bucket_secretKey,
      //   region: this.bucket_location,
      // });
      const params = {
        Bucket: this.bucket_name,
        Key: upload_folder + finalresume,
        ACL: this.bucket_access_type,
        Body: file,
        ContentType: contentType,
      };
      // let secretKeys = await this.getKeys();
      // if (this.bucket_accessKey && this.bucket_secretKey) {
      this.gets3Bucket().upload(params, function (err: any, data: any) {
        if (err) {
          reject(err);
        }
        resolve(data);
      });
      // }
    });
  }

  uploadCover(file1: any, uid: any, jobid: any, apname: any) {
    return new Promise(async (resolve, reject) => {
      var renameCover = file1?.name.split(".")[0];
      var finalcover = apname + "_" + "cl" + ".pdf";
      var upload_folder;
      if (this.production === false) {
        upload_folder =
          "dev/website/jobapplications/" + jobid + "/" + uid + "/";
      } else if (this.production === true) {
        upload_folder =
          "prod/website/jobapplications/" + jobid + "/" + uid + "/";
      }
      // var upload_folder = "website/job_applications/" + jobid + "/" + uid + "/";
      const contentType1 = file1.type;
      // const bucket = new S3({
      //   accessKeyId: this.bucket_accessKey,
      //   secretAccessKey: this.bucket_secretKey,
      //   region: this.bucket_location,
      // });
      const params = {
        Bucket: this.bucket_name,
        Key: upload_folder + finalcover,
        ACL: this.bucket_access_type,
        Body: file1,
        ContentType: contentType1,
      };
      // let secretKeys = await this.getKeys();
      // if (this.bucket_accessKey && this.bucket_secretKey) {
      this.gets3Bucket().upload(params, function (err: any, data: any) {
        if (err) {
          reject(err);
        }
        resolve(data);
      });
      // }
    });
  }

  // async uploadcoverletter(
  //   file: any,
  //   file1: any,
  //   uid: any,
  //   jobid: any,
  //   apname: any
  // ): Promise<any> {
  //   var renameResume = file?.name.split(".")[0];
  //   var renameCover = file1?.name.split(".")[0];
  //   var finalresume = apname + "_" + "cv" + ".pdf";
  //   var finalcover = apname + "_" + "cl" + ".pdf";
  //   const contentType = file.type;
  //   const contentType1 = file1.type;
  //   var upload_folder = "website/job_applications/" + jobid + "/" + uid + "/";
  //   const params = [
  //     {
  //       Bucket: this.bucket_name,
  //       Key: upload_folder + finalresume,
  //       ACL: this.bucket_access_type,
  //       Body: file,
  //       ContentType: contentType,
  //     },
  //     {
  //       Bucket: this.bucket_name,
  //       Key: upload_folder + finalcover,
  //       ACL: this.bucket_access_type,
  //       Body: file1,
  //       ContentType: contentType1,
  //     },
  //   ];
  //   var status;
  //   var deleteObj;
  //   await params.forEach((element) => {
  //     new Promise((resolve) => {
  //       this.gets3Bucket().putObject(element, (err: any, data: any) => {
  //         if (err) {
  //           //this.toaster.error("There was an error uploading your file");
  //           console.log("There was an error uploading your file: ", err);
  //           status = 0;
  //         } else {
  //           status = 1;
  //           //this.toaster.success("Successfully uploaded file")
  //         }
  //         resolve(status);
  //       });
  //     });
  //   });
  //   return status;
  // }

  async deleteFolder(jobid: any, dir: any) {
    var upload_folder;
    if (this.production === false) {
      upload_folder = "dev/website/jobapplications/" + jobid + "/";
    } else if (this.production === true) {
      upload_folder = "prod/website/jobapplications/" + jobid + "/";
    }
    var params = {
      Bucket: this.bucket_name,
      Prefix: upload_folder + dir,
    };
    var contents: any = [];
    // let secretKeys = await this.getKeys();
    // if (this.bucket_accessKey && this.bucket_secretKey) {
    await new Promise<any>((resolve) => {
      // Call S3 to obtain a list of the objects in the bucket
      this.gets3Bucket().listObjects(params, function (err: any, data: any) {
        if (err) {
          console.log("Error");
        } else {
          contents = data;
        }
        resolve(contents);
      });
    });

    contents.Contents.forEach((content: any) => {
      const params = {
        Bucket: this.bucket_name,
        Key: content.Key,
      };
      this.gets3Bucket().deleteObject(params, function (err: any, data: any) {
        if (err) {
          console.log("There was an error deleting your file: ");
          return;
        }
        //console.log("Successfully deleted file.", data);
      });
    });
    // }
  }
}
