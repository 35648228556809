<div
	class="relative rounded-2.5xl bg-white p-6 transition-shadow hover:shadow-[0_16px_24px_-8px_rgba(131,88,255,.3)] dark:bg-jacarta-700">
	<figure class="group overflow-hidden cursor-pointer">
		<a (click)="navigateToDetail(blog.blog_id, blog?.blog_name)">
			<img src="{{ blog?.blog_image }}" alt="post 2"
				class="h-full w-full object-cover transition-transform duration-[1600ms] will-change-transform group-hover:scale-105 rounded-2.5xl" />
		</a>
	</figure>

	<div class="dark:border-jacarta-600 dark:bg-jacarta-700 border-jacarta-100 rounded-b-[1.25rem] bg-white p-[5%]"
		style="margin-top: 2rem">
		<h2
			class="font-display text-jacarta-700 dark:hover:text-accent hover:text-accent mb-4 text-xl dark:text-white two-line-ellipsis">
			<a class="cursor-pointer" (click)="navigateToDetail(blog?.blog_id, blog?.blog_name)">
				{{ blog?.blog_name }}
			</a>
		</h2>
		<p class="dark:text-jacarta-200 mb-8 three-line-ellipsis" [innerHTML]="blog?.blog_description"></p>

		<div class="mb-3 flex flex-wrap items-center space-x-1">
			<a href="#" class="dark:text-jacarta-200 text-jacarta-700 font-display hover:text-accent">Category</a>
			<span class="dark:text-jacarta-400">:</span>
			<span class="text-accent inline-flex flex-wrap items-center space-x-1">
				{{ getCategoryName(blog?.blog_category) }}
			</span>
		</div>

		<div class="text-jacarta-400 flex flex-wrap items-center space-x-2 text-sm">
			<span><time datetime="2022-02-05">Latest</time></span>
			<span>•</span>
			<span>3 min read</span>
		</div>
	</div>
</div>