<!-- Landing section -->
<section id="home">
  <app-landing-section></app-landing-section>
</section>

<!--QR Photo -->
<section id="AR" class="dark:bg-jacarta-800 bg-light-base relative py-30" *ngIf="!show2DdemoData">
  <div class="grid grid-cols-1 gap-12 md:grid-cols-1 lg:grid-cols-3 p-3">
    <div class="text-center">
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
          'border-radius': '20px',
          width: '200px',
          height: '250px',
          'margin-right': '20px',
          'background-color': '#131740',
          border: '1px solid #131740',
          'animation-duration': '2s'
        }">
      </ngx-skeleton-loader>
    </div>
    <div class="text-center">
      <ngx-skeleton-loader count="6" [theme]="{
          'border-radius': '20px',
          height: '30px',
          'background-color': '#131740'
        }"></ngx-skeleton-loader>
    </div>
    <div class="text-center">
      <ngx-skeleton-loader count="1" appearance="circle" [theme]="themeWidth">
      </ngx-skeleton-loader>
    </div>
  </div>
</section>

<!--QR Photo -->
<section id="AR" class="dark:bg-jacarta-800 bg-light-base relative py-30" *ngIf="show2DdemoData">
  <div class="grid grid-cols-1 gap-12 md:grid-cols-1 lg:grid-cols-3 p-3">
    <div class="text-center">
      <h3 class="font-display text-jacarta-700 mb-4 text-lg dark:text-white">
        <br />Scan Me
      </h3>
      <div class="inline-flex items-center justify-center rounded-full">
        <img class="imgshadow imagemb" style="border: white 1px solid; border-radius: 10px"
          [src]="immersionLabsData?.demoQRImage" />
      </div>
    </div>
    <div class="text-center mb-4">
      <h2 class="text-jacarta-700 font-display mb-4 text-center text-2xl font-semibold dark:text-white">
        Experience our <br />Real-Time Augmented Reality(AR) <br />at its
        fullest
      </h2>
      <h3 class="font-display text-jacarta-700 mb-1 text-lg dark:text-white">
        &lt;&lt; &nbsp; Step 1
      </h3>
      <p class="dark:text-jacarta-300 mb-8 text-center text-md">
        -&nbsp;-&nbsp;Scan the QRCode&nbsp;-&nbsp;-
      </p>
      <h3 class="font-display text-jacarta-700 mb-1 text-lg dark:text-white">
        Step 2 &nbsp; &gt;&gt;
      </h3>
      <p class="dark:text-jacarta-300 mb-8 text-center text-md">
        -&nbsp;-&nbsp;Hover the image&nbsp;-&nbsp;-
      </p>
      <a href="{{ immersionLabsData?.demoURL }}" target="_blank"
        class="bg-accent shadow-accent-volume hover:bg-accent-dark w-36 rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
        View More</a>
    </div>
    <div class="text-center">
      <h3 class="font-display text-jacarta-700 mb-4 text-lg dark:text-white">
        <br />Hover Me
      </h3>
      <div class="inline-flex items-center justify-center rounded-full">
        <img class="imgshadow imagemb" style="border: white 1px solid; border-radius: 10px"
          [defaultImage]="defaultImage" [lazyLoad]="
            immersionLabsData?.cloudfront_url +
            immersionLabsData?.demo_active_scenes[0].scene_image
          " />
      </div>
    </div>
  </div>
</section>

<!--3d demos -->
<app-threed-demos></app-threed-demos>

<!-- Services -->
<section class="dark:bg-jacarta-800 relative paddingXy py-10">
  <app-services-section></app-services-section>
  <!-- Our Team -->
  <h4 class="font-display text-jacarta-500 mb-2 text-center text-2xl dark:text-white cursor-pointer"
    style="margin-top: 5%">
    <a (click)="GotoMembers()"
      class="bg-accent shadow-accent-volume hover:bg-accent-dark w-36 rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
      Our Team
    </a>
  </h4>
</section>

<!-- Products -->
<section id="products" class="py-10">
  <app-products-section></app-products-section>
  <!-- Our Story -->
  <h4 class="font-display text-jacarta-500 mb-12 text-center text-2xl dark:text-white cursor-pointer"
    style="margin-top: 5%">
    <a routerLink="/about-us" target="_blank"
      class="bg-accent shadow-accent-volume hover:bg-accent-dark w-36 rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
      Our Story
    </a>
  </h4>
</section>

<!-- Technology -->
<section class="relative paddingXy">
  <h2 class="font-display text-jacarta-700 mb-8 text-center text-5xl dark:text-white"
    style="text-shadow: 1px 1px #000000">
    Technology
  </h2>
  <div class="container">
    <div class="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-3">
      <div class="text-center">
        <div class="inline-flex items-center justify-center rounded-full">
          <img [src]="ar_svg" />
        </div>
        <h3 class="font-display text-jacarta-700 mb-4 text-lg dark:text-white">
          <br />
          AR
        </h3>
        <div class="mt-7 flex items-center justify-between">
          <a>
            <span class="dark:text-jacarta-300 mb-8 text-center text-md">
              Enhance your designing process, product presentation and customer support with AR technology. Let us know
              what you need and we&apos;ll help you come up with the ideal solution.
            </span>
          </a>
        </div>
      </div>
      <div class="text-center">
        <div class="inline-flex items-center justify-center rounded-full">
          <img [src]="webxr_svg" />
        </div>
        <h3 class="font-display text-jacarta-700 mb-4 text-lg dark:text-white">
          <br />
          WebXR
        </h3>
        <div class="mt-7 flex items-center justify-between">
          <a>
            <span class="dark:text-jacarta-300 mb-8 text-center text-md">
              Immerse your audience in the virtual world through your website! Take advantage of the possibilities of
              WebXR technology.
            </span>
          </a>
        </div>
      </div>
      <div class="text-center">
        <div class="inline-flex items-center justify-center rounded-full">
          <img [src]="mr_svg" />
        </div>
        <h3 class="font-display text-jacarta-700 mb-4 text-lg dark:text-white">
          <br />
          Mixed Reality
        </h3>
        <div class="mt-7 flex items-center justify-between">
          <a>
            <span class="dark:text-jacarta-300 mb-8 text-center text-md">
              Improve your employee training, workflow and customer experience with mixed reality. Bring 3D content to
              your workplace boosting creativity and productivity.
            </span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- Our Mission -->
  <h4 class="font-display text-jacarta-700 mb-12 text-center text-2xl dark:text-white cursor-pointer"
    style="margin-top: 5%">
    <a routerLink="/about-us" target="_blank"
      class="bg-accent shadow-accent-volume hover:bg-accent-dark w-36 rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
      Our Mission
    </a>
  </h4>
</section>

<!-- Strategy  -->
<!-- <section class="dark:bg-jacarta-800 bg-light-base relative py-24">
  <div class="container">
    <h2 class="font-display text-jacarta-700 mb-8 text-center text-5xl dark:text-white py-2"
      style="text-shadow: 1px 1px #000000;">STRATEGY</h2>
    <h2 class="font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white">
      <p style="font-size: 14px;padding: 3px 0px 0px 0px;">we&apos;ve got your requiremens covered </p>
    </h2>
    <div class="grid grid-cols-1 gap-12 md:grid-cols-2 lg:grid-cols-3">
      <div class="text-center">
        <div class="mb-6 inline-flex rounded-full bg-[#CDBCFF] p-3">
          <div class="inline-flex h-12 w-12 items-center justify-center rounded-full">
            <img [src]="efficient_shopping">
          </div>
        </div>
        <h3 class="font-display text-jacarta-700 mb-4 text-lg dark:text-white"> MORE EFFICIENT <br>ONLINE SHOPPING.</h3>
      </div>
      <div class="text-center">
        <div class="mb-6 inline-flex rounded-full bg-[#C4F2E3] p-3">
          <div class="inline-flex h-12 w-12 items-center justify-center rounded-full">
            <img [src]="product_discovery">
          </div>
        </div>
        <h3 class="font-display text-jacarta-700 mb-4 text-lg dark:text-white">SPONTANEOUS PRODUCT<br>DISCOVERY.</h3>
      </div>
      <div class="text-center">
        <div class="mb-6 inline-flex rounded-full bg-[#CDDFFB] p-3">
          <div class=" inline-flex h-12 w-12 items-center justify-center rounded-full">
            <img [src]="customer_engagement">
          </div>
        </div>
        <h3 class="font-display text-jacarta-700 mb-4 text-lg dark:text-white">ENHANCED BRAND &<br>CUSTOMER
          ENGAGEMENT.</h3>
      </div>
      <div class="text-center">
        <div class="mb-6 inline-flex rounded-full bg-[#CDBCFF] p-3">
          <div class="inline-flex h-12 w-12 items-center justify-center rounded-full">
            <img [src]="customer_metrics">
          </div>
        </div>
        <h3 class="font-display text-jacarta-700 mb-4 text-lg dark:text-white">BETTER CUSTOMER<br>MERICS AND INSIGHTS.
        </h3>
      </div>
      <div class="text-center">
        <div class="mb-6 inline-flex rounded-full bg-[#CDBCFF] p-3">
          <div class="inline-flex h-12 w-12 items-center justify-center rounded-full">
            <img [src]="cross_platform">
          </div>
        </div>
        <h3 class="font-display text-jacarta-700 mb-4 text-lg dark:text-white">RUN ACROSS ALL<br>DISPLAY PLATFORMS
        </h3>
      </div>
      <div class="text-center">
        <div class="mb-6 inline-flex rounded-full bg-[#FFD0D0] p-3">
          <div class="inline-flex h-12 w-12 items-center justify-center rounded-full">
            <img [src]="ar_enabled">
          </div>
        </div>
        <h3 class="font-display text-jacarta-700 mb-4 text-lg dark:text-white">AUGMENTED REALITY<br>ENABLED</h3>
      </div>
    </div>
  </div>
</section>&nbsp; -->
<!-- Strategy End -->

<!-- Usecases  -->
<section id="usecases" class="dark:bg-jacarta-800 bg-light-base relative paddingXy">
  <app-usecases-section></app-usecases-section>
</section>
&nbsp;

<section id="contact-us">
  <app-contact-us [heading]="headingTitle"></app-contact-us>
</section>

<!-- Testimonials section-->
<app-testimonials></app-testimonials>

<!-- Contributors section -->
<app-contributors></app-contributors>

<!-- Button trigger modal -->
<button type="button" id="SubscribeButton" data-bs-toggle="modal" data-bs-target="#SubscribeModal"
  style="display: none"></button>

<!-- subscribe Modal -->
<div class="modal fade" id="SubscribeModal" tabindex="-1" aria-labelledby="SubscribeModalLabel" aria-hidden="true">
  <div class="modal-dialog max-w-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="SubscribeModalLabel">Subscribe</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
            class="fill-jacarta-700 h-6 w-6 dark:fill-white">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
          </svg>
        </button>
      </div>
      <div class="modal-body">
        <div class="esub">
          <div class="container">
            <p class="text-jacarta-700 mx-auto mt-20 max-w-2xl text-center text-lg dark:text-white">
              Join our mailing list to stay in the loop with our newest feature
              releases.
            </p>
            <div class="mx-auto mt-7 max-w-md text-center">
              <form class="relative" [formGroup]="subscriberform">
                <input type="email" placeholder="Email address"
                  class="dark:bg-jacarta-700 dark:border-jacarta-600 focus:ring-accent border-jacarta-300 w-full rounded-full border py-3 px-4 dark:text-white dark:placeholder-white"
                  formControlName="subscriber_email" />
                <span *ngIf="subscriber_email?.invalid && subscriber_email?.touched" class="error">
                  Email required
                </span>
                <button
                  class="hover:bg-accent-dark font-display bg-accent absolute top-2 right-2 rounded-full px-6 py-2 text-sm text-white"
                  (click)="submit()" [disabled]="subscriberform.invalid">
                  Subscribe
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <div class="flex items-center justify-center space-x-4"></div>
      </div>
    </div>
  </div>
</div>