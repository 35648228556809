import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { S3FileService } from "../shared/services/s3-file.service";
import { SharingService } from "../shared/services/sharing.service";

@Component({
  selector: "app-job-details",
  templateUrl: "./job-details.component.html",
  styleUrls: ["./job-details.component.css"],
})
export class JobDetailsComponent implements OnInit {
  msg = "";
  Jobs: any = [];

  result2: any = [];
  mapped: any = [];
  Appid: any;
  JobId: any;

  Applicantform: FormGroup;

  uid: any;
  selectedResume!: FileList;
  selectedCover!: FileList;
  resume: any;
  coverLetter: any;
  constructor(
    private service: SharingService,
    private formBuilder: FormBuilder,
    private ActivatedRoute: ActivatedRoute,
    private s3FileService: S3FileService
  ) {
    this.Applicantform = this.formBuilder.group({
      applicant_job_id: [""],

      applicant_name: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z]*")],
      ],
      applicant_email: ["", [Validators.required]],
      applicant_cover_letter: ["", [Validators.required]],
      applicant_resume: ["", [Validators.required]],
      applicant_id: [this.uid],
      //applicant_phone : new FormControl()
      applicant_mobile: [],
    });

    // Unique ID
    this.uid = this.getuid(uid);
  }
  // unique id
  getuid(uid: any) {
    return "ISLJOBAPL_REFID_" + Math.random().toString(36).substr(2, 15);
  }

  // Upload
  selectResume(event: any) {
    this.selectedResume = event.target.files;
    // console.log("Resume " ,this.selectedResume.item(0));
  }
  selectCover(event: any) {
    this.selectedCover = event.target.files;
    // console.log("Cover " ,this.selectedCover.item(0));
  }
  ngOnInit(): void {
    let id = this.ActivatedRoute.snapshot.params["id"];
    this.Appid = id;

    this.service.GetJobsById(id).subscribe((res: any) => {
      //console.log("response",res);
      this.result2 = res["job"];
      this.Applicantform.patchValue({
        applicant_job_id: this.result2.job_id,
      });
      // console.log(this.result2);
    });
  }

  resume_url: any;
  cover_url: any;
  async submit(): Promise<any> {
    const file = this.selectedResume.item(0);
    const file1 = this.selectedCover.item(0);
    let apname: any = this.Applicantform.value.applicant_name;
    try {
      let resume = (await this.s3FileService.uploadResume(
        file,
        this.uid,
        this.Appid,
        apname
      )) as any;
      //console.log("resume",resume);
      this.resume_url = resume["Location"];
    } catch (error) {
      this.service.failure("Problem uploading Resume.");
    }
    this.resume =
      this.resume_url +
      this.Appid +
      "/" +
      this.uid +
      "/" +
      apname +
      "_" +
      "cv" +
      ".pdf";

    try {
      let cover = (await this.s3FileService.uploadCover(
        file1,
        this.uid,
        this.Appid,
        apname
      )) as any;
      //console.log("cover",cover);
      this.cover_url = cover["Location"];
    } catch (error) {
      this.service.failure("Problem uploading Cover.");
    }
    this.coverLetter =
      this.cover_url +
      this.Appid +
      "/" +
      this.uid +
      "/" +
      apname +
      "_" +
      "cl" +
      ".pdf";
    let applicant_resume = Object.assign(this.Applicantform.value, {
      applicant_resume: this.resume,
    });
    let applicant_cover_letter = Object.assign(this.Applicantform.value, {
      applicant_cover_letter: this.coverLetter,
    });
    let applicant_id = Object.assign(this.Applicantform.value, {
      applicant_id: this.uid,
    });
    let applicant_phone: Number = Object.assign(this.Applicantform.value, {
      applicant_phone: Number(this.Applicantform.value["applicant_mobile"]),
    });
    // console.log(this.Applicantform.value);
    //  APi
    this.service.putApplication(this.Applicantform.value).subscribe(
      (data: any) => {
        this.msg = data.message;
        //console.log(this.msg);
        this.service.success(this.msg);
        this.Applicantform.reset();
      },
      (err: any) => {
        this.service.failure(this.msg);
        //alert("something went wrong");
        this.s3FileService.deleteFolder(this.Appid, this.uid);
      }
    );
    this.Applicantform.reset();
  }

  get applicant_name() {
    return this.Applicantform.get("applicant_name");
  }
  get applicant_email() {
    return this.Applicantform.get("applicant_email");
  }
  get applicant_cover_letter() {
    return this.Applicantform.get("applicant_cover_letter");
  }
  get applicant_resume() {
    return this.Applicantform.get("applicant_resume");
  }
}
function uid(uid: any): string {
  throw new Error("Function not implemented.");
}
