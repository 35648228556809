import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class TestimonialsService {
  website_apiKey: any;
  constructor(private http: HttpClient) {
    this.website_apiKey = environment.website_api_key;
  }
  getFeaturedTestimoials() {
    return this.http.get(this.website_apiKey + "/testimonials/featured");
  }


  getClientbyID(clientId: any) {
    return this.http.get(this.website_apiKey + "/clients/" + clientId);
  }
}
