import { HttpClient } from "@angular/common/http";
import { Component, Input, OnInit, Output } from "@angular/core";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
  FormArray,
} from "@angular/forms";
import { SharingService } from "../shared/services/sharing.service";
@Component({
  selector: "app-feedback",
  templateUrl: "./feedback.component.html",
  styleUrls: ["./feedback.component.css"],
})
export class FeedbackComponent implements OnInit {
  @Input() uniqueId: any;

  feedbackForm!: FormGroup;
  isSelected: boolean = false;
  peersSelected: boolean = false;
  submitted: boolean = false;

  constructor(
    public formBuilder: FormBuilder,
    private http: HttpClient,
    private service: SharingService
  ) { }

  ngOnInit(): void {
    this.feedbackForm = this.formBuilder.group({
      feedback_demo_id: [this.uniqueId],
      feedback_name: ["", [Validators.required]],
      feedback_email: ["", [Validators.required, Validators.email]],
      feedback: ["", [Validators.required]],
      demo_like: [""],
      demo_type: [""],
      refertype: [""],
      feedback_referals: this.formBuilder.array([]),
      newSettler: [""],
    });
  }

  // Refral Functinality Start
  referals(): FormArray {
    return this.feedbackForm.get("feedback_referals") as FormArray;
  }

  newReferal(): FormGroup {
    return this.formBuilder.group({
      name: [""],
      email: [""],
    });
  }

  addReferal() {
    this.referals().push(this.newReferal());
  }

  removeReferal(i: number) {
    this.referals().removeAt(i);
  }
  // Refral Functinality End

  get fvalid() {
    return this.feedbackForm.controls;
  }

  response_msg: any;
  feedbackData() {
    this.submitted = true;
    if (this.feedbackForm.invalid) {
      return;
    }
    let feedback_self_demo: number = Object.assign(this.feedbackForm.value, {
      feedback_self_demo: Number(this.feedbackForm.value["demo_type"]),
    });
    let feedback_like: number = Object.assign(this.feedbackForm.value, {
      feedback_like: Number(this.feedbackForm.value["demo_like"]),
    });
    let feedback_subscribe: number = Object.assign(this.feedbackForm.value, {
      feedback_subscribe: Number(this.feedbackForm.value["newSettler"]),
    });

    //console.log("feedbackForm.value",this.feedbackForm.value)
    this.service.putFeedback(this.feedbackForm.value).subscribe({
      next: (res: any) => {
        this.response_msg = res.message;
        this.service.success(this.response_msg);
        this.feedbackForm.reset();
      },
      error: (err) => {
        this.service.failure(this.response_msg);
        // console.log(err);
      },
    });
  }

  enable() {
    this.isSelected = true;
  }
  referalEnable() {
    this.peersSelected = true;
  }

  referalDisable() {
    this.peersSelected = false;
  }

  disable() {
    this.isSelected = false;
  }

  get feedback_name() {
    return this.feedbackForm.get("feedback_name");
  }

  get feedback_email() {
    return this.feedbackForm.get("feedback_email");
  }
}
