import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AboutUsComponent } from "./about-us/about-us.component";
import { CareersComponent } from "./career/careers.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { HomeComponent } from "./home/home.component";
import { JobDetailsComponent } from "./job-details/job-details.component";
import { LandingSectionComponent } from "./landing-section/landing-section.component";
import { OurmissionComponent } from "./our-mission/our-mission.component";
import { OurStoryComponent } from "./our-story/our-story.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { ThreedModelDetailComponent } from "./threed-model-detail/threed-model-detail.component";
import { AllDemosListComponent } from "./all-demos-list/all-demos-list.component";
import { AllBlogsListComponent } from "./blogs/all-blogs-list/all-blogs-list.component";
import { BlogDetailComponent } from "./blogs/blog-detail/blog-detail.component";
import { TeamMemberDetailComponent } from "./team-member-detail/team-member-detail.component";

const routes: Routes = [
  { path: "", redirectTo: "home", pathMatch: "full" },
  {
    path: "home",
    component: HomeComponent,
  },
  // For Section ID Navigation
  {
    path: "home/:id",
    component: HomeComponent,
  },
  { path: "about-us", component: AboutUsComponent },
  { path: "our-story", component: OurStoryComponent },
  { path: "our-story/home", component: HomeComponent },
  { path: "our-mission", component: OurmissionComponent },
  { path: "our-mission/home", component: HomeComponent },
  { path: "privacy-policy", component: PrivacyPolicyComponent },
  { path: "contact-us", component: ContactUsComponent },
  { path: "career", component: CareersComponent },
  { path: "career/details/:id", component: JobDetailsComponent },
  { path: "blogs", component: AllBlogsListComponent },
  { path: "blogs/details/:id/:title", component: BlogDetailComponent },
  {
    path: "about-us/:members",
    component: AboutUsComponent,
  },
  {
    path: "about-us/members/details/:id/:title",
    component: TeamMemberDetailComponent,
  },
  {
    path: "alldemos",
    component: AllDemosListComponent,
  },
  {
    path: "3D/details/:id",
    component: ThreedModelDetailComponent,
  },
  {
    path: "landing-section",
    component: LandingSectionComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      anchorScrolling: "enabled",
      scrollPositionRestoration: "top",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
