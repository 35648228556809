<div class="second-section-wrapper mt-3">
	<div class="wrapper">
		<ngx-skeleton-loader [theme]="{
        width: '80%',
        'border-radius': '0',
        height: '15px',
        'margin-bottom': '10px',
        'background-color': '#131740'
      }">
		</ngx-skeleton-loader>
	</div>
	<div class="wrapper">
		<ngx-skeleton-loader [theme]="{
        width: '90%',
        'border-radius': '0',
        height: '15px',
        'margin-bottom': '10px',
        'background-color': '#131740'
      }">
		</ngx-skeleton-loader>
	</div>
	<div class="wrapper">
		<ngx-skeleton-loader [theme]="{
        width: '60%',
        'border-radius': '0',
        height: '15px',
        'margin-bottom': '10px',
        'background-color': '#131740'
      }">
		</ngx-skeleton-loader>
	</div>
</div>