<main class="pt-[5.5rem] lg:pt-15 mobile-padding-top-3rem">
  <section
    class="after:bg-jacarta-900/60 relative bg-cover bg-center bg-no-repeat py-17 after:absolute after:inset-0 headingTopMargin">
    <div class="container relative z-10">
      <h1 class="font-display text-jacarta-700 text-center text-xl dark:text-white">
        Build your <br /> POWERFUL CAREER
      </h1>
    </div>
  </section>

  <main class="pt-[5.5rem] lg:pt-15">
    <h1 *ngIf="toggleContainer" class="font-display text-jacarta-700 text-center text-xl dark:text-white"
      style="margin-bottom: 3%; margin-top: -3%">
      OUR OPENINGS
    </h1>
    <section class="dark:bg-jacarta-800 relative p-bot p-top">
      <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
        <img [src]="gradient_light" alt="gradient" class="h-full w-full" />
      </picture>
      <div class="container" *ngIf="toggleContainer">
        <div class="grid grid-cols-1 gap-6 sm:grid-cols-2 md:grid-cols-2 md:gap-[1.875rem]">
          <a *ngFor="let job of Jobs" [routerLink]="['/career', job.job_id]"
            class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 mb-8 rounded-[1.25rem] border bg-white p-8 text-center transition-shadow hover:shadow-lg">
            <img [src]="company_logo" alt="logo"
              class="dark:border-jacarta-600 dark:bg-jacarta-700 border-jacarta-100 mx-auto mb-7 -mt-[3.5rem] h-[5.5rem] w-[5.5rem] rounded-full border bg-white" />
            <h3 class="font-display text-jacarta-700 mb-3 text-[1.125rem] dark:text-white">
              {{ job.job_title }}
            </h3>
            <div class="dark:text-jacarta-300">
              <p class="font-display text-jacarta-700 mb-1 dark:text-white">
                SKILLS :
              </p>
              <p [innerHTML]="job.job_skill_requirements"></p>
            </div>
            <!-- <p class="dark:text-jacarta-300" style="font-family: fangsong;">
              <span class="font-display text-jacarta-700 mb-1 dark:text-white"> SKILLS : </span>
              {{job.job_skill_requirements}}
            </p> -->
            <br />
            <p class="dark:text-jacarta-300">
              <span class="font-display text-jacarta-700 mb-1 dark:text-white">
                Job-Location :
              </span>
              {{ job.job_location }}
            </p>
            &nbsp;
            <p class="dark:text-jacarta-300">
              <span class="font-display text-jacarta-700 mb-1 dark:text-white">
                Job-Id :
              </span>
              {{ job.job_id }}
            </p>
            <br />
            <button type="submit" routerLink="viewdetails" (click)="sent(job)"
              class="bg-red shadow-red-volume hover:bg-red-dark rounded-full py-2 px-4 text-center font-semibold text-white transition-all"
              [routerLink]="['/career', job.job_id]">
              APPLY NOW
            </button>
          </a>
        </div>
      </div>
      <div *ngIf="!toggleContainer" class="p-3">
        <h1 class="font-display text-jacarta-700 text-center text-xl dark:text-white" style="margin-bottom: 2%">
          Thank You for showing interest at Immersion Software Labs in your
          professional Journey.<br />
        </h1>
        <h1 class="font-display text-jacarta-700 text-center text-xl dark:text-white" style="margin-bottom: 2%">
          Currently we have no openings. Visit us again or subscribe to our site
          for constant updates on what we are up to!
        </h1>
      </div>
      <!-- subscribe -->
      <div class="container mt-3">
        <div class="mx-auto mt-7 max-w-md text-center">
          <form class="relative" [formGroup]="subscriberform">
            <input type="email" placeholder="Email address"
              class="dark:bg-jacarta-700 dark:border-jacarta-600 focus:ring-accent border-jacarta-300 w-full rounded-full border py-3 px-4 dark:text-white dark:placeholder-white"
              formControlName="subscriber_email" />
            <span *ngIf="subscriber_email?.invalid && subscriber_email?.touched" class="error">
              Email required
            </span>
            <button
              class="hover:bg-accent-dark font-display bg-accent absolute top-2 right-2 rounded-full px-6 py-2 text-sm text-white"
              (click)="submit()" [disabled]="subscriberform.invalid">
              Subscribe
            </button>
          </form>
        </div>
      </div>
      <!-- subscribe -->
    </section>

    <!-- contact-us -->
    <app-contact-us [heading]="headingTitle"></app-contact-us>
    <!-- contact-us -->
  </main>
</main>
<!--strategy-->

<!-- end strategy -->