<!-- Skeleton Container starts -->
<!--  -->
<section *ngIf="!showSkeletonloading">
  <div class="container">
    <div class="text-center">
      <ngx-skeleton-loader [theme]="{
          'border-radius': '20px',
          height: '40px',
          width: '200px',
          'margin-right': '18px',
          'background-color': '#131740',
          border: '1px solid #131740',
          'animation-duration': '2s'
        }">
      </ngx-skeleton-loader>
    </div>
    <div class="mt-3 text-center">
      <ngx-skeleton-loader [count]="skeletonCount" appearance="circle" [theme]="themeWidth">
      </ngx-skeleton-loader>
    </div>
    <div class="mt-3 text-center">
      <ngx-skeleton-loader [count]="skeletonCount" appearance="circle" [theme]="themeWidth">
      </ngx-skeleton-loader>
    </div>
  </div>
</section>
<!-- Skeleton Container End -->

<div class="container" *ngIf="showSkeletonloading">
  <h2 class="font-display text-jacarta-700 mb-8 text-center text-5xl dark:text-white"
    style="text-shadow: 1px 1px #000000">
    Our Portfolio
  </h2>
  <h2 id="services" class="font-display text-jacarta-700 mb-8 text-center text-3xl dark:text-white">
    SERVICES
    <p style="font-size: 18px; padding: 5px 0px 0px 0px">
      Augmented Reality Solutions
    </p>
    <p style="font-size: 18px; padding: 5px 0px 0px 0px">
      that we are keen to offer
    </p>
  </h2>
  <!-- Grid -->
  <div class="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-4">
    <div *ngFor="let list of services_list">
      <article>
        <div style="height: 25rem"
          class="dark:bg-jacarta-700 dark:border-jacarta-700 border-jacarta-100 block rounded-[1.25rem] border bg-white p-[1.1875rem] transition-shadow hover:shadow-lg cursor-pointer"
          (click)="viewServiceVideo(list.service_video)">
          <figure class="relative">
            <a>
              <img alt="AR Advertisement" class="imgwid" src="{{ list.cloudfront_url + list.service_image }}" />
            </a>
            <br />
            <h4 class="font-display text-jacarta-700 hover:text-accent text-center text-base text-2xl dark:text-white">
              {{ list.service_name }}
            </h4>
          </figure>
          <div class="mt-7 flex items-center justify-center">
            <a>
              <span class="dark:text-jacarta-300 mb-8 text-center text-md">
                {{ list.service_description }}
              </span>
            </a>
          </div>
        </div>
      </article>
    </div>
  </div>
</div>

<button type="button" id="ServiceSButton" data-bs-toggle="modal" data-bs-target="#ServicesModal"
  style="display: none"></button>

<!--Services Modal -->
<div class="modal fade" id="ServicesModal" data-bs-backdrop="static" tabindex="-1" aria-labelledby="ServicesModalLabel"
  aria-hidden="true">
  <div class="modal-dialog max-w-xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="ServicesModalLabel">Service Video</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="toggleVideo()">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
            class="fill-jacarta-700 h-6 w-6 dark:fill-white">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
          </svg>
        </button>
      </div>
      <!-- Body -->
      <div class="modal-body">
        <div class="accordion">
          <video controls #videoPlayer autoplay="autoplay" preload="auto">
            <source [src]="serviceVideo" type="video/mp4" />
          </video>
        </div>
      </div>
      <!-- end body -->
    </div>
  </div>
</div>
<!-- end services modal -->