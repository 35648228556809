import {
  CUSTOM_ELEMENTS_SCHEMA,
  NgModule,
  NO_ERRORS_SCHEMA,
} from "@angular/core";
import { BrowserModule, Meta } from "@angular/platform-browser";
import { AppRoutingModule } from "./app-routing.module";
import { FormsModule } from "@angular/forms";
import { ReactiveFormsModule } from "@angular/forms";
import { HttpClientModule } from "@angular/common/http";
import { AppComponent } from "./app.component";
import { HomeComponent } from "./home/home.component";
import { ContactUsComponent } from "./contact-us/contact-us.component";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { PrivacyPolicyComponent } from "./privacy-policy/privacy-policy.component";
import { OurStoryComponent } from "./our-story/our-story.component";
import { OurmissionComponent } from "./our-mission/our-mission.component";
import { CareersComponent } from "./career/careers.component";
import { JobDetailsComponent } from "./job-details/job-details.component";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { ToastrModule } from "ngx-toastr";
import { NgxStarRatingModule } from "ngx-star-rating";
import { NgxSkeletonLoaderModule } from "ngx-skeleton-loader";
import { LazyLoadImageModule } from "ng-lazyload-image";
// import { CarouselModule } from 'ngx-owl-carousel-o';
import { AboutUsComponent } from "./about-us/about-us.component";
import { NgxUsefulSwiperModule } from "ngx-useful-swiper";
import { ThreedDemosComponent } from "./threed-demos/threed-demos.component";
import { ThreedModelDetailComponent } from "./threed-model-detail/threed-model-detail.component";
import { TestimonialsComponent } from "./testimonials/testimonials.component";
import { LandingSectionComponent } from "./landing-section/landing-section.component";
import { AllDemosListComponent } from "./all-demos-list/all-demos-list.component";
import { SearchFilterPipe } from "./shared/pipes/search-filter.pipe";
import { ProductsSectionComponent } from "./products-section/products-section.component";
import { FeedbackComponent } from "./feedback/feedback.component";
import { BookingFormComponent } from "./booking-form/booking-form.component";
import { UsecasesSectionComponent } from "./usecases-section/usecases-section.component";
import { ServicesSectionComponent } from "./services-section/services-section.component";
import { SearchDemoPipe } from "./shared/pipes/search-demo.pipe";
import { SearchByProjectPipe } from "./shared/pipes/search-by-project.pipe";
import { AllBlogsListComponent } from "./blogs/all-blogs-list/all-blogs-list.component";
import { BlogDetailComponent } from "./blogs/blog-detail/blog-detail.component";
import { BlogCardComponent } from "./blogs/blog-card/blog-card.component";
import { Error500Component } from "./shared/error500/error500.component";
import { ImageLoaderComponent } from "./shared/skeleton-loaders/image-loader/image-loader.component";
import { ParagraphLoaderComponent } from "./shared/skeleton-loaders/paragraph-loader/paragraph-loader.component";
import { TeamMemberDetailComponent } from "./team-member-detail/team-member-detail.component";
import { SocialMediaIconsComponent } from "./shared/social-media-icons/social-media-icons.component";
import { ContributorsComponent } from "./contributors/contributors.component";

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ContactUsComponent,
    HeaderComponent,
    FooterComponent,
    PrivacyPolicyComponent,
    OurStoryComponent,
    OurmissionComponent,
    CareersComponent,
    JobDetailsComponent,
    AboutUsComponent,
    ThreedDemosComponent,
    ThreedModelDetailComponent,
    TestimonialsComponent,
    LandingSectionComponent,
    AllDemosListComponent,
    SearchFilterPipe,
    ProductsSectionComponent,
    FeedbackComponent,
    BookingFormComponent,
    UsecasesSectionComponent,
    ServicesSectionComponent,
    SearchDemoPipe,
    SearchByProjectPipe,
    AllBlogsListComponent,
    BlogDetailComponent,
    BlogCardComponent,
    Error500Component,
    ImageLoaderComponent,
    ParagraphLoaderComponent,
    TeamMemberDetailComponent,
    SocialMediaIconsComponent,
    ContributorsComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule.forRoot({
      timeOut: 2000,
      progressBar: true,
      progressAnimation: "increasing",
      preventDuplicates: true,
    }),
    NgxUsefulSwiperModule,
    NgxStarRatingModule,
    LazyLoadImageModule,
    // NgxSkeletonLoaderModule
    NgxSkeletonLoaderModule.forRoot({
      animation: "pulse",
      loadingText: "This item is actually loading...",
    }),
    // CarouselModule
  ],
  providers: [Meta],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class AppModule { }
