import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-our-mission",
  templateUrl: "./our-mission.component.html",
  styleUrls: ["./our-mission.component.css"],
})
export class OurmissionComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}
