<section *ngIf="hide_section"></section>
<section class="dark:bg-jacarta-800 bg-light-base relative pt-10" *ngIf="!hide_section">
  <div class="container">
    <h2 class="font-display mt-3 text-jacarta-700 text-center text-5xl dark:text-white"
      style="text-shadow: 1px 1px #000000">
      Testimonials
    </h2>
    <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
      <div class="">
        <swiper [config]="config">
          <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let slide of testmonials_slidestores">
              <div class="py-10">
                <div
                  class="flex justify-between flex-wrap rounded-2.5xl bg-white p-10 dark:bg-jacarta-700 md:flex-nowrap md:space-x-8 md:p-[4.25rem] lg:space-x-16">
                  <div
                    class="mb-8 flex h-16 w-16 shrink-0 items-center justify-center rounded-full bg-accent md:mb-0 md:w-16">
                    <svg width="22" height="19" fill="none" xmlns="http://www.w3.org/2000/svg" class="fill-white">
                      <path
                        d="M6.027 18.096c-.997 0-1.813-.204-2.448-.612a5.147 5.147 0 01-1.564-1.564 5.729 5.729 0 01-.952-2.38C.927 12.679.86 11.976.86 11.432c0-2.221.567-4.239 1.7-6.052C3.693 3.567 5.461 2.093 7.863.96l.612 1.224c-1.405.59-2.606 1.519-3.604 2.788-1.042 1.27-1.564 2.561-1.564 3.876 0 .544.068 1.02.204 1.428a3.874 3.874 0 012.516-.884c1.179 0 2.199.385 3.06 1.156.862.77 1.292 1.836 1.292 3.196 0 1.27-.43 2.312-1.292 3.128-.861.816-1.881 1.224-3.06 1.224zm11.56 0c-.997 0-1.813-.204-2.448-.612a5.148 5.148 0 01-1.564-1.564 5.73 5.73 0 01-.952-2.38c-.136-.861-.204-1.564-.204-2.108 0-2.221.567-4.239 1.7-6.052 1.134-1.813 2.902-3.287 5.304-4.42l.612 1.224c-1.405.59-2.606 1.519-3.604 2.788-1.042 1.27-1.564 2.561-1.564 3.876 0 .544.068 1.02.204 1.428a3.874 3.874 0 012.516-.884c1.179 0 2.199.385 3.06 1.156.862.77 1.292 1.836 1.292 3.196 0 1.27-.43 2.312-1.292 3.128-.861.816-1.881 1.224-3.06 1.224z">
                      </path>
                    </svg>
                  </div>
                  <div class="mb-4 md:mb-0">
                    <p class="text-sm leading-normal text-jacarta-700 dark:text-white"
                      [innerHTML]="slide.testimonial_message"></p>
                    <span class="mt-16 block font-display text-md font-medium text-jacarta-700 dark:text-white">
                      {{ slide.testimonial_name }}
                    </span>
                    <span class="text-sm font-medium block tracking-tight dark:text-jacarta-400">
                      {{ slide.testimonial_email }}
                    </span>
                    <span class="text-sm mt-2 font-medium block tracking-tight dark:text-jacarta-400">
                      {{ slide.testimonial_organization }}
                    </span>
                    <div class="mt-2">
                      <p *ngIf="slide.testimonial_rating == 1">
                        <i class="fa fa-star checked-star" aria-hidden="true"></i>
                      </p>
                      <p *ngIf="slide.testimonial_rating == 2">
                        <i class="fa fa-star checked-star" aria-hidden="true"></i>
                        <i class="fa fa-star checked-star" aria-hidden="true"></i>
                      </p>
                      <p *ngIf="slide.testimonial_rating == 3">
                        <i class="fa fa-star checked-star" aria-hidden="true"></i>
                        <i class="fa fa-star checked-star" aria-hidden="true"></i>
                        <i class="fa fa-star checked-star" aria-hidden="true"></i>
                      </p>
                      <p *ngIf="slide.testimonial_rating == 4">
                        <i class="fa fa-star checked-star" aria-hidden="true"></i>
                        <i class="fa fa-star checked-star" aria-hidden="true"></i>
                        <i class="fa fa-star checked-star" aria-hidden="true"></i>
                        <i class="fa fa-star checked-star" aria-hidden="true"></i>
                      </p>
                      <p *ngIf="slide.testimonial_rating == 5">
                        <i class="fa fa-star checked-star" aria-hidden="true"></i>
                        <i class="fa fa-star checked-star" aria-hidden="true"></i>
                        <i class="fa fa-star checked-star" aria-hidden="true"></i>
                        <i class="fa fa-star checked-star" aria-hidden="true"></i>
                        <i class="fa fa-star checked-star" aria-hidden="true"></i>
                      </p>
                    </div>
                  </div>
                  <img [src]="slide.cloudfront_url + slide.testimonial_image" [alt]="slide.testimonial_name"
                    [title]="slide.testimonial_name" width="260px" class="rounded-[1.25rem]" />
                  <!-- class="rounded-sm w-300 self-start lg:w-auto" -->
                </div>
              </div>
            </div>
          </div>

          <!-- Add Pagination -->
          <!-- <div class="swiper-pagination"></div> -->

          <!-- Add Arrows -->
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </swiper>
      </div>
    </div>
  </div>
</section>