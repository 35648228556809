import { Component, Input, OnInit } from "@angular/core";
import { SharingService } from "../shared/services/sharing.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";

@Component({
  selector: "app-contact-us",
  templateUrl: "./contact-us.component.html",
  styleUrls: ["./contact-us.component.css"],
})
export class ContactUsComponent implements OnInit {
  @Input() heading: any;

  contactform: FormGroup;
  constructor(
    private service: SharingService,
    private formBuilder: FormBuilder,
    private route: Router
  ) {
    this.contactform = this.formBuilder.group({
      enquiry_name: [
        "",
        [Validators.required, Validators.pattern("[a-zA-Z]*")],
      ],
      enquiry_subject: ["", [Validators.required]],
      enquiry_message: ["", [Validators.required]],
      enquiry_email: ["", [Validators.required, Validators.email]],
      enquiry_site:[""]
    });
  }

  ngOnInit(): void { }

  response: any;
  submit(): any {
    let enquiry_site = Object.assign(this.contactform.value, {
      enquiry_site: Number(0),
    });

    // console.log("this.contactform.value",this.contactform.value);
    this.service.putContact(this.contactform.value).subscribe((data: any) => {
      this.response = data.message;
      this.service.success(
        "Thanks for contacting Us. We appreciate your time. Our support team will contact you at the earliest."
      );
    });
    this.contactform.reset();
  }

  get enquiry_name() {
    return this.contactform.get("enquiry_name");
  }
  get enquiry_subject() {
    return this.contactform.get("enquiry_subject");
  }
  get enquiry_message() {
    return this.contactform.get("enquiry_message");
  }
  get enquiry_email() {
    return this.contactform.get("enquiry_email");
  }
}
