import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-paragraph-loader",
  templateUrl: "./paragraph-loader.component.html",
  styles: [],
})
export class ParagraphLoaderComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }
}
