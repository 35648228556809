<!-- Blog Area Start -->
<section
  class="after:bg-jacarta-900/60 relative bg-cover bg-center bg-no-repeat py-32 after:absolute after:inset-0 headingTopMargin">
  <div class="container relative z-10">
    <h1 class="font-display text-5xl font-large text-white">Privacy Policy</h1>
    <br />
    <h3 class="font-display text-jacarta-700 text-2xl dark:text-white">
      Responsible practices to ensure data protection and privacy
    </h3>
  </div>
</section>

<section class="dark:bg-jacarta-800 relative py-17">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div>
          <p class="dark:text-jacarta-400 mb-6 text-lg leading-normal">
            This Privacy policy enuciates the privacy and data protection principles followed by IMMERSIONSLABS and its
            affiliates with regards to collection, use, sharing and processing of personal information and personal data
            obtained in connection with the use of this website.This Privacy policy applies to processing of personal
            information or personal data of visitors and existing and prospective users of IMMERSIONSLABS and its
            affiliates. Customers,Suppliers.Agents, Business partners and their representatives.
          </p>
          &nbsp;
          <h3 class="font-display text-jacarta-700 text-2xl dark:text-white">
            The Data We Collect About You
          </h3>
          &nbsp; &nbsp;
          <p class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            We may collect use,store and transfer different kinds of personal data about you which we have grouped
            together follows.
          </p>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            Identify Data includes first name, maiden name,last name,username or similar identifier and title.
          </li>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            Contact Data includes billng address,email address and telephone numbers.
          </li>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            Usage Data includes information about how you use our site, products and services.
          </li>
          <li class="dark:text-jacarta-300 mb-7 text-lg">
            Content Data which you upload onto the site such as comments and other text or images that contain personal
            data.
          </li>
          &nbsp;&nbsp;
          <h3 class="font-display text-jacarta-700 text-2xl dark:text-white">
            How is your personal data collected?
          </h3>
          &nbsp;&nbsp;
          <p class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            We may collect use,store and transfer different kinds of personal data about you which we have grouped
            together follows.
          </p>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            Direct interactions. You may give us your Identity and Contact by filling in forms.
          </li>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            You may give us your Identity and Contact corresponding with us by post,phone,email,online or otherwise.
          </li>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            Data you upload onto our site. You may upload Content Data on our Site when you use our services.
          </li>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            Third partie receive personal data about you from various third parties and public sources as set out below:
          </li>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            Technical Data from the following parties: (i)analytics provides (ii)advertising networks;and (iii) search
            information providers.
          </li>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            Identify Profile and Contact Data from publicly available sources such as Companies House.
          </li>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            Identify Profile and Contact Data from data providers who obtain such data from publicly available sources.
          </li>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            We Collect your personal information for various purposes,for processing your order,corresponding with you.
          </li>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            We collect your personal information for providing you with a subscription or in connection with a job
            application.
          </li>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            We may combine the we collect from you with information obtained from other sources to help us improve its
            overall accuracy and completeness, and to help us better tailor interactions with you.
          </li>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            In addition, we may also collect information relating to your use of our Web sites through the use of
            various technologies, including cookies.
          </li>
          <p class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            Cookies are small text files that are stored on a user&apos;s computer and allow websites to remember
            information about users, such as their geo-location.We use cookies,to customize content and advertising,to
            provide social media features and to analyse traffic to the site.We also share information about our site
            and your email address with our trusted socila media advertising and anlytics partners.
          </p>
          &nbsp; &nbsp;
          <h3 class="font-display text-jacarta-700 text-2xl dark:text-white">
            Opting Out
          </h3>
          &nbsp; &nbsp; &nbsp;
          <p class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            You can ask us to stop sending you marketing messages at any time by following the opt-outs links on any
            marketing message send to you or by contacting us at any time. When we collect information from you. you may
            tell us that you do not want it used for further marketing contact
          </p>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            you may also turn off cookies in your browser
          </li>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            If you do not want to receive further marketing communication from us. or if you have questions about our
            privacy policy. please email us at :<span class="ered"> contactus@immersionslabs.com</span>
          </li>
          &nbsp; &nbsp;
          <h3 class="font-display text-jacarta-700 text-2xl dark:text-white">
            Uses
          </h3>
          <br />
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            We do not share your information with other companes, nor with the government unless legally required to do
            so.
          </li>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            To fulfill your requests by us
          </li>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            To contact you for customer satisfaction surveys, market research or in connection with certain
            transactions.
          </li>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            To support products or services you have obtained from us.
          </li>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            For marketing purposes if you have permitted such use.
          </li>
          <li class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            To personalize your experience while you are on our sites, make navigation easier, and for Web site usage
            statistics.
          </li>
          &nbsp;&nbsp;
          <h3 class="font-display text-jacarta-700 text-2xl dark:text-white">
            Data Security
          </h3>
          &nbsp;&nbsp;
          <p class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            We have put in place appropriate security measure to prevent your personal data from being accidentally
            lost, used or accessed in unauthorised to deal with any suspected personal data breach where we are legally
            required to do so. We take precautions to protect your information. When you submit sensitive information
            via the website, your information is protected both online and offline.
          </p>
          &nbsp; &nbsp;
          <h3 class="font-display text-jacarta-700 text-2xl dark:text-white">
            What We May Need From You
          </h3>
          &nbsp; &nbsp;
          <p class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            We may need to request specific information from you to help us confirm your identity and ensure your right
            to access your personal data (or to exercise any of your other rights)/This is a security measure to ensure
            that personal data is not disclosed to any person who has no right to receive it.We may also contact you to
            ask for further information in relation to your request to speed up our response.
          </p>
          &nbsp; &nbsp;
          <h3 class="font-display text-jacarta-700 text-2xl dark:text-white">
            Time Limit To Respond
          </h3>
          &nbsp; &nbsp;
          <p class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            We try to respond to all legitimate requests within 2-3 business days. In case if there is any delay. We
            will notify you and keep you updated.
          </p>
          &nbsp; &nbsp;
          <h3 class="font-display text-jacarta-700 text-2xl dark:text-white">
            Grievance Redressal
          </h3>
          &nbsp; &nbsp;
          <p class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            Any Grievance or complaint, in relation to processing of information, should be sent to Immersionslabs
            Corporation at <span class="ered">contactus@immersionslabs.com.</span> Grievance shall be redressed as
            expeditiously as possible.
          </p>
          &nbsp; &nbsp;
          <h3 class="font-display text-jacarta-700 text-2xl dark:text-white">
            Changes In The Policy
          </h3>
          &nbsp; &nbsp;
          <p class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            This policy may be updated keeping your privacy as our priority. Any updates on the same will be updated
            here for your reference. Please feel free to get in touch with us for any clarification at the below
            details.
          </p>
          &nbsp; &nbsp;
          <h3 class="font-display text-jacarta-700 text-2xl dark:text-white">
            Contact Details
          </h3>
          &nbsp; &nbsp;
          <p class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            Our full details are
          </p>
          <p class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            Email address :
            <span class="ered">
              <a target="_blank" href="mailto:contactus@immersionslabs.com">
                contactus@immersionslabs.com
              </a>
            </span>
          </p>
          <p class="dark:text-jacarta-300 mb-6 text-lg leading-normal">
            Postal Address: #710, 14th Cross Rd, 2nd Block, Nagarbhavi 1st Stage,
            Chandra Layout, Bengaluru, Karnataka INDIA 560072.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
<hr />
<!-- Blog Area End-->