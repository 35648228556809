import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { environment } from "projects/admin/src/environments/environment";

import { SharingService } from "../../shared/services/sharing.service";

@Component({
  selector: "app-blog-detail",
  templateUrl: "./blog-detail.component.html",
  styles: [],
})
export class BlogDetailComponent implements OnInit {
  private routeParamsObservable: any;

  constructor(
    private sharingService: SharingService,
    private toaster: ToastrService,
    private activateRoute: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.routeParamsObservable = this.activateRoute.params.subscribe(
      (params) => {
        let id = this.activateRoute.snapshot.params["id"];
        this.getBlogData(id);
      }
    );
  }

  cloudFrontUrl = environment.cloudFrontURL;
  isDataFetched: boolean = false;
  isErrorOccured: boolean = false;

  // =================== Get Blog Data By Id ==================== //
  blogResponse: any;
  blogData: any;
  getBlogData(id: any) {
    this.sharingService.getBlogById(id).subscribe({
      next: (res: any) => {
        this.blogResponse = res;
        // console.log("blog response", this.blogResponse);
        if (this.blogResponse.status == 1) {
          this.isDataFetched = true;
          this.blogData = this.blogResponse.blog;
          this.getMember(this.blogData.blog_published_by);
          this.getAllBlogsData(this.blogData.blog_published_by, id);
        }
      },
      error: (err: any) => {
        this.isErrorOccured = true;
        this.isDataFetched = false;
        console.log("error", err);
      },
    });
  }
  // =================== Get Blog Data By Id ==================== //

  // =================== Get Member Data By Id ==================== //
  SpecificMemberRes: any;
  SpecificMemberData: any;
  getMember(MemberId: any) {
    this.sharingService.getSpecificMember(MemberId).subscribe({
      next: (res: any) => {
        this.SpecificMemberRes = res;
        console.log("member Res", this.SpecificMemberRes);
        if (this.SpecificMemberRes.status == 1) {
          this.SpecificMemberData = this.SpecificMemberRes.team;
        } else {
          this.toaster.error(this.SpecificMemberRes.message);
        }
      },
      error: (err: any) => {
        console.log("get member error", err);
      },
      complete: () => {},
    });
  }
  // =================== Get Member Data By Id ==================== //

  // =================== Get All Blogs Data  ==================== //
  blogsResponse: any;
  blogsData: any = [];
  getAllBlogsData(memberId: any, blogId: any) {
    this.sharingService.getAllBlogs().subscribe({
      next: (res: any) => {
        this.blogsResponse = res;
        if (this.blogsResponse.status == 1) {
          this.isDataFetched = true;
          let blogs = this.blogsResponse.blogs;
          let member_blogs_Data = blogs.filter(
            (el: any) => el.blog_published_by == memberId
          );
          this.blogsData = member_blogs_Data.filter(
            (el: any) => el.blog_id !== blogId
          );
          console.log("this.blogsData", this.blogsData);
        }
      },
      error: (err: any) => {
        this.isErrorOccured = true;
        this.isDataFetched = false;
        console.log("error", err);
      },
    });
  }
  // =================== Get All Blogs Data  ==================== //

  naviagteToDetail(id: any, title: any) {
    const memberName = title.split(" ").join("-").toLowerCase();
    let finalmemberName = memberName.replace(
      /[&\/\\#,+()$~%!@^;|.'":*?<>{}]/g,
      ""
    );
    this.router.navigate(["/about-us/members/details", id, finalmemberName]);
  }

  // =================== copy page url  ==================== //
  copyClipboard() {
    // console.log(window.location.href);
    let url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      this.toaster.success("URL Copied");
    });
  }

  // ================== Called on ID Change ================== //
  ngOnDestroy() {
    if (this.routeParamsObservable != null) {
      this.routeParamsObservable.unsubscribe();
    }
  }
}
