import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { SwiperOptions } from "swiper";
import { ModelService } from "../shared/services/models.service";
import { S3ResourceService } from "../shared/services/s3-resource.service";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-threed-demos",
  templateUrl: "./threed-demos.component.html",
  //styleUrls: ["./threed-demos.component.css"],
})
export class ThreedDemosComponent implements OnInit {
  gradient_creative: any;
  arSiteUrl: any;

  constructor(
    private modelSevice: ModelService,
    private router: Router,
    private s3serviceurl: S3ResourceService
  ) {
    this.arSiteUrl = environment.ardemos_host_url;

    this.windowResize();
  }

  ngOnInit(): void {
    this.toggleSpinner = true;
    this.getAllModels();
    this.gradient_creative = this.s3serviceurl.gradient_creative;
  }

  config: SwiperOptions = {
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    slidesPerView: 4,
    spaceBetween: 10,
    // Responsive breakpoints
    breakpoints: {
      // when window width is >= 320px
      320: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      // when window width is >= 480px
      480: {
        slidesPerView: 1,
        spaceBetween: 10,
      },
      // when window width is >= 640px
      640: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 20,
      },
      992: {
        slidesPerView: 4,
        spaceBetween: 20,
      },
      1024: {
        slidesPerView: 5,
        spaceBetween: 20,
      },
      1440: {
        slidesPerView: 6,
        spaceBetween: 20,
      },
      2560: {
        slidesPerView: 7,
        spaceBetween: 20,
      },
    },
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
    },
    speed: 1500,
    loop: true,
    effect: "coverflow",
    grabCursor: true,
    coverflowEffect: {
      slideShadows: false,
      rotate: 4,
      stretch: 0,
      depth: 6,
      modifier: 6,
    },
  };

  toggleSpinner: boolean = false;
  // All models
  model_json_data: any;
  err_model_json_data: any;
  models_data: any = [];
  toggleModelsData: boolean = false;
  getAllModels() {
    this.modelSevice.getAllActive3DModels().subscribe({
      next: (val) => {
        this.model_json_data = val;
        // console.log(this.model_json_data);
      },
      error: (err) => {
        console.log(err);
        this.toggleSpinner = false;
      },
      complete: () => {
        if (this.model_json_data.status == 1) {
          this.models_data = this.model_json_data.models;
          this.models_data.map((item: any) => {
            item.cloudfront_url = environment.cloudFrontURL;
          });
          if (this.models_data.length == 0) {
            this.toggleModelsData = false;
          } else {
            this.toggleSpinner = false;
            this.toggleModelsData = true;
            // setTimeout(()=>{
            //   this.toggleSpinner = false;
            //   this.toggleModelsData = true;
            // },1000)
          }
        }
      },
    });
  }

  navigate(modelsdata: any) {
    this.router.navigate(["3D/details", modelsdata.model_id]);
  }

  windowWidth = window.innerWidth;
  windowHeight = window.innerHeight;

  skeletonCount: number = 5;
  windowResize() {
    // console.log("window width", this.windowWidth);
    if (
      this.windowWidth === 414 ||
      this.windowWidth < 414 ||
      this.windowWidth === 540
    ) {
      this.skeletonCount = 1;
    } else if (this.windowWidth === 1024) {
      this.skeletonCount = 3;
    } else if (
      this.windowWidth === 820 ||
      this.windowWidth === 768 ||
      this.windowWidth === 912
    ) {
      this.skeletonCount = 2;
    }
  }

  // default image for images loading //
  defaultImage = "../../assets/images/loading.gif";
}
