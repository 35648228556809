import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-image-loader",
  templateUrl: "./image-loader.component.html",
  styles: [],
})
export class ImageLoaderComponent implements OnInit {
  constructor() { }

  ngOnInit(): void { }

  themeWidth: any = {
    "border-radius": "20px",
    width: "250px",
    height: "250px",
    "margin-right": "10px",
    "background-color": "#131740",
    border: "1px solid #131740",
    "animation-duration": "2s",
  };
}
