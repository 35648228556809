import { Component, OnInit } from "@angular/core";
import { CookieService } from "ngx-cookie-service";
import { S3ResourceService } from "../shared/services/s3-resource.service";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.css"],
})
export class FooterComponent implements OnInit {
  PolicycookieValue: any;
  websitePrivacyStatus: any;
  webistePolicyCondition: boolean = false;
  googlePlay: string = "";
  branch: boolean = false;

  constructor(
    private cookieService: CookieService,
    public s3serviceurl: S3ResourceService
  ) { }

  ngOnInit(): void {
    this.PolicycookieValue = this.cookieService.get("websitePrivacyStatus");
    this.websitePrivacyStatus = this.cookieService.check(
      "websitePrivacyStatus"
    );
    if (!this.websitePrivacyStatus) {
      this.webistePolicyCondition = true;
    }

    this.googlePlay = this.s3serviceurl.google_play;
    if (environment.production == true) {
      this.branch = true;
    } else {
      this.branch = false;
    }
  }

  policyFloatingBox() {
    var element = <HTMLInputElement>document.getElementById("policyContainer");
    element.style.display = "none";
    this.cookieService.set("websitePrivacyStatus", "Accepted", { expires: 30 });
  }
}
