<!-- Table -->
<div class="container" style="margin-top: 13%">
  <h1 class="font-display text-jacarta-700 text-center text-xl dark:text-white" style="margin-bottom: 3%">
    Job Details
  </h1>

  <!-- Details -->
  <div class="container">
    <div
      class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-white p-6 md:p-10">
      <div>
        <div class="mb-6">
          <label class="font-display mb-1 text-accent block dark:text-accent-300">
            Job-Title:
          </label>
          <a class="text-jacarta-700 dark:text-white">
            {{ result2.job_title }}
          </a>
        </div>
        <div class="mb-6">
          <label class="font-display mb-1 text-accent block dark:text-accent-300">
            Job-SubTitle:
          </label>
          <span class="text-jacarta-700 dark:text-white">
            {{ result2.job_subtitle }}
          </span>
        </div>
        <div class="mb-6">
          <label class="font-display mb-1 text-accent block dark:text-accent-300">
            Job-Description:
          </label>
          <span class="text-jacarta-700 dark:text-white" [innerHTML]="result2.job_description"></span>
        </div>
        <div class="mb-6">
          <label class="font-display mb-1 text-accent block dark:text-accent-300">
            Skill-Requirements:
          </label>
          <span class="text-jacarta-700 dark:text-white" [innerHTML]="result2.job_skill_requirements"></span>
        </div>
        <div class="mb-6">
          <label class="font-display mb-1 text-accent block dark:text-accent-300">
            Job-Location:
          </label>
          <a class="text-jacarta-700 dark:text-white">
            {{ result2.job_location }}
          </a>
        </div>
        <div class="mb-6">
          <label class="font-display mb-1 text-accent block dark:text-accent-300">
            Job-Status:
          </label>
          <p *ngIf="result2.job_status == 1" class="text-jacarta-700 dark:text-white">
            Active
          </p>
          <p *ngIf="result2.job_status == 0" class="text-jacarta-700 dark:text-white">
            InActive
          </p>
        </div>
        <div class="mb-6">
          <label class="font-display mb-1 text-accent block dark:text-accent-300">
            Job-Note:
          </label>
          <p class="text-jacarta-700 dark:text-white" [innerHTML]="result2.job_note"></p>
        </div>
        <div class="mb-6">
          <label class="font-display mb-1 text-accent block dark:text-accent-300">
            Job-Type:
          </label>
          <div class="mb-16 flex items-center">
            <div class="flex space-x-2">
              <div *ngFor="let i of result2.job_type"
                class="dark:bg-jacarta-700 dark:border-white-300 border-jacarta-100 rounded-xl border bg-white py-2 px-2">
                <a class="text-jacarta-700 dark:text-white">{{ i }}</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <h1 class="font-display text-jacarta-700 text-center text-xl dark:text-white"
    style="margin-bottom: 3%; margin-top: 3%">
    Fill the below form to Submit Your Application
  </h1>

  <section style="margin-top: 5%; margin-bottom: 20%">
    <div class="container">
      <form id="contact-form" method="post" [formGroup]="Applicantform">
        <div class="mb-4">
          <label for="name" class="font-display text-jacarta-700 mb-1 block text-m dark:text-white">
            Job ID: {{ result2.job_id }}
          </label>
          <input type="hidden" formControlName="applicant_job_id" />
          <br />
          <label for="name" class="font-display text-jacarta-700 mb-1 block text-m dark:text-white">
            Applicant Name: <span class="text-red">*</span>
          </label>
          <!--name input-->
          <input name="Name" id="applicant_name" type="text" formControlName="applicant_name" required
            class="contact-form-input dark:bg-jacarta-700 border-jacarta-300 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white" />
          <span *ngIf="applicant_name?.invalid && applicant_name?.touched" class="error">
            Name required
          </span>
        </div>
        <br />
        <div class="mb-4">
          <label for="email" class="font-display text-jacarta-700 mb-1 block text-m dark:text-white">
            Applicant Email: <span class="text-red">*</span>
          </label>
          <!--email input-->
          <input name="email"
            class="contact-form-input dark:bg-jacarta-700 border-jacarta-300 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
            id="applicant_email" type="email" formControlName="applicant_email" required />
          <span *ngIf="applicant_email?.invalid && applicant_email?.touched" class="error">
            Email required
          </span>
        </div>
        <br />
        <div class="mb-4">
          <label for="phone" class="font-display text-jacarta-700 mb-1 block text-m dark:text-white">
            Applicant Phone Number:
          </label>
          <input type="text" name="number" formControlName="applicant_mobile"
            class="contact-form-input dark:bg-jacarta-700 border-jacarta-300 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
            id="applicant_phone" />
        </div>
        <div class="mb-4">
          <label for="email" class="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">
            Applicant Resume: <span class="text-red">*</span>
          </label>
          <!--subject input-->
          <input type="file" (change)="selectResume($event)" formControlName="applicant_resume" accept=".pdf" />
          <p class="mt-2 text-red">upload pdf only</p>
          <!-- <input name="enquiry_subject" type="file" class="form-control-file" id="exampleFormControlFile1"
            class="contact-form-input dark:bg-jacarta-700 border-jacarta-300 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
            id="applicant_resume" formControlName="applicant_resume" required /> -->
          <span *ngIf="applicant_resume?.invalid && applicant_resume?.touched" class="error">
            Resume required
          </span>
        </div>
        <br />
        <div class="mb-4">
          <label for="email" class="font-display text-jacarta-700 mb-1 block text-m dark:text-white">
            Applicant Cover Letter: <span class="text-red">*</span>
          </label>
          <!--subject input-->
          <!-- <input name="enquiry_subject"
          class="contact-form-input dark:bg-jacarta-700 border-jacarta-300 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
          id="applicant_cover_letter" type="text" formControlName="applicant_cover_letter" required /> -->
          <input type="file" (change)="selectCover($event)" formControlName="applicant_cover_letter" accept=".pdf" />
          <p class="mt-2 text-red">upload pdf only</p>
          <span *ngIf="
              applicant_cover_letter?.invalid && applicant_cover_letter?.touched
            " class="error">
            Cover Letter required
          </span>
        </div>

        <br />

        <!-- <div class="mb-4">
          <label
            for="Phone"
            class="font-display text-jacarta-700 mb-1 block text-m dark:text-white">
            Applicant Phone:
          </label>
          <input
            name=""
            class="contact-form-input dark:bg-jacarta-700 border-jacarta-300 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
            id="applicant_phone"
            type="number"
            formControlName="applicant_phone" />
        </div> -->

        <button type="submit"
          class="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
          id="contact-form-submit" [disabled]="Applicantform.invalid" (click)="submit()">
          Submit
        </button>
        <div id="contact-form-notice" class="relative mt-4 hidden rounded-lg border border-transparent p-4"></div>
      </form>
    </div>
  </section>
  &nbsp;
  <hr />
</div>