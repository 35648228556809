import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { contact } from "../../modals/contact";
import { subscriber } from "../../modals/subscriber";
import { applicants } from "../../modals/applicants";

import { throwError } from "rxjs";
import { Subject } from "rxjs";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class SharingService {
  private _item: number = 0;
  private _id: number = 0;

  // SharingData = new Subject();
  SharingId = new Subject();

  REST_API: any;
  ardemo_api: any;
  httpHeaders = new HttpHeaders().set("Content-Type", "text/plain");

  constructor(private httpClient: HttpClient, private toaster: ToastrService) {
    this.REST_API = environment.website_api_key;
    this.ardemo_api = environment.ardemos_api_key;
  }

  // scroll
  NavigateId(id: number) {
    this.SharingId.next(id);
  }

  success(msg: any) {
    this.toaster.success(msg);
  }
  failure(msg: any) {
    this.toaster.error(msg);
  }

  GetAllJobs() {
    return this.httpClient.get(this.REST_API + "/jobs");
  }

  GetJobsById(id: any) {
    return this.httpClient.get(`${this.REST_API}/job/${id}`);
  }

  //Booking form Data
  bookDemo(data: any) {
    return this.httpClient.put(this.ardemo_api + "bookademo", data);
  }

  //feedbackform Data
  putFeedback(data: any) {
    return this.httpClient.put(this.ardemo_api + "addfeedback", data);
  }

  putContact(data: contact): any {
    let API_URL = `${this.REST_API}/createenquiry`;
    let res = this.httpClient.put(API_URL, data);
    // console.log(res);
    return res;
  }

  //putApplicant
  putApplication(data: applicants): any {
    let API_URL = `${this.REST_API}/applyjob`;
    let res = this.httpClient.put(API_URL, data);
    // console.log(res);
    return res;
  }

  //put-subscriber
  putSubscriber(data: subscriber): any {
    let API_URL = `${this.REST_API}/subscriber`;
    let res = this.httpClient.put(API_URL, data);
    // console.log(res);
    return res;
  }

  handleError(error: HttpErrorResponse) {
    let errorMessage = "";
    if (error.error instanceof ErrorEvent) {
      // Handle client error
      errorMessage = error.error.message;
    } else {
      // Handle server error
      errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
    }
    // console.log(errorMessage);
    return throwError(errorMessage);
  }

  GetAllUsecases() {
    return this.httpClient.get(this.ardemo_api + "usecases");
  }

  GetAllservices() {
    return this.httpClient.get(this.REST_API + "/getAllservices");
  }

  // ==========  Blogs ============ //
  getAllBlogs() {
    return this.httpClient.get(this.REST_API + "/blog/active")
  }

  getBlogById(id: any) {
    return this.httpClient.get(this.REST_API + "/blog/" + id);
  }

  getSpecificMember(member_id: any) {
    return this.httpClient.get(this.REST_API + "/team/" + member_id);
  }

  getBlogsByMemberId(member_id: any) {
    return this.httpClient.get(this.REST_API + "/blog/team/" + member_id);
  }

  // =========================== Contributors ============================ //
  getAllContributors() {
    return this.httpClient.get(this.REST_API + "/contributors");
  }
}
