<!-- <div class="text-center" *ngIf="toggleSpinner">
  <div class="spinner-border text-danger" role="status">
    <span class="sr-only">Loading...</span>
  </div>
</div> -->

<!-- Skeleton Container starts -->
<div class="container mtFive" *ngIf="toggleSpinner">
  <div class="item text-center">
    <ngx-skeleton-loader [theme]="{
        'border-radius': '20px',
        height: '25px',
        width: '200px',
        'margin-right': '18px',
        'background-color': '#131740',
        border: '1px solid #131740',
        'animation-duration': '2s'
      }">
    </ngx-skeleton-loader>
  </div>
  <div class="item text-center">
    <ngx-skeleton-loader [count]="skeletonCount" appearance="circle" [theme]="{
        'border-radius': '20px',
        width: '200px',
        height: '250px',
        'margin-right': '20px',
        'background-color': '#131740',
        border: '1px solid #131740',
        'animation-duration': '2s'
      }">
    </ngx-skeleton-loader>
  </div>
</div>
<!-- Skeleton Container End -->

<!-- 3d model list start -->
<section class="dark:bg-jacarta-800 bg-light-base relative pt-20" *ngIf="toggleModelsData">
  <picture class="dark:block hidden pointer-events-none absolute inset-0">
    <img [src]="gradient_creative" alt="gradient" class="h-full w-full" />
  </picture>
  <div class="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1">
    <div class="relative mb-5" id="threedListContainer">
      <h2 class="font-display text-jacarta-700 mb-8 text-center text-5xl dark:text-white"
        style="text-shadow: 1px 1px #000000">
        Demo Cards
      </h2>
      <div class="p-[1.1875rem]">
        <swiper [config]="config">
          <div class="swiper-wrapper">
            <div class="swiper-slide" *ngFor="let slide of models_data">
              <a target="_blank" href="{{ arSiteUrl }}/{{ slide.model_url }}" class="cursor-pointer">
                <div
                  class="mt-1 mb-8 dark:bg-jacarta-700 border border-jacarta-500 block rounded-2xl bg-white p-[0.1875rem] transition-shadow hover:shadow-lg">
                  <div class="img-container">
                    <img [defaultImage]="defaultImage"
                      class="swiper-lazy h-[500px] w-full object-cover swiper-lazy-loaded rounded-t-r-2xl"
                      [lazyLoad]="slide.cloudfront_url + slide.model_gif" />
                    <!-- <img src="{{slide.model_gif}}"
                      class="swiper-lazy h-[500px] w-full object-cover swiper-lazy-loaded rounded-t-r-2xl"
                      alt="{{slide.model_name}}"> -->
                  </div>
                  <div class="p-[1.1875rem]">
                    <h5 class="font-display text-jacarta-700 mb-2 text-lg dark:text-white">
                      {{ slide.model_name }}
                    </h5>
                    <div class="sm:flex sm:flex-wrap items-center">
                      <div class="sm:w-1/2">
                        <div class="items-center">
                          <div class="mr-4">
                            <p class="dark:text-jacarta-300 font-bold text-xs"
                              *ngIf="slide.rating == 0 || slide.rating == 'NaN'">
                              <span class="text-xs mr-1">
                                <i class="fa fa-star"> </i>
                              </span>
                              <span class="text-xs mr-1">
                                <i class="fa fa-star"> </i>
                              </span>
                              <span class="text-xs mr-1">
                                <i class="fa fa-star"> </i>
                              </span>
                              <span class="text-xs mr-1">
                                <i class="fa fa-star"> </i>
                              </span>
                              <span class="text-xs mr-1">
                                <i class="fa fa-star"> </i>
                              </span>
                              (New)
                            </p>
                            <p class="dark:text-jacarta-300 mb-3 font-bold text-xs" *ngIf="slide.rating == 1">
                              <span class="text-xs mr-1">
                                <i class="fa fa-star checked-star"></i>
                              </span>
                            </p>
                            <p class="dark:text-jacarta-300 mb-3 font-bold text-xs" *ngIf="slide.rating == 2">
                              <span class="text-xs mr-1">
                                <i class="fa fa-star checked-star"></i>
                              </span>
                              <span class="text-xs mr-1">
                                <i class="fa fa-star checked-star"></i>
                              </span>
                            </p>
                            <p class="dark:text-jacarta-300 mb-3 font-bold text-xs" *ngIf="slide.rating == 3">
                              <span class="text-xs mr-1">
                                <i class="fa fa-star checked-star"></i>
                              </span>
                              <span class="text-xs mr-1">
                                <i class="fa fa-star checked-star"></i>
                              </span>
                              <span class="text-xs mr-1">
                                <i class="fa fa-star checked-star"></i>
                              </span>
                            </p>
                            <p class="dark:text-jacarta-300 mb-3 font-bold text-xs" *ngIf="slide.rating == 4">
                              <span class="text-xs mr-1">
                                <i class="fa fa-star checked-star"></i>
                              </span>
                              <span class="text-xs mr-1">
                                <i class="fa fa-star checked-star"></i>
                              </span>
                              <span class="text-xs mr-1">
                                <i class="fa fa-star checked-star"></i>
                              </span>
                              <span class="text-xs mr-1">
                                <i class="fa fa-star checked-star"></i>
                              </span>
                            </p>
                            <p class="dark:text-jacarta-300 mb-3 font-bold text-xs"
                              *ngIf="slide.rating == 5 || slide.rating == 4.5">
                              <span class="text-xs mr-1">
                                <i class="fa fa-star checked-star"></i>
                              </span>
                              <span class="text-xs mr-1">
                                <i class="fa fa-star checked-star"></i>
                              </span>
                              <span class="text-xs mr-1">
                                <i class="fa fa-star checked-star"></i>
                              </span>
                              <span class="text-xs mr-1">
                                <i class="fa fa-star checked-star"></i>
                              </span>
                              <span class="text-xs mr-1">
                                <i class="fa fa-star checked-star"></i>
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                      <div class="sm:w-1/2">
                        <div class="items-center">
                          <div class="mr-4">
                            <p class="dark:text-jacarta-300 font-bold text-sm text-right">
                              <!-- <ng-container *ngIf="slide.likes == 0" else likes>
                              <span class="text-sm"><i class="fa fa-heart text-red"></i> 0</span>
                            </ng-container>
                            <ng-template #likes>
                              <span class="text-sm"><i class="fa fa-heart text-red"></i> {{slide.likes}}</span>
                            </ng-template> -->
                              <span *ngIf="slide.likes == 1" class="text-xs">
                                <i class="fa fa-heart text-red mr-2"></i>
                                {{ slide.likes }} Like
                              </span>
                              <span *ngIf="slide.likes > 1 || slide.likes == 0" class="text-xs">
                                <i class="fa fa-heart text-red mr-2"></i>
                                {{ slide.likes }} Likes
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          </div>

          <!-- Add Pagination -->
          <!-- <div class="swiper-pagination mt-20"></div> -->

          <!-- Add Arrows -->
          <div class="swiper-button-next"></div>
          <div class="swiper-button-prev"></div>
        </swiper>
      </div>
      <div class="text-center py-6 relative z-auto">
        <button
          class="bg-accent shadow-accent-volume hover:bg-accent-dark w-48 rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
          routerLink="/alldemos">
          View All Demos
        </button>
      </div>
    </div>
  </div>
</section>
<!-- 3d model list end -->