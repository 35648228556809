import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";

@Component({
  selector: "app-blog-card",
  templateUrl: "./blog-card.component.html",
  styles: [],
})
export class BlogCardComponent implements OnInit {
  @Input() blog: any;

  constructor(private router: Router) {}

  ngOnInit(): void {}

  // ================== Get Category Name  ==================== //
  categories: any = ["Web", "DevOps", "3D", "Article"];
  getCategoryName(value: any) {
    return this.categories[value];
  }

  // =================== Navigate To Detail ==================== //
  navigateToDetail(id: any, name: any) {
    const productName = name.split(" ").join("-").toLowerCase();
    let finalProductName = productName.replace(
      /[&\/\\#,+()$~%!@^;|.'":*?<>{}]/g,
      ""
    );
    this.router.navigate(["/blogs/details", id, finalProductName]);
  }
}
