import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ToastrService } from "ngx-toastr";

import { SharingService } from "../shared/services/sharing.service";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-team-member-detail",
  templateUrl: "./team-member-detail.component.html",
  styles: [],
})
export class TeamMemberDetailComponent implements OnInit {
  constructor(
    private sharingService: SharingService,
    private toaster: ToastrService,
    private activateRoute: ActivatedRoute
  ) { }

  isDataFetched: boolean = false;
  isErrorOccured: boolean = false;
  cloundfrontUrl = environment.cloudFrontURL;

  ngOnInit(): void {
    let id = this.activateRoute.snapshot.params["id"];
    this.getMember(id);
    this.getMemberBlogs(id);
  }

  // =================== Get Member Data By Id ==================== //
  SpecificMemberRes: any;
  SpecificMemberData: any;
  getMember(MemberId: any) {
    this.sharingService.getSpecificMember(MemberId).subscribe({
      next: (res: any) => {
        this.SpecificMemberRes = res;
        // console.log("member Res", this.SpecificMemberRes);
        if (this.SpecificMemberRes.status == 1) {
          this.isDataFetched = true;
          this.SpecificMemberData = this.SpecificMemberRes.team;
        } else {
          this.toaster.error(this.SpecificMemberRes.message);
        }
      },
      error: (err: any) => {
        this.isErrorOccured = true;
        this.isDataFetched = false;
        console.log("get member error", err);
      },
      complete: () => { },
    });
  }
  // =================== Get Member Data By Id ==================== //

  // =================== Get Member Blogs By Id ==================== //
  memberBlogsRes: any;
  memberPublishedBlogs: any = [];
  memberContributedBlogs: any = [];
  getMemberBlogs(MemberId: any) {
    this.sharingService.getBlogsByMemberId(MemberId).subscribe({
      next: (res: any) => {
        this.memberBlogsRes = res;
        console.log("member Res", this.memberBlogsRes);
        if (this.memberBlogsRes.status == 1) {
          this.memberPublishedBlogs = this.memberBlogsRes.published_blogs;
          this.memberContributedBlogs = this.memberBlogsRes.contributed_blogs;
        } else {
          this.toaster.error(this.memberBlogsRes.message);
        }
      },
      error: (err: any) => {
        console.log("get member blogs error", err);
      },
      complete: () => { },
    });
  }
}
