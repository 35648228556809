import { Component, OnInit } from "@angular/core";
import { SwiperOptions } from "swiper";
import { TestimonialsService } from "../shared/services/testimonials.service";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-testimonials",
  templateUrl: "./testimonials.component.html",
  styleUrls: ["./testimonials.component.css"],
})
export class TestimonialsComponent implements OnInit {
  testmonials_slidestores: any;
  constructor(private testimonialsService: TestimonialsService) { }

  ngOnInit(): void {
    this.getfeatureTestimonials();
  }
  config: SwiperOptions = {
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
    },
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    slidesPerView: 1,
    spaceBetween: 10,
    autoplay: {
      delay: 1500,
      disableOnInteraction: false,
    },
    speed: 1500,
    loop: true,
  };

  testimonial_array: any;
  err_testimonial_array: any;
  hide_section!: boolean;
  getfeatureTestimonials() {
    this.testimonialsService.getFeaturedTestimoials().subscribe({
      next: (val) => {
        this.testimonial_array = val;
      },
      error: (err) => {
        // console.log(err);
        this.err_testimonial_array = err;
      },
      complete: () => {
        if (this.testimonial_array.status == 1) {
          this.testmonials_slidestores = this.testimonial_array.testimonials;
          this.testmonials_slidestores.map((item: any) => {
            item.cloudfront_url = environment.cloudFrontURL;
          });
          if (this.testimonial_array.count == 0) {
            this.hide_section = true;
          } else {
            this.hide_section = false;
          }
        }
      },
    });
  }
}
