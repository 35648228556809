import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { SharingService } from "../shared/services/sharing.service";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-services-section",
  templateUrl: "./services-section.component.html",
  styleUrls: ["./services-section.component.css"],
})
export class ServicesSectionComponent implements OnInit {
  constructor(private service: SharingService) { }

  ngOnInit(): void {
    this.windowResize();
    this.getallServices();
  }

  showSkeletonloading: boolean = false;
  services_list: any = [];
  services_data: any;
  getallServices() {
    this.showSkeletonloading = false;
    this.service.GetAllservices().subscribe({
      next: (val: any) => {
        this.services_data = val;
        this.showSkeletonloading = true;
        this.services_list = this.services_data.data;
        this.services_list.map((item: any) => {
          item.cloudfront_url = environment.cloudFrontURL;
        });
      },
      error: (err: any) => {
        let errData = err;
        // console.log(errData.error.message);
      },
    });
  }

  // for service video
  serviceVideo: any;
  async viewServiceVideo(video: string) {
    //  if(video){
    //    //this.serviceVideo = await this.s3fileservice.getSignedImage(video);
    //    this.serviceVideo = video;
    //    document.getElementById("ServiceSButton")?.click();
    //    const player = this.elRef.nativeElement.querySelector('video');
    //    player.load();
    //  }
  }

  @ViewChild("videoPlayer") videoplayer!: ElementRef;
  toggleVideo() {
    this.videoplayer.nativeElement.pause();
  }

  skeletonCount: number = 4;

  windowWidth = window.innerWidth;
  windowHeight = window.innerHeight;

  themeWidth: any = {
    "border-radius": "20px",
    width: "250px",
    height: "300px",
    "margin-right": "30px",
    "background-color": "#131740",
    border: "1px solid #131740",
    "animation-duration": "2s",
  };

  windowResize() {
    if (
      this.windowWidth === 414 ||
      this.windowWidth < 414 ||
      this.windowWidth === 540
    ) {
      this.themeWidth.width = "320px";
      this.themeWidth.height = "350px";
      this.skeletonCount = 2;
    } else if (
      this.windowWidth === 820 ||
      this.windowWidth === 768 ||
      this.windowWidth === 912 ||
      this.windowWidth === 1024
    ) {
      this.skeletonCount = 2;
      this.themeWidth.width = "320px";
    }
  }
}
