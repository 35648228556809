import { Component, OnInit } from "@angular/core";
import { SharingService } from "../shared/services/sharing.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-contributors",
  templateUrl: "./contributors.component.html",
  styles: [],
})
export class ContributorsComponent implements OnInit {
  constructor(private apiservice: SharingService, private router: Router) { }

  ngOnInit(): void {
    this.getAllContributorsdata();
  }

  contributors_section: boolean = true;

  contributorsRes: any;
  allContributorsData: any = [];

  contributorsData: any = [];
  partnersdata: any = [];
  clientsdata: any = [];
  getAllContributorsdata() {
    this.apiservice.getAllContributors().subscribe({
      next: (res: any) => {
        this.contributorsRes = res;
        // console.log("this.contributorsRes",this.contributorsRes);
        if (this.contributorsRes.status === 1) {
          this.allContributorsData = this.contributorsRes.Contributors;
          if (this.allContributorsData.count == 0) {
            this.contributors_section = false;
          }
          for (let i = 0; i < this.allContributorsData?.length; i++) {
            if (this.allContributorsData[i].contributor_type == 0) {
              this.contributorsData.push(this.allContributorsData[i]);
            } else if (this.allContributorsData[i].contributor_type == 1) {
              this.partnersdata.push(this.allContributorsData[i]);
            } else if (this.allContributorsData[i].contributor_type == 2) {
              this.clientsdata.push(this.allContributorsData[i]);
            }
          }
        }
      },
      error: (err: any) => {
        console.log("contributors fetching error :", err);
      },
      complete: () => { },
    });
  }
}
