<div style="margin-top: 10%">
  <h2 class="font-display text-jacarta-500 mb-12 text-center text-2xl dark:text-white">
    OUR STORY
  </h2>
</div>

<!-- <div style="margin-left: 90%; margin-top: -8%">
  <a routerLink="home" class="font-display dark:text-jacarta-400 mb-12 text-2xl dark:text-white">
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="bi bi-house"
      viewBox="0 0 16 16">
      <path fill-rule="evenodd"
        d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
      <path fill-rule="evenodd"
        d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z" />
    </svg>
  </a>
</div> -->

<!-- Blog Area Start -->
<section style="margin-top: 5%">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div>
          <h3 class="font-display text-jacarta-700 text-2xl dark:text-white">
            Who are we?
          </h3>
          &nbsp;
          <p class="dark:text-jacarta-400 mb-6 text-lg leading-normal">
            Team of Augmented Reality (AR) experts from India, Germany and USA specializing in combining digital and
            physical environments. Through AR we supplement physical places for example exhibits, tradeshows, newspaper
            ads, cardboard print ads, brand rooms, etcetera with interactive digital elements. In terms of service
            lines, we provide Augmented Reality via WEB, Android, iOS, and AR Glasses as well in the new future combined
            with Machine Learning backed by intensely potential Cloud Technologies.
          </p>
          &nbsp;
          <h3 class="font-display text-jacarta-700 text-2xl dark:text-white">
            What we can do?
          </h3>
          &nbsp;
          <p class="dark:text-jacarta-400 mb-6 text-lg leading-normal">
            One of our predominant service lines are WEB AR, completely App&apos;less AR Experience, with hassle free
            and install free over the mobile devices featuring both licensed and custom 2D and 3D content.
          </p>
          <p class="dark:text-jacarta-400 mb-6 text-lg leading-normal">
            Our expertise just doesn&apos;t stop there, we have a strong research team in India working on bringing
            analogue world to digital in all possible ways for example, giving life to newspaper ads and letting the
            products talk for themselves while shopping in the supermarket.
          </p>
          &nbsp;
          <p class="dark:text-jacarta-400 mb-6 text-lg leading-normal">
            We create real time AR and no AR Software Solutions and there is no need for an agency/client to compromise
            its objectives because of a program&apos;s shortcomings or constraints – we make software&apos;s work for
            you and we provide the best suitable and productive solutions to you. We program to bridge the gaps and we
            do it on time and within the budget.
          </p>
          <p class="dark:text-jacarta-400 mb-6 text-lg leading-normal">
            Immersion Software Labs has the potential to completely change multiple verticals including Marketing,
            Manufacturing, Print and Packaging, Healthcare, Education, etc by enabling AR into their line of work
            thereby enhancing the productivity and engagement.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>
&nbsp;
<hr />
<!-- Blog Area End-->