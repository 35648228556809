import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModelService } from "../shared/services/models.service";
import { ToastrService } from "ngx-toastr";
import { S3ResourceService } from "../shared/services/s3-resource.service";
import { environment } from "../../environments/environment";
import { TwodDemosService } from "../shared/services/twod-demos.services";

@Component({
  selector: "app-threed-models-list",
  templateUrl: "./all-demos-list.component.html",
  styleUrls: ["./all-demos-list.component.css"],
})
export class AllDemosListComponent implements OnInit {
  searchtext: string = "";
  arSiteUrl: any;
  constructor(
    private modelService: ModelService,
    private demoService: TwodDemosService,
    private router: Router,
    private toaster: ToastrService,
    public s3serviceurl: S3ResourceService
  ) {
    this.arSiteUrl = environment.ardemos_host_url;
  }

  gradient_dark: string = "";
  gradient: string = "";
  thunderPng: string = "";
  blastPng: string = "";

  // default image for images loading //
  defaultImage = "../../assets/images/loading.gif";

  cloudfrontUrl: any;

  ngOnInit(): void {
    this.gradient = this.s3serviceurl.gradient;
    this.gradient_dark = this.s3serviceurl.gradient_dark;
    this.thunderPng = this.s3serviceurl.thunderImage;
    this.blastPng = this.s3serviceurl.blastImage;

    this.cloudfrontUrl = this.getAllModels();
    this.getAll2Ddemos();
    this.getAllActiveThreedDemos();
    this.getAllProjects();
    this.getallUsecases();
    this.getAllModelTags();
    this.getAllthreedDemoTags();
    this.getAllDemoTags();
  }

  // All models
  model_json_data: any;
  err_model_json_data: any;
  models_data: any;
  specificthreedProjectResponse: any;
  show3dProject: boolean = false;
  specificthreedUsecaseResponse: any;
  show3dUsecase: boolean = false;
  getAllModels() {
    this.modelService.getAllActive3DModels().subscribe({
      next: (val) => {
        this.model_json_data = val;
        // console.log(this.model_json_data);
      },
      error: (err) => {
        this.searchtext;
        // console.log(err);
      },
      complete: () => {
        if (this.model_json_data.status == 1) {
          this.models_data = this.model_json_data.models;
          // assigning Project name to each model object
          for (let i = 0; i < this.models_data.length; i++) {
            this.models_data[i].cloudfront_url = environment.cloudFrontURL;
            let projectId = this.models_data[i].model_project;
            if (projectId) {
              this.demoService.getProjectById(projectId).subscribe({
                next: (res: any) => {
                  this.specificthreedProjectResponse = res;
                  if (this.specificthreedProjectResponse.status == 1) {
                    let specificProject =
                      this.specificthreedProjectResponse.project;
                    this.models_data[i].project_name =
                      specificProject.project_name;
                  }
                },
                error: (err: any) => {
                  let errData = err;
                  // this.toaster.error(errData.error.message);
                },
              });
            }
          }
          this.show3dProject = true;
          // projects end
          // Usecases Start //
          for (let i = 0; i < this.models_data.length; i++) {
            let usecaseIdsArray = this.models_data[i].model_usecases;
            let useCaseNamesArray: any = [];
            if (usecaseIdsArray.length > 0) {
              for (let j = 0; j < usecaseIdsArray.length; j++) {
                let useCaseId = usecaseIdsArray[j];
                this.demoService.getUsecaseById(useCaseId).subscribe({
                  next: (val: any) => {
                    this.specificthreedUsecaseResponse = val;
                    if (this.specificthreedUsecaseResponse.status == 1) {
                      // console.log("Response:",this.specificthreedUsecaseResponse);
                      let useCaseTitle =
                        this.specificthreedUsecaseResponse?.usecase
                          ?.usecase_title;
                      // console.log("usecasename:",useCaseTitle);
                      useCaseNamesArray.push(useCaseTitle);
                      // console.log("usecasenames array:",useCaseNamesArray);
                      this.models_data[i].usecaseNames = useCaseNamesArray;
                    }
                  },
                  error: (err: any) => {
                    let errData = err;
                  },
                });
              }
            }
          }
          this.show3dUsecase = true;
          // usecases end
        }
      },
    });
  }

  // All 2d emos
  demo_json_data: any;
  err_demo_json_data: any;
  demos_data: any;
  specificTwodProjectResponse: any;
  show2dProject: boolean = false;
  specifictwodUsecaseResponse: any;
  show2dUsecase: boolean = false;
  getAll2Ddemos() {
    this.demoService.getAllActive2dDemos().subscribe({
      next: (val) => {
        this.demo_json_data = val;
      },
      error: (err) => {
        // console.log(err);
      },
      complete: () => {
        if (this.demo_json_data.status == 1) {
          this.demos_data = this.demo_json_data.demos;
          // assigning Project name to each Demo object
          for (let i = 0; i < this.demos_data.length; i++) {
            // cloudfront url
            this.demos_data[i].demo_cloudfront_image =
              environment.cloudFrontURL + this.demos_data[i].demo_marker;
            // project
            let projectId = this.demos_data[i].demo_project;
            if (projectId) {
              this.demoService.getProjectById(projectId).subscribe({
                next: (res: any) => {
                  this.specificTwodProjectResponse = res;
                  if (this.specificTwodProjectResponse.status == 1) {
                    let specificProject =
                      this.specificTwodProjectResponse.project;
                    this.demos_data[i].project_name =
                      specificProject.project_name;
                  }
                },
                error: (err: any) => {
                  let errData = err;
                  // this.toaster.error(errData.error.message);
                },
              });
            }
          }
          this.show2dProject = true;
          // projects end
          // Usecases Start //
          for (let i = 0; i < this.demos_data.length; i++) {
            let usecaseIdsArray = this.demos_data[i].demo_usecases;
            let useCaseNamesArray: any = [];
            if (usecaseIdsArray.length > 0) {
              for (let j = 0; j < usecaseIdsArray.length; j++) {
                let useCaseId = usecaseIdsArray[j];
                this.demoService.getUsecaseById(useCaseId).subscribe({
                  next: (val: any) => {
                    this.specifictwodUsecaseResponse = val;
                    if (this.specifictwodUsecaseResponse.status == 1) {
                      // console.log("Response:",this.specifictwodUsecaseResponse);
                      let useCaseTitle =
                        this.specifictwodUsecaseResponse?.usecase
                          ?.usecase_title;
                      // console.log("usecasename:",useCaseTitle);
                      useCaseNamesArray.push(useCaseTitle);
                      // console.log("usecasenames array:",useCaseNamesArray);
                      this.demos_data[i].usecaseNames = useCaseNamesArray;
                    }
                  },
                  error: (err: any) => {
                    let errData = err;
                  },
                });
              }
            }
          }
          this.show2dUsecase = true;
          // usecases end
        }
      },
    });
  }

  modelTags: any = [];
  showModelTags: boolean = false;
  getAllModelTags() {
    this.modelService.getModelTags().subscribe({
      next: (res: any) => {
        let response = res;
        this.modelTags = response.tags;
        if (this.modelTags.length >= 36) {
          this.showModelTags = true;
        } else {
          this.showModelTags = false;
        }
      },
      error: (err: any) => {
        let errData = err;
        // console.log(errData.error.message);
      },
    });
  }

  demoTags: any = [];
  allTagsArray: any = [];
  showTwodDemoTags: boolean = false;
  getAllDemoTags() {
    this.demoService.getAllDemosTags().subscribe({
      next: (res: any) => {
        let response = res;
        this.demoTags = response.tags;
        if (this.demoTags.length >= 36) {
          this.showTwodDemoTags = true;
        } else {
          this.showTwodDemoTags = false;
        }
      },
      error: (err: any) => {
        let errData = err;
        // console.log(errData.error.message);
      },
      complete: () => { },
    });
  }

  // ==================================== 3d Demos =============================== //

  threedDemoTags: any = [];
  showThreedDemoTags: boolean = false;
  getAllthreedDemoTags() {
    this.modelService.getThreedDemoTags().subscribe({
      next: (res: any) => {
        let response = res;
        this.threedDemoTags = response.tags;
        if (this.threedDemoTags.length >= 36) {
          this.showThreedDemoTags = true;
        } else {
          this.showThreedDemoTags = false;
        }
      },
      error: (err: any) => {
        let errData = err;
        // console.log(errData.error.message);
      },
      complete: () => { },
    });
  }

  active_Models: any = [];
  toggleActiveModels!: boolean;
  specificthreedDemoProjectResponse: any;
  show3dDemoProject: boolean = false;
  specificthreedDemoUsecaseResponse: any;
  show3dDemoUsecase: boolean = false;
  getAllActiveThreedDemos() {
    this.modelService.getAllActiveModels().subscribe({
      next: (res: any) => {
        let response = res;
        // console.log("active 3D Demos", response);
        if (response.status == 1) {
          this.active_Models = response.demos;
          this.toggleActiveModels =
            this.active_Models.length == 0 ? false : true;

          this.active_Models.map((item: any) => {
            item.cloudfront_url = environment.cloudFrontURL;
          });

          // assigning Project name to each 3D Demo object
          for (let i = 0; i < this.active_Models.length; i++) {
            let projectId = this.active_Models[i].demo_project;
            if (projectId) {
              this.demoService.getProjectById(projectId).subscribe({
                next: (res: any) => {
                  this.specificthreedDemoProjectResponse = res;
                  if (this.specificthreedDemoProjectResponse.status == 1) {
                    let specificProject =
                      this.specificthreedDemoProjectResponse.project;
                    this.active_Models[i].project_name =
                      specificProject.project_name;
                  }
                },
              });
            }
          }
          this.show3dDemoProject = true;
          // projects end //
          // assigning Usecase name to each 3D Demo object //
          for (let i = 0; i < this.active_Models.length; i++) {
            let usecaseIdsArray = this.active_Models[i].demo_usecases;
            let useCaseNamesArray: any = [];
            if (usecaseIdsArray.length > 0) {
              for (let j = 0; j < usecaseIdsArray.length; j++) {
                let useCaseId = usecaseIdsArray[j];
                this.demoService.getUsecaseById(useCaseId).subscribe({
                  next: (val: any) => {
                    this.specificthreedDemoUsecaseResponse = val;
                    if (this.specificthreedDemoUsecaseResponse.status == 1) {
                      // console.log("Response:",this.specificthreedDemoUsecaseResponse);
                      let useCaseTitle =
                        this.specificthreedDemoUsecaseResponse?.usecase
                          ?.usecase_title;
                      // console.log("usecasename:",useCaseTitle);
                      useCaseNamesArray.push(useCaseTitle);
                      // console.log("usecasenames array:",useCaseNamesArray);
                      this.active_Models[i].usecaseNames = useCaseNamesArray;
                    }
                  },
                });
              }
            }
          }
          this.show3dDemoUsecase = true;
          // usecases end //
        } else {
          console.log("No Active New 3D Demos");
        }
      },
      error: (err: any) => {
        let errData = err;
        // console.log(errData);
      },
    });
  }

  // ==================================== 3d Demos =============================== //

  //Search bar related
  searchByTags(tag: any) {
    this.searchtext = tag;
  }

  searchByTwodCardTag(tag: any) {
    this.searchtext = tag;
  }

  // search By Project
  showParticularCards(Name: any) {
    this.searchtext = Name;
    this.useCasedropDown = false;
    this.projectDropDown = false;
  }

  // Models //
  modeltagsStatus: boolean = true;
  viewMoreTags() {
    this.modeltagsStatus = !this.modeltagsStatus;
    if (!this.modeltagsStatus) {
      let tagsContainer = document.getElementById("tagContainer")!;
      tagsContainer.classList.remove("tagsContainer");
      let buttonCOntainer = document.getElementById("buttonContainer")!;
      buttonCOntainer.classList.add("d-none");
    }
    if (this.modeltagsStatus) {
      let tagsContainer = document.getElementById("tagContainer")!;
      tagsContainer.classList.add("tagsContainer");
      let buttonCOntainer = document.getElementById("buttonContainer")!;
      buttonCOntainer.classList.remove("d-none");
    }
  }

  // 3D demos //
  threedDemotagsStatus: boolean = true;
  viewMoreThreedDemoTags() {
    this.threedDemotagsStatus = !this.threedDemotagsStatus;
    if (!this.threedDemotagsStatus) {
      let tagsContainer = document.getElementById("tagsThreedContainer")!;
      tagsContainer.classList.remove("tagsThreedContainer");
      let buttonCOntainer = document.getElementById("buttonThreedContainer")!;
      buttonCOntainer.classList.add("d-none");
    }
    if (this.threedDemotagsStatus) {
      let tagsContainer = document.getElementById("tagsThreedContainer")!;
      tagsContainer.classList.add("tagsThreedContainer");
      let buttonCOntainer = document.getElementById("buttonThreedContainer")!;
      buttonCOntainer.classList.remove("d-none");
    }
  }

  // 2D demos //
  demotagsStatus: boolean = true;
  viewMoreTwodDTags() {
    this.demotagsStatus = !this.demotagsStatus;
    if (!this.demotagsStatus) {
      let tagsContainer = document.getElementById("tagsTwodContainer")!;
      tagsContainer.classList.remove("tagsTwodContainer");
      let buttonCOntainer = document.getElementById("buttonTwodContainer")!;
      buttonCOntainer.classList.add("d-none");
    }
    if (this.demotagsStatus) {
      let tagsContainer = document.getElementById("tagsTwodContainer")!;
      tagsContainer.classList.add("tagsTwodContainer");
      let buttonCOntainer = document.getElementById("buttonTwodContainer")!;
      buttonCOntainer.classList.remove("d-none");
    }
  }

  scroll(el: any) {
    document.getElementById(el)?.scrollIntoView({ behavior: "smooth" });
  }

  useCasedropDown: boolean = false;
  onUsecaseClick() {
    this.useCasedropDown = !this.useCasedropDown;
    this.projectDropDown = false; //closing usecase dropdown
    this.usecaseSearchStatus = true; // showing second list
  }

  projectDropDown: boolean = false;
  onProjectClick() {
    this.projectDropDown = !this.projectDropDown;
    this.useCasedropDown = false; //closing project dropdown
    this.usecaseSearchStatus = false; // hiding second list
  }

  // get Projects list
  projectsResponse: any;
  projectsList: any = [];
  getAllProjects() {
    this.demoService.getAllProjects().subscribe({
      next: (res: any) => {
        this.projectsResponse = res;
        // console.log("this.projectsResponse", this.projectsResponse);
        if (this.projectsResponse.status == 1) {
          this.projectsList = this.projectsResponse.projects;
        }
      },
      error: (err: any) => {
        let errData = err;
        // console.log(errData.error.message);
      },
      complete: () => { },
    });
  }

  // get usescases list
  usecases_list: any = [];
  usecases_data: any;
  getallUsecases() {
    this.demoService.GetAllUsecases().subscribe({
      next: (val: any) => {
        this.usecases_data = val;
        // console.log("usecases_data", this.usecases_data);
      },
      error: (err: any) => {
        let errData = err;
        console.log(errData.error.message);
      },
      complete: () => {
        if (this.usecases_data.status == 1) {
          this.usecases_list = this.usecases_data.usecases;
        } else {
          // console.log(this.usecases_data.message);
        }
      },
    });
  }

  usecaseSearchStatus: boolean = false;
  onSearchChange(event: any) {
    if (event == "") {
      this.usecaseSearchStatus = false;
    }
  }
}
