import { Component, OnInit } from "@angular/core";
import { environment } from "../../environments/environment";
import { S3ResourceService } from "../shared/services/s3-resource.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.css"],
})
export class HeaderComponent implements OnInit {
  //condition: boolean = true;
  showDropList: boolean = false;
  value: Number = 0;
  adminSiteUrl: any;
  ardemoSiteUrl: any;
  siteType: any;

  company_logo: string = "";
  ourMission_Png: string = "";

  constructor(
    public s3serviceurl: S3ResourceService,
    private router: Router,
  ) {
    this.adminSiteUrl = environment.admin_url;
    this.ardemoSiteUrl = environment.ardemos_host_url;
    this.siteType = environment.production;
  }

  ngOnInit(): void {
    this.company_logo = this.s3serviceurl.company_logo;
    this.ourMission_Png = this.s3serviceurl.our_mission;
  }

  currentUrl: any;

  //toggle drop menu list in mobile view
  openDropList() {
    this.showDropList = !this.showDropList;
  }
  // open drop menu
  openDropMenu() {
    this.value = 1;
  }
  // close drop menu
  closeDropMenu() {
    this.value = 0;
  }


  // Home page section navigation //
  toHomeSection(sectionId: string) {
    this.router.navigate(['home', sectionId]);
  }

}
