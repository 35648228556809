<app-header></app-header>
<router-outlet></router-outlet>
<div class="fixed-icon">
  <a href="https://wa.me/0919036600966?text=Hello%2C%20I%20am%20interested%20in%20Immersion%20Software%20Labs%20Services.%20Can%20we%20have%20a%20chat%20regarding%20the%20services%20offered."
    target="_blank">
    <img src="assets/images/chat-whatsapp.svg" alt="" />
  </a>
  <!-- <a href="https://wa.me/0919036600966?text=Hello,%20Welcome%20to%20Immersion%20Software%20Labs%20Pvt%20Ltd.%20Click%20the%20chat%20button%20to%20get%20started" target="_blank">
        <img src="assets/images/whatsapp.png" alt="" width="50px"/>
    </a> -->
</div>
<app-footer></app-footer>