import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

let envs3PublicUrl = environment.cloudFrontURL;

@Injectable({
  providedIn: "root",
})
export class S3ResourceService {
  constructor() {}

  mainpath: string = envs3PublicUrl;

  public company_logo: string =
    this.mainpath + "common/assets/images/company_logo.webp";
  public google_play: string =
    this.mainpath + "common/website/staticimages/google-play.webp";
  public our_mission: string =
    this.mainpath + "common/website/staticimages/our-mission.webp";

  public gradient_dark: string =
    this.mainpath + "common/website/staticimages/gradient_dark.jpg";
  public gradient_light: string =
    this.mainpath + "common/website/staticimages/gradient_light.jpg";
  public gradient: string =
    this.mainpath + "common/website/staticimages/gradient.jpg";
  public gradient_creative: string =
    this.mainpath + "common/website/staticimages/gradient_creative.jpg";

  // Technology Section
  public Ar_img: string = this.mainpath + "common/website/staticimages/ar.svg";
  public webxr_img: string =
    this.mainpath + "common/website/staticimages/webxr.svg";
  public mr_img: string = this.mainpath + "common/website/staticimages/mr.svg";

  // // Strategy section
  // public efficient_shopping: string =
  //   this.mainpath + "website/efficient_shopping.png";
  // public product_discovery: string =
  //   this.mainpath + "website/product_discovery%20%281%29.png";
  // public customer_engagement: string =
  //   this.mainpath + "website/customer_engagement.png";
  // public customer_metrics: string =
  //   this.mainpath + "website/customer_metrics.png";
  // public cross_platform: string = this.mainpath + "website/cross_platform.png";
  // public ar_enabled: string = this.mainpath + "website/ar_enabled.png";

  // Products section

  public admin_manage: string =
    this.mainpath + "common/website/staticimages/admin-manage.webp";
  public user_manage: string =
    this.mainpath + "common/website/staticimages/user-manage.webp";

  public video_rendering: string =
    this.mainpath + "common/website/staticimages/video-rendering.webp";
  public image_rendering: string =
    this.mainpath + "common/website/staticimages/image-rendering.webp";
  public css_rendering: string =
    this.mainpath + "common/website/staticimages/css-rendering.webp";
  public threed_rendering: string =
    this.mainpath + "common/website/staticimages/threed-rendering.webp";

  public surface_detect: string =
    this.mainpath + "common/website/staticimages/surface-detect.webp";
  public cross_platform: string =
    this.mainpath + "common/website/staticimages/cross-platform.webp";
  public image_detect: string =
    this.mainpath + "common/website/staticimages/image-detect.webp";
  public live_preview: string =
    this.mainpath + "common/website/staticimages/live-preview.webp";

  public browser_based: string =
    this.mainpath + "common/website/staticimages/browser-based.webp";
  public location_based: string =
    this.mainpath + "common/website/staticimages/location-based.webp";
  public organic_analytics: string =
    this.mainpath + "common/website/staticimages/organic-analytics.webp";

  // Demos List Page
  public thunderImage: string =
    this.mainpath + "common/website/staticimages/Thunder.png";
  public blastImage: string =
    this.mainpath + "common/website/staticimages/Blast.png";
}
