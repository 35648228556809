<div style="margin-top: 10%">
  <h2 class="font-display text-jacarta-500 mb-12 text-center text-2xl dark:text-white">
    OUR MISSION
  </h2>
</div>

<!-- <div style="margin-left: 90%; margin-top: -8%">
  <a routerLink="home" class="font-display dark:text-jacarta-400 mb-12 text-2xl dark:text-white">
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" fill="currentColor" class="bi bi-house"
      viewBox="0 0 16 16">
      <path fill-rule="evenodd"
        d="M2 13.5V7h1v6.5a.5.5 0 0 0 .5.5h9a.5.5 0 0 0 .5-.5V7h1v6.5a1.5 1.5 0 0 1-1.5 1.5h-9A1.5 1.5 0 0 1 2 13.5zm11-11V6l-2-2V2.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5z" />
      <path fill-rule="evenodd"
        d="M7.293 1.5a1 1 0 0 1 1.414 0l6.647 6.646a.5.5 0 0 1-.708.708L8 2.207 1.354 8.854a.5.5 0 1 1-.708-.708L7.293 1.5z" />
    </svg>
  </a>
</div> -->

<div class="vid">
  <iframe class="embed-responsive-item" style="width: 100%" height="500" src="https://www.youtube.com/embed/6mGl5_cL6IE"
    frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    allowfullscreen></iframe>
</div>

<section style="margin-top: 10%">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="content">
          <div class="margin-top:20%">
            <h3 class="font-display text-jacarta-700 text-xl dark:text-white">
              Why choose Immersion Software Labs?
            </h3>
            <p class="dark:text-jacarta-400 mb-6 text-lg leading-normal">
              Immersion Software Labs is you Partner-in-Technology providing
              futuristic User Experience with Best-In-Class technology through
              Web as well Mobile Applications
            </p>
            <h3 class="font-display text-jacarta-700 text-1xl dark:text-white">
              What is our STRATEGY?
            </h3>
            <p class="dark:text-jacarta-400 mb-6 text-lg leading-normal">
              <li>More efficient way to engage users and enable branding</li>
              <li>Dynamic targeted offer solutions</li>
              <li>Spontaneous product discovery</li>
              <li>Enhanced brand & customer engagement</li>
              <li>Better customer insights</li>
              <li>Run across all display platforms</li>
              <li>Tracking Customer data</li>
            </p>
            <h3 class="font-display text-jacarta-700 text-1xl dark:text-white">
              CORE SERVICES
            </h3>
            <p class="dark:text-jacarta-400 mb-6 text-lg leading-normal">
              <li>AUGMENTED REALITY as a Service (ARAS)</li>
              <li>USER ENGANGEMENT SERVICES</li>
              <li>TARGETED MARKETING</li>
              <li>AR based ADVERTISEMENT PRODUCTS</li>
            </p>
            <h3 class="font-display text-jacarta-700 text-1xl dark:text-white">
              OUR CAPABILITIES
            </h3>
            <p class="dark:text-jacarta-400 mb-6 text-lg leading-normal">
              <span>
                Our capabilities rely in the experience we process in the team
                and the strong individual roots
              </span>
              &nbsp;
              <br />
              <li>Software design Life Cycle</li>
              <li>Agile Project Management</li>
              <li>Augment Reality (AR)</li>
              <li>VR and XR Development</li>
              <li>Mobile Application Development</li>
              <li>Hybrid Application Development</li>
              <li>AWS Cloud Infrastructure</li>
              <li>Machine Learning</li>
              <li>Data Analytics</li>
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
&nbsp;
<hr />