import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "searchDemo",
})
export class SearchDemoPipe implements PipeTransform {
  transform(items: any, searchdemotype: string): any[] {
    if (!items) return [];
    if (!searchdemotype) return items;

    return items.filter((item: any) => {
      if (item.demo_tags.includes(searchdemotype)) {
        return item.demo_tags.includes(searchdemotype);
      } else if (item.demo_name.toLowerCase().includes(searchdemotype)) {
        return item.demo_name
          .toLowerCase()
          .includes(searchdemotype.toLowerCase());
      } else if (item.project_name) {
        if (item.project_name) {
          return item.project_name
            .toLowerCase()
            .includes(searchdemotype.toLowerCase());
        }
      }
      if (item.usecaseNames) {
        if (item.usecaseNames.includes(searchdemotype)) {
          return item.usecaseNames.includes(searchdemotype);
        }
      }
    });
  }
}
