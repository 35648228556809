<div class="mt-1 flex space-x-5" [ngClass]="pageClass ? '' : 'justify-center'">
	<div *ngFor="let i of member_social_links">
		<a href="{{ i?.link }}" target="_blank" class="group" *ngIf="i?.name == '0'">
			<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" fill="currentColor" class="bi bi-linkedin"
				viewBox="0 0 16 16" class="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white"
				role="img">
				<path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 
					1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854V1.146zm4.943 12.248V6.169H2.542v7.225h2.401zm-1.2-8.212c.837 
					0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248-.822 0-1.359.54-1.359 1.248 
					0 .694.521 1.248 1.327 1.248h.016zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 
					1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 
					0-1.845.7-2.165 1.193v.025h-.016a5.54 5.54 0 0 1 .016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225h2.4z" />
			</svg>
		</a>
		<a href="{{ i?.link }}" target="_blank" class="group" *ngIf="i?.name == '2'">
			<svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="facebook"
				class="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white" role="img"
				xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
				<path d="M504 256C504 119 393 8 256 8S8 119 8 256c0 123.78 90.69 226.38 209.25 
					245V327.69h-63V256h63v-54.64c0-62.15 37-96.48 93.67-96.48 27.14 0 55.52 4.84 55.52 
					4.84v61h-31.28c-30.8 0-40.41 19.12-40.41 38.73V256h68.78l-11 71.69h-57.78V501C413.31 
					482.38 504 379.78 504 256z"></path>
			</svg>
		</a>
		<a href="{{ i?.link }}" target="_blank" class="group" *ngIf="i?.name == '1'">
			<svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter"
				class="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white" role="img"
				xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
				<path d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 
					298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 
					1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 
					6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 
					7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 
					63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 
					30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 
					21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"></path>
			</svg>
		</a>
		<a href="{{ i?.link }}" target="_blank" class="group" *ngIf="i?.name == '3'">
			<svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram"
				class="group-hover:fill-accent fill-jacarta-300 h-5 w-5 dark:group-hover:fill-white" role="img"
				xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
				<path d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 
					141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 
					74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 
					27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 
					36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 
					2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 
					19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 
					9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 
					11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"></path>
			</svg>
		</a>
	</div>
</div>