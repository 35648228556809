import { Component, OnInit } from "@angular/core";
import { SharingService } from "../shared/services/sharing.service";
import { NavigationEnd, NavigationExtras, Router } from "@angular/router";
import { S3ResourceService } from "../shared/services/s3-resource.service";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-careers",
  templateUrl: "./careers.component.html",
  styleUrls: ["./careers.component.css"],
})
export class CareersComponent implements OnInit {
  Jobs: any = [];
  jobId: any;
  job_id: any;
  result1: any;
  toggleContainer: Boolean = true;

  gradient_light: string = "";
  company_logo: string = "";

  subscriberform!: FormGroup;

  headingTitle: string = "Contact Us for further queries";
  constructor(
    private service: SharingService,
    public s3serviceurl: S3ResourceService,
    private formBuilder: FormBuilder,
    private toaster: ToastrService
  ) {
    this.subscriberform = this.formBuilder.group({
      subscriber_email: ["", [Validators.required, Validators.email]],
    });
  }

  ngOnInit(): void {
    this.service.GetAllJobs().subscribe((res: any) => {
      var result = res["jobs"];
      this.Jobs = result;
      //console.log("jobs", this.Jobs);
      if (this.Jobs.length == 0) {
        this.toggleContainer = false;
      } else {
        this.toggleContainer = true;
      }
      this.result1 = this.Jobs.map(({ job_id }: any) => job_id);
    });

    this.gradient_light = this.s3serviceurl.gradient_light;
    this.company_logo = this.s3serviceurl.company_logo;
  }

  sent(job: any) {
    // console.log(job);
    this.jobId = this.result1.find((el: any) => el == job.job_id);
    const navigationExtras: NavigationExtras = {
      state: {
        jobId: this.jobId,
      },
    };
    var id = this.jobId;
    //this.router.navigate(['career',id], navigationExtras);
  }

  subscribeResponse: any;
  submit(): any {
    // console.log(this.subscriberform.value);
    this.service.putSubscriber(this.subscriberform.value).subscribe({
      next: (data: any) => {
        // console.log(data);
        this.subscribeResponse = data;
        if (this.subscribeResponse.status == 1) {
          this.toaster.success(this.subscribeResponse.message);
        }
        this.subscriberform.reset();
      },
      error: (err: any) => {
        // console.log(err);
        this.toaster.info(err.error.message);
        this.subscriberform.reset();
      },
      complete: () => { },
    });
  }

  get subscriber_email() {
    return this.subscriberform.get("subscriber_email");
  }
}
