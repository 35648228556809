import { Component, OnInit } from "@angular/core";
import { SharingService } from "../shared/services/sharing.service";
import { environment } from "../../environments/environment";

@Component({
  selector: "app-usecases-section",
  templateUrl: "./usecases-section.component.html",
})
export class UsecasesSectionComponent implements OnInit {
  constructor(private service: SharingService) {
    this.arDemoHostURL = environment.ardemos_host_url;
  }

  ngOnInit(): void {
    this.windowResize();
    this.getallUsecases();
  }

  arDemoHostURL: any;

  ToggleAllUsecases: boolean = false;
  usecases_list: any = [];
  usecases_data: any;
  getallUsecases() {
    this.service.GetAllUsecases().subscribe({
      next: (val: any) => {
        this.usecases_data = val;
        // console.log("usecases_data", this.usecases_data);
      },
      error: (err: any) => {
        let errData = err;
        // console.log(errData.error.message);
      },
      complete: () => {
        if (this.usecases_data.status == 1) {
          this.ToggleAllUsecases = true;
          this.usecases_list = this.usecases_data.usecases;
          this.usecases_list.map((item: any) => {
            item.cloudfront_url = environment.cloudFrontURL;
          });
        } else {
          this.ToggleAllUsecases = false;
          // console.log(this.usecases_data.message);
        }
      },
    });
  }

  // =========================== Skeleton Loading Related ============================

  skeletonCount: number = 3;

  windowWidth = window.innerWidth;
  windowHeight = window.innerHeight;

  themeWidth: any = {
    "border-radius": "20px",
    width: "300px",
    height: "200px",
    "margin-right": "30px",
    "background-color": "#131740",
    border: "1px solid #131740",
    "animation-duration": "2s",
  };

  windowResize() {
    if (
      this.windowWidth === 414 ||
      this.windowWidth < 414 ||
      this.windowWidth === 540
    ) {
      this.themeWidth.width = "320px";
      this.skeletonCount = 2;
    } else if (
      this.windowWidth === 820 ||
      this.windowWidth === 768 ||
      this.windowWidth === 912 ||
      this.windowWidth === 1024
    ) {
      this.skeletonCount = 2;
      this.themeWidth.width = "320px";
    }
  }

  // ==============================

  usecaseViewStatus: boolean = true;
  viewMoreUsecases() {
    this.usecaseViewStatus = !this.usecaseViewStatus;
    if (!this.usecaseViewStatus) {
      let usecaseContainer = document.getElementById("usecaseContainer")!;
      usecaseContainer.classList.remove("usecaseContainer");
      let buttonCOntainer = document.getElementById("buttonContainer")!;
      buttonCOntainer.classList.add("d-none");
    }
    if (this.usecaseViewStatus) {
      let usecaseContainer = document.getElementById("usecaseContainer")!;
      usecaseContainer.classList.add("usecaseContainer");
      let buttonCOntainer = document.getElementById("buttonContainer")!;
      buttonCOntainer.classList.remove("d-none");
    }
  }
}
