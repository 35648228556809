import { Component, OnInit } from "@angular/core";
import { environment } from "../../environments/environment";
// import { ThreejsGlbService } from "../shared/threejs-glb.service";
import { S3ResourceService } from "../shared/services/s3-resource.service";

@Component({
  selector: "app-products-section",
  templateUrl: "./products-section.component.html",
  styleUrls: ["./products-section.component.css"],
})
export class ProductsSectionComponent implements OnInit {
  ardemoSiteUrl: any;

  constructor(
    // private threeService: ThreejsGlbService,
    public s3serviceurl: S3ResourceService
  ) {
    this.ardemoSiteUrl = environment.ardemos_host_url;
  }

  admin_manage: any;
  user_manage: any;
  video_rendering: any;
  image_rendering: any;
  css_rendering: any;
  threed_rendering: any;

  surface_detect: any;
  cross_platform: any;
  image_detect: any;
  live_preview: any;

  browser_based: any;
  location_based: any;
  organic_analytics: any;
  glbPath =
    environment.cloudFrontURL +
    "common/assets/glb/white_robot_uncompressed.glb";
  ngOnInit(): void {
    this.admin_manage = this.s3serviceurl.admin_manage;
    this.user_manage = this.s3serviceurl.user_manage;
    this.video_rendering = this.s3serviceurl.video_rendering;
    this.image_rendering = this.s3serviceurl.image_rendering;
    this.threed_rendering = this.s3serviceurl.threed_rendering;
    this.css_rendering = this.s3serviceurl.css_rendering;
    this.surface_detect = this.s3serviceurl.surface_detect;
    this.cross_platform = this.s3serviceurl.cross_platform;
    this.image_detect = this.s3serviceurl.image_detect;
    this.live_preview = this.s3serviceurl.live_preview;
    this.browser_based = this.s3serviceurl.browser_based;
    this.location_based = this.s3serviceurl.location_based;
    this.organic_analytics = this.s3serviceurl.organic_analytics;
  }
  toContact() {
    document.getElementById("contact")?.scrollIntoView({ behavior: "smooth" });
  }
}
