import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "app-social-media-icons",
  templateUrl: "./social-media-icons.component.html",
  styles: [],
})
export class SocialMediaIconsComponent implements OnInit {
  @Input() member_social_links: any;
  @Input() pageClass: any;

  constructor() { }

  ngOnInit(): void { }
}
