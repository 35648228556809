<section class="dark:bg-jacarta-800 relative py-16 md:py-24">
	<div class="container">
		<div class="mx-auto max-w-lg text-center">
			<h1 class="text-jacarta-700 font-display mb-6 text-4xl dark:text-white md:text-6xl">
				500
			</h1>
			<p class="dark:text-jacarta-300 mb-12 text-lg leading-normal">
				Oops! Sorry, unexpected error
			</p>
			<a routerLink="/home"
				class="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
				Navigate back to Home</a>
		</div>
	</div>
</section>