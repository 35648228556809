<section class="relative pt-12 pb-24 lg:py-24">
  <div class="container">
    <!-- Breadcrub -->
    <div>
      <ol class="breadcrumb">
        <li class="breadcrumb-item dark:text-jacarta-300">
          <a class="text-accent" routerLink="/">Home</a>
        </li>
        <li class="breadcrumb-item dark:text-jacarta-300">
          <a class="text-accent" routerLink="/3D">3D Models</a>
        </li>
        <li class="breadcrumb-item dark:text-jacarta-300 active" aria-current="page">
          {{ specific_models_data?.model_name }}
        </li>
      </ol>
    </div>
    <!-- Breadcrub -->

    <!-- Item Details Start -->
    <div class="md:flex md:flex-wrap">
      <!-- Left Panel -->
      <div class="mb-8 md:w-2/5 md:flex-shrink-0 md:flex-grow-0 md:basis-auto lg:w-1/2">
        <figure class="mb-8 md:flex-shrink-0 md:flex-grow-0 md:basis-auto">
          <img [src]="specific_models_data?.model_gif" alt="{{ specific_models_data?.model_name }}"
            class="rounded-[1.25rem]" />
        </figure>
        <!-- Demo Ratings -->
        <div class="mb-3 flex">
          <div class="flex items-center">
            <form [formGroup]="ratingform">
              <p class="dark:text-jacarta-300 text-lg mb-1 cursor-pointer">
                Rate this Demo :
              </p>
              <ngx-star-rating formControlName="rating" [id]="'rating'" (click)="sendStarRating()"></ngx-star-rating>
            </form>
          </div>
        </div>
        <!-- Demo Likes/Dislikes -->
        <div class="mb-3 flex">
          <div class="flex items-center">
            <form [formGroup]="addLikesform">
              <p class="dark:text-jacarta-300 text-lg mb-1 cursor-pointer">
                Do you like this demo: &nbsp;
                <span class="text-lg mr-8">
                  <i class="fa fa-thumbs-up text-red" (click)="likeModel()"></i>
                </span>
                <span class="text-lg mr-1">
                  <i class="fa fa-thumbs-down text-red" (click)="disLikeModel()"></i>
                </span>
              </p>
            </form>
          </div>
        </div>
      </div>
      <!-- Right Panel -->
      <div class="md:w-3/5 md:basis-auto md:pl-8 lg:w-1/2 lg:pl-[3.75rem]">
        <div class="mb-8 flex flex-wrap items-center justify-between">
          <div class="flex flex-wrap items-center">
            <div class="flex items-center mt-3">
              <p class="dark:text-jacarta-300 mb-3 font-bold text-sm" *ngIf="
                  specific_models_data?.rating == 0 ||
                  specific_models_data?.rating == 'NaN'
                ">
                <span class="text-lg">
                  <i class="fa fa-star mr-1"></i>
                  <i class="fa fa-star mr-1"></i>
                  <i class="fa fa-star mr-1"></i>
                  <i class="fa fa-star mr-1"></i>
                  <i class="fa fa-star"></i>
                </span>
                (New)
              </p>
              <p *ngIf="specific_models_data?.rating == 1" class="dark:text-jacarta-300 mb-3 font-bold text-sm">
                <span class="text-lg">
                  <i class="fa fa-star checked-star" aria-hidden="true"></i>
                </span>
              </p>
              <p *ngIf="specific_models_data?.rating == 2" class="dark:text-jacarta-300 mb-3 font-bold text-sm">
                <span class="text-lg">
                  <i class="fa fa-star checked-star" aria-hidden="true"></i>
                  <i class="fa fa-star checked-star" aria-hidden="true"></i>
                </span>
              </p>
              <p *ngIf="specific_models_data?.rating == 3" class="dark:text-jacarta-300 mb-3 font-bold text-sm">
                <span class="text-lg">
                  <i class="fa fa-star checked-star" aria-hidden="true"></i>
                  <i class="fa fa-star checked-star" aria-hidden="true"></i>
                  <i class="fa fa-star checked-star" aria-hidden="true"></i>
                </span>
              </p>
              <p *ngIf="specific_models_data?.rating == 4" class="dark:text-jacarta-300 mb-3 font-bold text-sm">
                <span class="text-lg">
                  <i class="fa fa-star checked-star" aria-hidden="true"></i>
                  <i class="fa fa-star checked-star" aria-hidden="true"></i>
                  <i class="fa fa-star checked-star" aria-hidden="true"></i>
                  <i class="fa fa-star checked-star" aria-hidden="true"></i>
                </span>
              </p>
              <p *ngIf="
                  specific_models_data?.rating == 5 ||
                  specific_models_data?.rating == 4.5
                " class="dark:text-jacarta-300 mb-3 font-bold text-sm">
                <span class="text-lg">
                  <i class="fa fa-star checked-star" aria-hidden="true"></i>
                  <i class="fa fa-star checked-star" aria-hidden="true"></i>
                  <i class="fa fa-star checked-star" aria-hidden="true"></i>
                  <i class="fa fa-star checked-star" aria-hidden="true"></i>
                  <i class="fa fa-star checked-star" aria-hidden="true"></i>
                </span>
              </p>
            </div>
          </div>

          <div class="dropdown relative my-1 cursor-pointer">
            <div class="mx-auto flex space-x-2">
              <div
                class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 flex items-center space-x-1 rounded-xl border bg-white py-2 px-4">
                <span class="text-lg">
                  <i class="fa fa-thumbs-up text-red mr-2"></i>
                </span>
                <span class="dark:text-jacarta-200 text-sm">
                  {{ specific_models_data?.likes }}
                </span>
              </div>
              <div
                class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 flex items-center space-x-1 rounded-xl border bg-white py-2 px-4">
                <span class="text-lg">
                  <i class="fa fa-thumbs-down text-red mr-2"></i>
                </span>
                <span class="dark:text-jacarta-200 text-sm">
                  {{ specific_models_data?.dislikes }}
                </span>
              </div>
            </div>
          </div>

          <div>
            <span class="text-lg">
              <i class="fa fa-eye mr-2 viewClass"></i>
            </span>
            <span class="dark:text-jacarta-200 text-sm mt-2">
              {{ specific_models_data?.model_views }}
            </span>
          </div>
        </div>
        <!-- Model Title -->
        <div class="mb-8 flex flex-wrap items-center justify-between">
          <div class="flex flex-wrap items-center">
            <h1 class="font-display text-jacarta-700 mb-4 text-4xl font-semibold dark:text-white">
              {{ specific_models_data?.model_name }}
            </h1>
          </div>
          <div class="dropdown relative my-1 cursor-pointer">
            <div class="mt-2">
              <a class="hover:bg-accent font-display bg-green mt-5 rounded px-4 py-2 text-md text-white cursor-pointer"
                id="feedbackButton" data-bs-toggle="modal" data-bs-target="#feedbackModal">
                feedback
              </a>
            </div>
          </div>
        </div>
        <!-- Model Descrption -->
        <div class="dark:text-jacarta-300 mb-6" [innerHTML]="specific_models_data?.model_description"></div>
        <!-- Model Tags -->
        <div class="mb-6">
          <div class="mb-16 items-center">
            <div class="flex flex-wrap space-x-2">
              <div *ngFor="let element of specific_models_data?.model_tags" class="chip bg-violetColor text-light">
                <span class="text-jacarta-700 text-light dark:text-white">
                  {{ element }}
                </span>
              </div>
            </div>
          </div>
        </div>

        <!-- AR Reated Info -->
        <!-- Both Image AR & Surface AR -->
        <div *ngIf="specific_models_data?.model_ar == 2" class="mb-4 flex flex-wrap">
          <div class="mr-4 flex flex-col">
            <div
              class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                class="h-6 w-6 fill-white">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
              </svg>
            </div>
          </div>
          <div class="mr-8 mb-4 flex">
            <div class="flex flex-col justify-center">
              <span class="text-jacarta-400 block text-lg dark:text-white">
                Image AR
              </span>
            </div>
          </div>
          <div class="mr-8 mb-4 flex">
            <div class="flex flex-col mr-4">
              <div
                class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                  class="h-6 w-6 fill-white">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                </svg>
              </div>
            </div>
            <div class="flex flex-col justify-center">
              <span class="text-jacarta-400 block text-lg dark:text-white">
                Surface AR
              </span>
            </div>
          </div>
        </div>
        <!-- Detection Reated Info -->
        <!-- Both I & S -->
        <div class="mb-4 flex flex-wrap" *ngIf="specific_models_data?.model_ar == 2">
          <div class="mr-4 flex flex-col">
            <div
              class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                class="h-6 w-6 fill-white">
                <path fill="none" d="M0 0h24v24H0z"></path>
                <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
              </svg>
            </div>
          </div>
          <div class="mr-8 mb-4 flex">
            <div class="flex flex-col justify-center">
              <span class="text-jacarta-400 block text-lg dark:text-white">
                Image Detection
              </span>
              <a class="text-accent block">
                <span class="text-sm font-bold">Scan an Image for AR</span>
              </a>
            </div>
          </div>
          <div class="mr-8 mb-4 flex">
            <div class="flex flex-col mr-4">
              <div
                class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                  class="h-6 w-6 fill-white">
                  <path fill="none" d="M0 0h24v24H0z"></path>
                  <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                </svg>
              </div>
            </div>
            <div class="flex flex-col justify-center">
              <span class="text-jacarta-400 block text-lg dark:text-white">
                Surface Detection
              </span>
              <a class="text-accent block">
                <span class="text-sm font-bold">Scan a Surface for AR</span>
              </a>
            </div>
          </div>
        </div>
        <!-- Only Image AR & Surface AR -->

        <!-- Only Surface AR Start-->
        <div *ngIf="specific_models_data?.model_ar == 1"
          class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-2lg border bg-white p-4">
          <div class="mb-4 sm:flex sm:flex-wrap">
            <div class="sm:w-1/2">
              <div class="mt-4 flex flex-wrap qrCardSectionRight">
                <div class="flex flex-col mr-4">
                  <div
                    class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                      class="h-6 w-6 fill-white">
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                    </svg>
                  </div>
                </div>
                <div class="flex flex-col justify-center">
                  <span class="text-jacarta-400 block text-lg dark:text-white">Surface AR</span>
                </div>
              </div>
              <div class="mt-5 flex flex-wrap qrCardSectionRight">
                <div class="flex flex-col mr-4">
                  <div
                    class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                      class="h-6 w-6 fill-white">
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                    </svg>
                  </div>
                </div>
                <div class="flex flex-col justify-center">
                  <span class="text-jacarta-400 block text-lg dark:text-white">
                    Surface Detection
                  </span>
                  <a class="text-accent block">
                    <span class="text-sm font-bold">Scan a Surface for AR</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="dark:border-jacarta-600 sm:border-jacarta-100 mt-4 sm:mt-0 sm:w-1/2">
              <div *ngIf="specific_models_data?.model_ar == 1" class="p-2 text-center">
                <p class="dark:text-jacarta-300 text-lg mb-2">Surface QR</p>
                <img [src]="specific_models_data?.modelSurfaceQRImage" alt="" class="w-full rounded-2lg mb-8" />
                <a class="hover:bg-accent-dark font-display bg-accent mt-4 rounded-full px-6 py-2 text-sm text-white"
                  (click)="addSurfaceARClick()" target="_blank"
                  href="{{ specific_models_data?.modelSurfaceQRURL }}">View in AR</a>
              </div>
            </div>
          </div>
        </div>
        <!-- Only Surface AR End-->
        <!-- Only Image AR Start-->
        <div *ngIf="specific_models_data?.model_ar == 3"
          class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-2lg border bg-white p-4">
          <div class="mb-4 sm:flex sm:flex-wrap">
            <div class="sm:w-1/2">
              <div class="mt-4 flex flex-wrap qrCardSectionRight">
                <div class="flex flex-col mr-4">
                  <div
                    class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                      class="h-6 w-6 fill-white">
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                    </svg>
                  </div>
                </div>
                <div class="flex flex-col justify-center">
                  <span class="text-jacarta-400 block text-lg dark:text-white">
                    Image AR
                  </span>
                </div>
              </div>
              <div class="mt-5 flex flex-wrap qrCardSectionRight">
                <div class="flex flex-col mr-4">
                  <div
                    class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                      class="h-6 w-6 fill-white">
                      <path fill="none" d="M0 0h24v24H0z"></path>
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                    </svg>
                  </div>
                </div>
                <div class="flex flex-col justify-center">
                  <span class="text-jacarta-400 block text-lg dark:text-white">
                    Image Detection
                  </span>
                  <a class="text-accent block">
                    <span class="text-sm font-bold">Scan a Image for AR</span>
                  </a>
                </div>
              </div>
            </div>
            <div class="dark:border-jacarta-600 sm:border-jacarta-100 mt-4 sm:mt-0 sm:w-1/2">
              <div *ngIf="specific_models_data?.model_ar == 3" class="p-4 text-center">
                <p class="dark:text-jacarta-300 text-lg mb-2">Image QR</p>
                <img [src]="specific_models_data?.modelImageQRImage" alt="" class="w-full rounded-2lg mb-8" />
                <a class="hover:bg-green font-display bg-green mt-4 rounded-full px-6 py-2 text-sm text-white"
                  (click)="addImageARClick()" target="_blank" href="{{ specific_models_data?.modelImageQRURL }}">
                  View in AR
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- Only Image AR end-->
        <!-- Both I & S -->
        <div *ngIf="specific_models_data?.model_ar == 2"
          class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-2lg border bg-white p-4">
          <div class="mb-4 sm:flex sm:flex-wrap">
            <div class="sm:w-1/2">
              <div class="p-4 text-center">
                <p class="dark:text-jacarta-300 text-lg mb-2">Image QR</p>
                <img [src]="specific_models_data?.modelImageQRImage" alt="" class="w-full rounded-2lg mb-8" />
                <a class="hover:bg-green font-display bg-green mt-4 rounded-full px-6 py-2 text-sm text-white"
                  (click)="addImageARClick()" target="_blank" href="{{ specific_models_data?.modelImageQRURL }}">
                  View in AR
                </a>
              </div>
            </div>

            <div class="dark:border-jacarta-600 sm:border-jacarta-100 mt-4 sm:mt-0 sm:w-1/2">
              <div class="p-4 text-center">
                <p class="dark:text-jacarta-300 text-lg mb-2">Surface QR</p>
                <img [src]="specific_models_data?.modelSurfaceQRImage" alt="" class="w-full rounded-2lg mb-8" />
                <a class="hover:bg-accent-dark font-display bg-accent mt-4 rounded-full px-6 py-2 text-sm text-white"
                  (click)="addSurfaceARClick()" target="_blank" href="{{ specific_models_data?.modelSurfaceQRURL }}">
                  View in AR
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Item Details End -->

    <!-- Tabs Start -->
    <div class="rounded-lg">
      <!-- Tabs Nav -->
      <ul class="scrollbar-custom mt-14 overflow-x-auto nav nav-tabs flex items-center bulletsNone" role="tablist">
        <!-- Offers -->
        <li class="nav-item" role="presentation">
          <button
            class="nav-link active hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
            id="properties-tab" data-bs-toggle="tab" data-bs-target="#properties" type="button" role="tab"
            aria-controls="properties" aria-selected="true">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
              class="mr-1 h-5 w-5 fill-current">
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M8 4h13v2H8V4zm-5-.5h3v3H3v-3zm0 7h3v3H3v-3zm0 7h3v3H3v-3zM8 11h13v2H8v-2zm0 7h13v2H8v-2z" />
            </svg>
            <span class="font-display text-base font-medium">Properties</span>
          </button>
        </li>

        <!-- Image Detection -->
        <li class="nav-item" role="presentation" *ngIf="specific_models_data?.model_ar == 3">
          <button
            class="nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
            id="imageDetection-tab" data-bs-toggle="tab" data-bs-target="#imageDetection" type="button" role="tab"
            aria-controls="imageDetection" aria-selected="false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
              class="mr-1 h-5 w-5 fill-current">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M20 22H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1zm-1-2V4H5v16h14zM7 6h4v4H7V6zm0 6h10v2H7v-2zm0 4h10v2H7v-2zm6-9h4v2h-4V7z" />
            </svg>
            <span class="font-display text-base font-medium">
              Image Detection
            </span>
          </button>
        </li>

        <!-- Surface Detection -->
        <li class="nav-item" role="presentation" *ngIf="specific_models_data?.model_ar == 1">
          <button
            class="nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
            id="surfaceDetection-tab" data-bs-toggle="tab" data-bs-target="#surfaceDetection" type="button" role="tab"
            aria-controls="surfaceDetection" aria-selected="false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
              class="mr-1 h-5 w-5 fill-current">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M20 22H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1zm-1-2V4H5v16h14zM7 6h4v4H7V6zm0 6h10v2H7v-2zm0 4h10v2H7v-2zm6-9h4v2h-4V7z" />
            </svg>
            <span class="font-display text-base font-medium">
              Surface Detection
            </span>
          </button>
        </li>

        <!-- Both IMage and Surface Detection Start-->
        <li class="nav-item" role="presentation" *ngIf="specific_models_data?.model_ar == 2">
          <button
            class="nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
            id="imageDetection-tab" data-bs-toggle="tab" data-bs-target="#imageDetection" type="button" role="tab"
            aria-controls="imageDetection" aria-selected="false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
              class="mr-1 h-5 w-5 fill-current">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M20 22H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1zm-1-2V4H5v16h14zM7 6h4v4H7V6zm0 6h10v2H7v-2zm0 4h10v2H7v-2zm6-9h4v2h-4V7z" />
            </svg>
            <span class="font-display text-base font-medium">
              Image Detection
            </span>
          </button>
        </li>
        <li class="nav-item" role="presentation" *ngIf="specific_models_data?.model_ar == 2">
          <button
            class="nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
            id="surfaceDetection-tab" data-bs-toggle="tab" data-bs-target="#surfaceDetection" type="button" role="tab"
            aria-controls="surfaceDetection" aria-selected="false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
              class="mr-1 h-5 w-5 fill-current">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M20 22H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1zm-1-2V4H5v16h14zM7 6h4v4H7V6zm0 6h10v2H7v-2zm0 4h10v2H7v-2zm6-9h4v2h-4V7z" />
            </svg>
            <span class="font-display text-base font-medium">
              Surface Detection
            </span>
          </button>
        </li>
        <!-- Both IMage and Surface Detection end-->
        <li class="nav-item" role="presentation">
          <button
            class="nav-link hover:text-jacarta-700 text-jacarta-400 relative flex items-center whitespace-nowrap py-3 px-6 dark:hover:text-white"
            id="deviceDetails-tab" data-bs-toggle="tab" data-bs-target="#deviceDetails" type="button" role="tab"
            aria-controls="deviceDetails" aria-selected="false">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
              class="mr-1 h-5 w-5 fill-current">
              <path fill="none" d="M0 0h24v24H0z" />
              <path
                d="M20 22H4a1 1 0 0 1-1-1V3a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1zm-1-2V4H5v16h14zM7 6h4v4H7V6zm0 6h10v2H7v-2zm0 4h10v2H7v-2zm6-9h4v2h-4V7z" />
            </svg>
            <span class="font-display text-base font-medium">
              Device Details
            </span>
          </button>
        </li>
      </ul>

      <!-- Tab Content Start-->
      <div class="tab-content">
        <!-- Properties Tab Start-->
        <div class="tab-pane fade show active" id="properties" role="tabpanel" aria-labelledby="properties-tab">
          <div
            class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-white p-6 md:p-10">
            <div class="mb-2 flex items-center">
              <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                Is the Model Animated?:
              </span>
              <a *ngIf="specific_models_data?.model_type == 1" class="text-accent">
                Yes
              </a>
              <a *ngIf="specific_models_data?.model_type == 0" class="text-accent">
                No
              </a>
            </div>
            <div *ngIf="specific_models_data?.model_type == 1" class="mb-8 flex items-center">
              <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                Does the model have multiple Animations?:
              </span>
              <a *ngIf="specific_models_data?.model_animation == 0" class="text-accent">
                No
              </a>
              <a *ngIf="specific_models_data?.model_animation == 1" class="text-accent">
                No
              </a>
              <a *ngIf="specific_models_data?.model_animation == 2" class="text-accent">
                Yes
              </a>
            </div>
            <div *ngIf="
                specific_models_data?.model_animation == 2 &&
                specific_models_data?.model_animation_names.length > 0
              " class="mb-8 flex flex-wrap">
              <span *ngFor="let names of modelAnimationNames"
                class="dark:bg-jacarta-700 dark:border-white-300 border-jacarta-100 rounded-xl border bg-white py-2 px-2 mr-2 mb-4">
                <a class="text-jacarta-700 dark:text-white">
                  {{ names.animation_keyword }}
                </a>
              </span>
            </div>

            <div *ngIf="specific_models_data?.model_blender" class="mr-8 mb-4 flex">
              <div class="flex flex-col mr-4">
                <div
                  class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                    class="h-6 w-6 fill-white">
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                  </svg>
                </div>
              </div>
              <div class="flex flex-col justify-center">
                <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                  Model Blender
                </span>
              </div>
            </div>
            <div *ngIf="specific_models_data?.model_blender == ''" class="mr-8 mb-4 flex">
              <div class="flex flex-col mr-4">
                <div class="dark:border-jacarta-600 flex items-center justify-center rounded-full">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
                    <path fill="#f44336"
                      d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
                    <path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"></path>
                    <path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"></path>
                  </svg>
                </div>
              </div>
              <div class="flex flex-col justify-center">
                <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                  Model Blender
                </span>
              </div>
            </div>

            <div *ngIf="specific_models_data?.model_glb" class="mr-8 mb-4 flex">
              <div class="flex flex-col mr-4">
                <div
                  class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                    class="h-6 w-6 fill-white">
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                  </svg>
                </div>
              </div>
              <div class="flex flex-col justify-center">
                <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">Model GLB
                </span>
                <a class="text-accent block">
                  <span class="text-sm font-bold">Android Compatable</span>
                </a>
              </div>
            </div>
            <div *ngIf="specific_models_data?.model_glb == ''" class="mr-8 mb-4 flex">
              <div class="flex flex-col mr-4">
                <div class="dark:border-jacarta-600 flex items-center justify-center rounded-full">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
                    <path fill="#f44336"
                      d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
                    <path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"></path>
                    <path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"></path>
                  </svg>
                </div>
              </div>
              <div class="flex flex-col justify-center">
                <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                  Model GLB
                </span>
                <a class="text-accent block">
                  <span class="text-sm font-bold">Android Compatable</span>
                </a>
              </div>
            </div>

            <div *ngIf="specific_models_data?.model_usdz" class="mr-8 mb-4 flex">
              <div class="flex flex-col mr-4">
                <div
                  class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                    class="h-6 w-6 fill-white">
                    <path fill="none" d="M0 0h24v24H0z"></path>
                    <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                  </svg>
                </div>
              </div>
              <div class="flex flex-col justify-center">
                <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                  Model USDZ
                </span>
                <a class="text-accent block">
                  <span class="text-sm font-bold">Apple Compatable</span>
                </a>
              </div>
            </div>
            <div *ngIf="specific_models_data?.model_usdz == ''" class="mr-8 mb-4 flex">
              <div class="flex flex-col mr-4">
                <div class="dark:border-jacarta-600 flex items-center justify-center rounded-full">
                  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30" viewBox="0 0 48 48">
                    <path fill="#f44336"
                      d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
                    <path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z"></path>
                    <path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z"></path>
                  </svg>
                </div>
              </div>
              <div class="flex flex-col justify-center">
                <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                  Model USDZ
                </span>
                <a class="text-accent block">
                  <span class="text-sm font-bold">Apple Compatable</span>
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- properties Tab End-->

        <!-- Image Detection Tab Start-->
        <div class="tab-pane fade" id="imageDetection" role="tabpanel" aria-labelledby="imageDetection-tab">
          <div
            class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-white p-6 md:p-10">
            <div class="sm:flex sm:flex-wrap">
              <div class="mb-4 flex items-center sm:w-1/2">
                <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                  Is Object Animated?:
                </span>
                <a *ngIf="specific_models_data?.model_type == 1" class="text-accent">
                  Yes
                </a>
                <a *ngIf="specific_models_data?.model_type == 0" class="text-accent">
                  No
                </a>
              </div>
              <div class="mb-2 flex sm:w-1/2 items-center">
                <div class="sm:flex justify-center sm:flex-wrap">
                  <div class="mb-2 sm:w-1/2 p-2 text-center">
                    <span class="dark:text-jacarta-300 mb-6 d-block">
                      Marker
                    </span>
                    <img [src]="specific_models_data?.model_marker" alt="" class="w-full rounded-2lg mb-8" />
                    <a [href]="specific_models_data?.model_marker" [download]="specific_models_data?.model_name"
                      target="_blank"
                      class="hover:bg-green font-display bg-green mt-4 rounded-full px-6 py-2 text-sm text-white cursor-pointer">
                      Download Marker
                    </a>
                  </div>
                  <div class="mb-2 sm:w-1/2 items-center p-2 text-center">
                    <span class="dark:text-jacarta-300 mb-6 d-block">
                      IMAGE QR
                    </span>
                    <img [src]="specific_models_data?.modelImageQRImage" alt="" class="w-full rounded-2lg mb-8" />
                    <a class="hover:bg-accent-dark font-display bg-accent mt-4 rounded-full px-6 py-2 text-sm text-white"
                      target="_blank" href="{{ specific_models_data?.modelImageQRURL }}">
                      View in Image AR
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Image Detection Tab End-->

        <!-- Surface Detection Tab Start-->
        <div class="tab-pane fade" id="surfaceDetection" role="tabpanel" aria-labelledby="surfaceDetection-tab">
          <div
            class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-white p-6 md:p-10 sm:flex sm:flex-wrap">
            <div class="sm:w-1/2">
              <div class="">
                <div class="mb-4 flex items-center">
                  <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                    Is Object Animated?:
                  </span>
                  <a *ngIf="specific_models_data?.model_type == 1" class="text-accent">
                    Yes
                  </a>
                  <a *ngIf="specific_models_data?.model_type == 0" class="text-accent">
                    No
                  </a>
                </div>
                <div *ngIf="specific_models_data?.model_type == 1" class="mb-4 flex items-center">
                  <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                    Does Object have multiple Animationin it?:
                  </span>
                  <a *ngIf="specific_models_data?.model_animation == 0" class="text-accent">
                    No
                  </a>
                  <a *ngIf="specific_models_data?.model_animation == 1" class="text-accent">
                    No
                  </a>
                  <a *ngIf="specific_models_data?.model_animation == 2" class="text-accent">
                    Yes
                  </a>
                </div>
                <div *ngIf="
                    specific_models_data?.model_animation == 2 &&
                    specific_models_data?.model_animation_names.length > 0
                  " class="mb-4">
                  <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                    How many Animations does the model have and what are they:
                  </span>
                  <span class="js-copy-clipboard text-jacarta-700 cursor-pointer select-none text-accent"
                    data-tippy-content="Copy">
                    {{ specific_models_data?.model_animation_names.length }}
                  </span>
                </div>
                <div *ngIf="
                    specific_models_data?.model_animation == 2 &&
                    specific_models_data?.model_animation_names.length > 0
                  " class="mb-8 flex flex-wrap items-center">
                  <span *ngFor="let names of modelAnimationNames"
                    class="dark:bg-jacarta-700 dark:border-white-300 border-jacarta-100 rounded-xl border bg-white py-2 px-2 mr-2 mb-4">
                    <a class="text-jacarta-700 dark:text-white">
                      {{ names.animation_keyword }}
                    </a>
                  </span>
                </div>
                <div *ngIf="specific_models_data?.model_blender" class="mr-8 mb-4 flex">
                  <div class="flex flex-col mr-4">
                    <div
                      class="dark:border-jacarta-600 bg-green flex items-center justify-center rounded-full border-2 border-white">
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="20" height="20"
                        class="h-6 w-6 fill-white">
                        <path fill="none" d="M0 0h24v24H0z"></path>
                        <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z"></path>
                      </svg>
                    </div>
                  </div>
                  <div class="flex flex-col justify-center">
                    <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                      Model Blender
                    </span>
                  </div>
                </div>
                <div *ngIf="specific_models_data?.model_blender == ''" class="mr-8 mb-4 flex">
                  <div class="flex flex-col mr-4">
                    <div class="dark:border-jacarta-600 flex items-center justify-center rounded-full">
                      <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="30" height="30"
                        viewBox="0 0 48 48">
                        <path fill="#f44336"
                          d="M44,24c0,11.045-8.955,20-20,20S4,35.045,4,24S12.955,4,24,4S44,12.955,44,24z"></path>
                        <path fill="#fff" d="M29.656,15.516l2.828,2.828l-14.14,14.14l-2.828-2.828L29.656,15.516z">
                        </path>
                        <path fill="#fff" d="M32.484,29.656l-2.828,2.828l-14.14-14.14l2.828-2.828L32.484,29.656z">
                        </path>
                      </svg>
                    </div>
                  </div>
                  <div class="flex flex-col justify-center">
                    <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                      Model Blender
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="sm:w-1/2 sm:flex sm:flex-wrap">
              <div class="sm:w-1/2"></div>
              <div class="mb-2 sm:w-1/2 items-center p-2 text-center">
                <span class="dark:text-jacarta-300 mb-6 d-block">
                  Surface QR
                </span>
                <img [src]="specific_models_data?.modelSurfaceQRImage" alt="" class="w-full rounded-2lg mb-8" />
                <a class="hover:bg-accent-dark font-display bg-accent mt-4 rounded-full px-6 py-2 text-sm text-white"
                  target="_blank" href="{{ specific_models_data?.modelSurfaceQRURL }}">
                  View in Surface AR
                </a>
              </div>
            </div>
          </div>
        </div>
        <!-- Surface Detection Tab End-->

        <!-- Surface Detection Tab Start-->
        <div class="tab-pane fade" id="deviceDetails" role="tabpanel" aria-labelledby="deviceDetails-tab">
          <div
            class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-t-2lg rounded-b-2lg rounded-tl-none border bg-white p-6 md:p-10 sm:flex sm:flex-wrap">
            <div class="sm:w-1/2">
              <div class="mb-4">
                <h4 class="dark:text-jacarta-300 mb-4">
                  Your Device OS Details
                </h4>
                <div class="mb-4 flex items-center">
                  <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                    OS :
                  </span>
                  <a class="text-accent">{{ deviceInfo.os }}</a>
                </div>
                <div class="mb-4 flex items-center">
                  <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                    OS Version :
                  </span>
                  <a class="text-accent">{{ deviceInfo.os_version }}</a>
                </div>
              </div>
              <!-- OS END -->
              <!-- Browser start -->
              <div class="mb-4">
                <h4 class="dark:text-jacarta-300 mb-4">Your Device Browser</h4>
                <div class="mb-4 flex items-center">
                  <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                    Browser :
                  </span>
                  <a class="text-accent">{{ deviceInfo.browser }}</a>
                </div>
                <div class="mb-4 flex items-center">
                  <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                    Browser Version :
                  </span>
                  <a class="text-accent">{{ deviceInfo.browser_version }}</a>
                </div>
              </div>
              <!-- Browser end -->
              <!-- Browser start -->
              <div class="mb-4">
                <h4 class="dark:text-jacarta-300 mb-4">Device Details</h4>
                <div class="mb-4 flex items-center">
                  <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                    Device Type :
                  </span>
                  <a class="text-accent">{{ deviceInfo.deviceType }}</a>
                </div>
                <div class="mb-4 flex items-center">
                  <span class="dark:text-jacarta-300 mr-2 min-w-[9rem]">
                    WebXR Status :
                  </span>
                  <a class="text-accent">{{ deviceInfo.deviceType }}</a>
                </div>
              </div>
              <!-- Browser end -->
            </div>
            <div class="sm:w-1/2 sm:flex sm:flex-wrap"></div>
          </div>
        </div>
        <!-- Surface Detection Tab End-->
      </div>
      <!-- Tab content end-->
    </div>
    <!-- Tabs End -->
  </div>
</section>

<div class="modal fade" id="feedbackModal" tabindex="-1" aria-labelledby="feedbackModalLabel" aria-hidden="true">
  <div class="modal-dialog max-w-2xl">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="feedbackModalLabel">Feedback</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24"
            class="fill-jacarta-700 h-6 w-6 dark:fill-white">
            <path fill="none" d="M0 0h24v24H0z" />
            <path
              d="M12 10.586l4.95-4.95 1.414 1.414-4.95 4.95 4.95 4.95-1.414 1.414-4.95-4.95-4.95 4.95-1.414-1.414 4.95-4.95-4.95-4.95L7.05 5.636z" />
          </svg>
        </button>
      </div>
      <!-- Body -->
      <div class="modal-body">
        <div class="container" style="margin-top: 2rem">
          <app-feedback [uniqueId]="id"></app-feedback>
        </div>
      </div>
    </div>
  </div>
</div>