import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";

@Injectable({
  providedIn: "root",
})
export class AnalyticsApiService {
  demographics_api_key: any;
  ardemo_api: any;
  constructor(private http: HttpClient) {
    this.demographics_api_key = environment.analytics_api_key;
    this.ardemo_api = environment.ardemos_api_key;
  }

  //AddIP
  webSiteVisitors(visitorSite: any) {
    const body = {
      visitor_site: visitorSite,
    };
    return this.http.put(this.demographics_api_key + "addVisitor", body);
  }

  visitorDeviceDetails(visitorOsType: any, visitorBrowserType: any) {
    const body = {
      device_type: visitorOsType,
      device_browser_type: visitorBrowserType,
    };
    return this.http.put(this.demographics_api_key + "addnewDevices", body);
  }

  getIp() {
    return this.http.get(this.ardemo_api + "ip");
  }
}
