import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { SharingService } from "../shared/services/sharing.service";
import { HttpClient } from "@angular/common/http";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { CookieService } from "ngx-cookie-service";
import { S3ResourceService } from "../shared/services/s3-resource.service";
import { TwodDemosService } from "../shared/services/twod-demos.services";
import { ActivatedRoute, Router } from "@angular/router";
import { environment } from "../../environments/environment";
import { S3FileService } from "../shared/services/s3-file.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent implements OnInit {
  Response: any;
  ResponseStatus: any;
  subscriberform: FormGroup;

  ar_svg: string = "";
  webxr_svg: string = "";
  mr_svg: string = "";

  // efficient_shopping: string = "";
  // product_discovery: string = "";
  // customer_engagement: string = "";
  // customer_metrics: string = "";
  // cross_platform: string = "";
  // ar_enabled: string = "";

  arDemoHostURL: any;
  immersionTwodDemoId: any;
  ardemos_url: any;

  headingTitle: string = "Get In Touch";

  constructor(
    private service: SharingService,
    private http: HttpClient,
    private formBuilder: FormBuilder,
    private cookieService: CookieService,
    public s3serviceurl: S3ResourceService,
    private twodemoService: TwodDemosService,
    private router: Router,
    private route: ActivatedRoute,
    public toastr: ToastrService
  ) {
    this.subscriberform = this.formBuilder.group({
      subscriber_email: ["", [Validators.required, Validators.email]],
    });

    this.arDemoHostURL = environment.ardemos_host_url;
    this.immersionTwodDemoId = environment.immersionTwodModelId;
    this.ardemos_url = environment.ardemos_host_url;

    this.windowResize();
  }

  cookieValue2: any;
  websiteSubscriberStatus: any;
  websitePolicyCondition: boolean = false;

  scrollString: any;
  private routeParamsObservable: any;

  ngOnInit(): void {
    this.getImmersionDemobyId(this.immersionTwodDemoId);
    this.cookieValue2 = this.cookieService.get("websiteSubscriberStatus");
    this.websiteSubscriberStatus = this.cookieService.check(
      "websiteSubscriberStatus"
    );
    setTimeout(() => {
      if (!this.websiteSubscriberStatus) {
        document.getElementById("SubscribeButton")?.click();
      }
    }, 4000);
    setTimeout(() => {
      this.cookieService.set("websiteSubscriberStatus", "Accepted", {
        expires: 30,
      });
    }, 4000);

    this.ar_svg = this.s3serviceurl.Ar_img;
    this.webxr_svg = this.s3serviceurl.webxr_img;
    this.mr_svg = this.s3serviceurl.mr_img;

    this.routeParamsObservable = this.route.params.subscribe((params) => {
      let id = this.route.snapshot.params["id"];
      this.scrollToIdFromUrl(id);
    });
  }

  scrollToIdFromUrl(id: any) {
    setTimeout(() => {
      const el = document.getElementById(id);
      el?.scrollIntoView({ behavior: "smooth" });
    }, 1000);
  }

  submit(): any {
    let subscriber_site = Object.assign(this.subscriberform.value, {
      subscriber_site: Number(0),
    });
    // console.log("this.subscriberform.value",this.subscriberform.value);
    this.service.putSubscriber(this.subscriberform.value).subscribe({
      next: (data: any) => {
        // console.log(data);
        this.Response = data;
        this.ResponseStatus = this.Response.status;
        if (this.Response.message == "Already a subscriber") {
          this.toastr.info(
            "This email address is already subscribed to the newsletters"
          );
        } else {
          this.service.success(
            "Thank you for subscribing to Immersion Software Labs Newsletters."
          );
        }
        this.subscriberform.reset();
      },
      error: (err: any) => {
        // console.log(err);
      },
      complete: () => {
        this.cookieService.set("websiteSubscriberStatus", "Accepted", {
          expires: 30,
        });
      },
    });
  }

  get subscriber_email() {
    return this.subscriberform.get("subscriber_email");
  }

  immersionLabsJsonData: any;
  errimmersionLabsJsonData: any;
  immersionLabsData: any;
  show2DdemoData: boolean = false;
  getImmersionDemobyId(immersionId: any) {
    this.twodemoService.getImmersionTwodDemobyID(immersionId).subscribe({
      next: (val) => {
        // console.log(val);
        this.immersionLabsJsonData = val;
        // console.log("this.immersionLabsJsonData", this.immersionLabsJsonData);
      },
      error: (err: any) => {
        console.log(err);
        this.errimmersionLabsJsonData = err;
      },
      complete: () => {
        if (this.immersionLabsJsonData.status === 1) {
          this.immersionLabsData = this.immersionLabsJsonData.demo;
          this.immersionLabsData.cloudfront_url = environment.cloudFrontURL;
          if (this.immersionLabsJsonData.count != 0) {
            this.show2DdemoData = true;
            this.immersionLabsData.demoQRURL =
              this.ardemos_url + "/" + this.immersionLabsData.demo_qr_url;
            this.immersionLabsData.demoURL =
              this.ardemos_url + "/" + this.immersionLabsData.demo_url;
            if (environment.production == false) {
              this.immersionLabsData.demoQRImage =
                this.immersionLabsData.demo_qr_d;
            } else if (environment.production == true) {
              this.immersionLabsData.demoQRImage =
                this.immersionLabsData.demo_qr_p;
            }
          } else {
            this.show2DdemoData = false;
          }
        }
      },
    });
  }

  naviagateTwodDetail(demoId: any) {
    this.router.navigate([""]);
  }

  //************ skeleton Loading related *******************//

  windowWidth = window.innerWidth;
  windowHeight = window.innerHeight;

  skeletonWidth: any = {
    "border-radius": "20px",
    width: "250px",
    height: "250px",
    "margin-right": "20px",
    "background-color": "#131740",
    border: "1px solid #131740",
    "animation-duration": "2s",
  };

  skeletonWidthDefault: any = {
    "border-radius": "20px",
    width: "330px",
    height: "250px",
    "margin-right": "20px",
    "background-color": "#131740",
    border: "1px solid #131740",
    "animation-duration": "2s",
  };

  themeWidth: any;
  windowResize() {
    if (this.windowWidth === 280) {
      this.themeWidth = this.skeletonWidth;
    } else {
      this.themeWidth = this.skeletonWidthDefault;
    }
  }
  //************ skeleton Loading related *******************//

  // default image for images loading //
  defaultImage = "../../assets/images/loading.gif";

  GotoMembers() {
    let members: string = "members";
    this.router.navigate(["/about-us", members]);
  }

  // ================== Called on ID Change ================== //
  ngOnDestroy() {
    if (this.routeParamsObservable != null) {
      this.routeParamsObservable.unsubscribe();
    }
  }
}
