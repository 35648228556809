<!-- About Us Start -->
<section class="relative py-24">
  <picture class="pointer-events-none absolute inset-0 -z-10 hidden dark:block">
    <img [src]="gradient_dark" alt="gradient" class="h-full w-full" />
  </picture>
  <div class="container">
    <h1
      class="mobile-padding-top-3rem font-display text-jacarta-700 mb-4 mt-3 text-center text-2xl dark:text-white sm:text-5xl">
      {{ aboutUsData?.aboutus_heading1 }}
    </h1>
    <div class="lg:flex lg:justify-between">
      <!-- About Us Section 1 -->
      <div class="py-20 lg:w-[50%] lg:pl-8">
        <h2 class="text-jacarta-700 font-display mb-6 text-2xl dark:text-white"
          [innerHTML]="aboutUsData?.aboutus_desc1"></h2>
        <p class="dark:text-jacarta-300 mb-8 text-lg leading-normal" [innerHTML]="aboutUsData?.aboutus_subdesc1"></p>
      </div>
      <!-- About Us Section 2 -->
      <div class="py-20 lg:w-[50%] lg:pl-16">
        <h2 class="text-jacarta-700 font-display mb-6 text-2xl dark:text-white"
          [innerHTML]="aboutUsData?.aboutus_desc2"></h2>
        <p class="dark:text-jacarta-300 mb-8 text-lg leading-normal" [innerHTML]="aboutUsData?.aboutus_subdesc2"></p>
      </div>
    </div>
    <!-- Featured image -->
    <figure class="mb-16">
      <img [src]="aboutUsData?.cloudfront_url + aboutUsData?.aboutus_image1" alt="post 1"
        class="w-full rounded-[1.25rem]" />
    </figure>
  </div>
</section>
<!-- About Us End -->

<!-- Our Story Start-->
<section class="dark:bg-jacarta-800 bg-light-base relative py-24">
  <div class="container">
    <h1 class="font-display text-jacarta-700 mb-4 mt-3 text-center text-2xl dark:text-white sm:text-5xl">
      {{ aboutUsData?.aboutus_heading2 }}
    </h1>
    <div class="lg:flex lg:justify-between">
      <!-- Image -->
      <div class="lg:w-[55%]">
        <div class="relative">
          <img [src]="aboutUsData?.cloudfront_url + aboutUsData?.aboutus_image2" alt=""
            class="animate-fly absolute top-0 rounded-[1.25rem]" />
        </div>
      </div>
      <!-- Info -->
      <div class="py-10 lg:w-[45%] lg:pl-16">
        <h2 class="text-jacarta-700 font-display mb-6 text-2xl dark:text-white"
          [innerHTML]="aboutUsData?.aboutus_desc3"></h2>
        <p class="dark:text-jacarta-300 mb-8 text-lg leading-normal" [innerHTML]="aboutUsData?.aboutus_subdesc3"></p>
      </div>
    </div>
    <div class="lg:flex lg:justify-between">
      <!-- Info -->
      <div class="py-10 lg:w-[45%]">
        <h2 class="text-jacarta-700 font-display mb-6 text-2xl dark:text-white"
          [innerHTML]="aboutUsData?.aboutus_desc4"></h2>
        <p class="dark:text-jacarta-300 mb-8 text-lg leading-normal" [innerHTML]="aboutUsData?.aboutus_subdesc4"></p>
      </div>
      <!-- Image -->
      <div class="lg:w-[55%] lg:pl-16">
        <div class="relative">
          <img [src]="aboutUsData?.cloudfront_url + aboutUsData?.aboutus_image1" alt=""
            class="animate-fly absolute top-0 rounded-[1.25rem]" />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Our Story End -->

<!-- Team Members Start -->
<section class="relative py-24" id="members">
  <div class="container">
    <h2 class="font-display text-jacarta-700 mb-12 text-center text-3xl dark:text-white">
      Our Founders
    </h2>
    <!-- class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 md:gap-[1.875rem] lg:grid-cols-5" -->
    <div class="grid grid-cols-1 mb-16">
      <div
        class="centerTeamCard dark:bg-jacarta-700 rounded-2lg dark:border-jacarta-600 border-jacarta-100 border bg-white text-center transition-shadow hover:shadow-lg cursor-pointer"
        *ngFor="let pteam of primaryTeamData">
        <div (click)="naviagteToDetail(pteam.member_id, pteam.member_name)" class="p-2 paddingTopBottomThreeRem">
          <img [src]="pteam.cloudfront_url + pteam.member_image"
            class="mx-auto mb-6 h-[8.125rem] w-[8.125rem] rounded-[1.25rem]" alt="team" />
          <h3 class="font-display text-jacarta-700 text-[1.125rem] dark:text-white p-2">
            {{ pteam.member_name }}
          </h3>
          <span class="text-jacarta-400 text-2xs font-medium tracking-tight p-2">{{ pteam.member_designation }}
          </span>
          <div class="mt-3">
            <app-social-media-icons [member_social_links]="pteam?.member_social_links" [pageClass]="false">
            </app-social-media-icons>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Secondary Memebers -->
  <div class="container">
    <h2 class="font-display text-jacarta-700 mb-12 text-center text-3xl dark:text-white mt-2">
      Meet Our Amazing Team
    </h2>
    <div class="grid grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-3 md:gap-[1.875rem] lg:grid-cols-5">
      <div
        class="dark:bg-jacarta-700 rounded-2lg dark:border-jacarta-600 border-jacarta-100 border bg-white text-center transition-shadow hover:shadow-lg cursor-pointer"
        *ngFor="let steam of secondaryTeamData">
        <div (click)="naviagteToDetail(steam.member_id, steam.member_name)" class="p-8">
          <img [src]="steam.cloudfront_url + steam.member_image"
            class="mx-auto mb-6 h-[8.125rem] w-[8.125rem] rounded-[1.25rem]" alt="team" />
          <h3 class="font-display text-jacarta-700 text-[1.125rem] dark:text-white">
            {{ steam.member_name }}
          </h3>
          <span class="text-jacarta-400 text-2xs font-medium tracking-tight">{{
            steam.member_designation
            }}</span>

          <div class="mt-3">
            <app-social-media-icons [member_social_links]="steam?.member_social_links" [pageClass]="false">
            </app-social-media-icons>
          </div>
        </div>
      </div>
      <a routerLink="/career" target="_blank"
        class="dark:bg-jacarta-700 rounded-2lg dark:border-jacarta-600 border-jacarta-100 flex items-center justify-center border bg-white p-8 text-center transition-shadow hover:shadow-lg">
        <span class="font-display text-jacarta-700 text-[1.125rem] dark:text-white">Join us!</span>
      </a>
    </div>
  </div>
</section>
<!-- Team Members End -->

<!-- Our Story Start -->
<section class="dark:bg-jacarta-800 bg-light-base relative py-24">
  <!-- <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden">
    <img src="assets/img/gradient_dark.jpg" alt="gradient" class="h-full w-full" />
  </picture> -->
  <div class="container">
    <h1 class="font-display text-jacarta-700 mb-4 text-2xl dark:text-white sm:text-5xl">
      {{ aboutUsData?.aboutus_heading3 }}
    </h1>
    <div class="lg:flex lg:justify-between">
      <!-- Image -->
      <div class="lg:w-[55%]">
        <div class="relative">
          <img [src]="aboutUsData?.cloudfront_url + aboutUsData?.aboutus_image4" alt=""
            class="animate-fly absolute top-0 rounded-[1.25rem]" />
        </div>
      </div>
      <!-- Info -->
      <div class="py-10 lg:w-[45%] lg:pl-16">
        <h2 class="text-jacarta-700 font-display mb-6 text-2xl dark:text-white"
          [innerHTML]="aboutUsData?.aboutus_desc5"></h2>
        <p class="dark:text-jacarta-300 mb-8 text-lg leading-normal listElements"
          [innerHTML]="aboutUsData?.aboutus_subdesc5"></p>
      </div>
    </div>
    <div class="lg:flex lg:justify-between">
      <!-- Info -->
      <div class="py-10 lg:w-[45%]">
        <h2 class="text-jacarta-700 font-display mb-6 text-2xl dark:text-white"
          [innerHTML]="aboutUsData?.aboutus_desc6"></h2>
        <p class="dark:text-jacarta-300 mb-8 text-lg leading-normal listElements"
          [innerHTML]="aboutUsData?.aboutus_subdesc6"></p>
      </div>
      <!-- Image -->
      <div class="lg:w-[55%] lg:pl-16">
        <div class="relative">
          <img [src]="aboutUsData?.cloudfront_url + aboutUsData?.aboutus_image5" alt=""
            class="animate-fly absolute top-0 rounded-[1.25rem]" />
        </div>
      </div>
    </div>
  </div>
</section>
<!-- Our Story End -->

<!-- Clients section Start -->
<app-clients></app-clients>
<!-- Clients section End -->