import { Component, OnInit } from "@angular/core";

import { SharingService } from "../../shared/services/sharing.service";

@Component({
  selector: "app-all-blogs-list",
  templateUrl: "./all-blogs-list.component.html",
  styles: [],
})
export class AllBlogsListComponent implements OnInit {
  constructor(private sharingService: SharingService) {}

  ngOnInit(): void {
    this.windowResize();
    this.getAllBlogsData();
  }

  isDataFetched: boolean = false;
  isErrorOccured: boolean = false;

  displayBlogs: number = 6;
  displayedBlogsArray: any = [];

  blogsResponse: any;
  blogsData: any = [];
  // ===================  Get All Blogs ==================== //
  getAllBlogsData() {
    this.sharingService.getAllBlogs().subscribe({
      next: (res: any) => {
        this.blogsResponse = res;
        if (this.blogsResponse.status == 1) {
          this.isDataFetched = true;
          this.blogsData = this.blogsResponse.blogs;
          // console.log("blogsData", this.blogsData);
          this.displayedBlogsArray = this.blogsData.slice(0, this.displayBlogs);
        }
      },
      error: (err: any) => {
        this.isErrorOccured = true;
        this.isDataFetched = false;
        console.log("error", err);
      },
    });
  }
  // ===================  Get All Blogs ==================== //

  // ==================  Load More Blogs  ==================== //
  loadMoreBlogs() {
    this.displayBlogs = this.displayBlogs + 6;
    this.displayedBlogsArray = this.blogsData.slice(0, this.displayBlogs);
  }

  // ==================  Skeleton Loading Related   ==================== //
  skeletonCount: number = 3;
  windowWidth = window.innerWidth;
  windowHeight = window.innerHeight;
  themeWidth: any = {
    "border-radius": "20px",
    width: "250px",
    height: "350px",
    "margin-right": "10px",
    "background-color": "#131740",
    border: "1px solid #131740",
    "animation-duration": "2s",
  };
  windowResize() {
    if (this.windowWidth < 361) {
      this.themeWidth.width = "320px";
      this.skeletonCount = 2;
    } else if (this.windowWidth > 361 && this.windowWidth < 413) {
      this.themeWidth.width = "330px";
      this.skeletonCount = 2;
    } else if (this.windowWidth > 413 && this.windowWidth < 541) {
      this.themeWidth.width = "350px";
      this.skeletonCount = 2;
    } else if (this.windowWidth > 767 && this.windowWidth < 855) {
      this.skeletonCount = 2;
      this.themeWidth.width = "220px";
      this.themeWidth.height = "300px";
    } else if (this.windowWidth > 855) {
      this.skeletonCount = 3;
      this.themeWidth.width = "220px";
      this.themeWidth.height = "300px";
    }
  }
  // ==================  Skeleton Loading Related Ends  ==================== //
}
