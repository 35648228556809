import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "searchFilter",
})
export class SearchFilterPipe implements PipeTransform {
  transform(items: any, searchtext: string): any[] {
    if (!items) return [];
    if (!searchtext) return items;

    return items.filter((item: any) => {
      if (item.model_tags.includes(searchtext)) {
        return item.model_tags.includes(searchtext);
      } else if (item.model_name.toLowerCase().includes(searchtext)) {
        return item.model_name.toLowerCase().includes(searchtext.toLowerCase());
      } else if (item.project_name) {
        if (item.project_name) {
          return item.project_name
            .toLowerCase()
            .includes(searchtext.toLowerCase());
        }
      }
      if (item.usecaseNames) {
        if (item.usecaseNames.includes(searchtext)) {
          return item.usecaseNames.includes(searchtext);
        }
      }
    });
  }
}
