<main class="pt-[5.5rem] lg:pt-24">
	<ng-container *ngIf="!isDataFetched && !isErrorOccured; else show_else">
		<section>
			<div class="container">
				<!-- Title -->
				<div class="text-center" style="margin-top: 4rem">
					<ngx-skeleton-loader [theme]="{
              'border-radius': '20px',
              height: '40px',
              width: '200px',
              'margin-right': '18px',
              'background-color': '#131740',
              border: '1px solid #131740',
              'animation-duration': '2s'
            }">
					</ngx-skeleton-loader>
				</div>

				<!-- Author -->
				<div class="">
					<div class="first-section-wrapper">
						<div class="gravatar">
							<ngx-skeleton-loader appearance="circle" [theme]="{
                  width: '80px',
                  height: '80px',
                  'background-color': '#131740'
                }"></ngx-skeleton-loader>
						</div>
						<div class="gravatar-title mt-2">
							<div>
								<ngx-skeleton-loader [theme]="{
                    width: '200px',
                    'border-radius': '0',
                    height: '20px',
                    'margin-bottom': '10px',
                    'background-color': '#131740'
                  }">
								</ngx-skeleton-loader>
							</div>
							<div>
								<ngx-skeleton-loader [theme]="{
                    width: '170px',
                    'border-radius': '0',
                    height: '20px',
                    'background-color': '#131740'
                  }">
								</ngx-skeleton-loader>
							</div>
						</div>
					</div>
				</div>

				<!-- Image -->
				<app-image-loader></app-image-loader>
				<!-- Text -->
				<app-paragraph-loader></app-paragraph-loader>
				<!-- Text -->
				<app-paragraph-loader></app-paragraph-loader>
			</div>
		</section>
	</ng-container>

	<ng-template #show_else>
		<ng-container *ngIf="isErrorOccured; else other_one">
			<app-error500></app-error500>
		</ng-container>
		<ng-template #other_one>
			<div class="py-10 container">
				<div class="backButton">
					<div class="flex space-x-2">
						<a class="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-1 px-3 text-center font-semibold text-white transition-all cursor-pointer"
							(click)="copyClipboard()">
							<i class="fa fa-share-alt"></i> Share
						</a>
						<a class="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-1 px-3 text-center font-semibold text-white transition-all cursor-pointer"
							routerLink="/blogs">
							<i class="fa fa-arrow-left"></i> Back to Blogs List
						</a>
					</div>
				</div>
			</div>
			<section class="relative py-5 md:py-10">
				<div class="container">
					<header class="mx-auto mb-16 max-w-lg text-center">
						<h1 class="font-display text-jacarta-700 mb-4 text-2xl dark:text-white sm:text-5xl">
							{{ blogData?.blog_name }}
						</h1>

						<!-- Author -->
						<div class="inline-flex items-center">
							<img src="{{ cloudFrontUrl + SpecificMemberData?.member_image }}" alt="author"
								class="mr-4 h-10 w-10 shrink-0 rounded-full" />

							<div class="text-left">
								<span class="dark:text-jacarta-200 text-jacarta-700 text-2xs font-medium tracking-tight">
									{{ SpecificMemberData?.member_name }}</span>

								<!-- Date / Time -->
								<div class="text-jacarta-400 flex flex-wrap items-center space-x-2 text-sm">
									<span><time datetime="2022-02-05">Latest</time></span>
									<span>•</span>
									<span>3 min read</span>
								</div>
							</div>
						</div>
					</header>

					<!-- Featured image -->
					<figure class="mb-16">
						<img src="{{ blogData?.blog_image }}" alt="post 1" class="w-full rounded-[1.25rem]" />
					</figure>

					<!-- Article -->
					<article class="mb-12">
						<!-- class="article-content" -->
						<div>
							<!-- <p class="text-jacarta-700 text-[1.125rem] dark:text-white"
                                [innerHTML]="blogData?.blog_content_one">
                            </p> -->
							<p class="text-jacarta-700 text-[1.125rem] dark:text-jacarta-300 text-md mb-5"
								[innerHTML]="blogData?.blog_description"></p>

							<!-- Blog Content two related starts -->
							<div *ngFor="let i of blogData?.blog_content_two; let j = index">
								<ng-container *ngIf="i.order == '2'; else show_text">
									<ng-container *ngIf="
                      blogData?.blog_content_two[j + 1]?.order == '2';
                      else show_image
                    ">
										<div class="article-content-wide my-12 grid grid-cols-1 gap-[1.875rem] sm:grid-cols-2">
											<img src="{{ blogData?.blog_content_two[j]?.data }}" alt="gallery+{{ j }}"
												class="w-full rounded-2lg" />
											<img src="{{ blogData?.blog_content_two[j + 1]?.data }}" alt="gallery+{{ j }}"
												class="w-full rounded-2lg" />
										</div>
									</ng-container>
									<ng-template #show_image>
										<img *ngIf="blogData?.blog_content_two[j - 1]?.order != '2'" src="{{ i?.data }}"
											alt="gallery+{{ j }}" class="w-full rounded-2lg" />
									</ng-template>
								</ng-container>
								<ng-template #show_text>
									<div class="mb-5 mt-5">
										<p class="text-jacarta-700 text-[1.125rem] dark:text-jacarta-300 text-md" [innerHTML]="i.data"></p>
									</div>
								</ng-template>
							</div>
							<!-- Blog Content two related starts -->
						</div>
						<!-- Content -->
					</article>

					<div class="mx-auto max-w-[48.125rem]">
						<!-- Share -->
						<div class="mb-16 flex items-center">
							<div class="flex space-x-2">
								<a class="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-1 px-3 text-center font-semibold text-white transition-all cursor-pointer"
									(click)="copyClipboard()">
									Share <i class="fa fa-share-alt"></i></a>
							</div>
						</div>

						<!-- Author -->
						<div (click)="naviagteToDetail(SpecificMemberData?.member_id, SpecificMemberData?.member_name)"
							class="cursor-pointer dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 mb-16 flex rounded-[1.25rem] border bg-white p-8">
							<img src="{{ cloudFrontUrl + SpecificMemberData?.member_image }}" alt="author"
								class="mr-4 h-16 w-16 shrink-0 self-start rounded-lg md:mr-8 md:h-[9rem] md:w-[9rem]" />
							<div>
								<span class="text-jacarta-700 font-display mb-3 mt-2 block text-base dark:text-white">
									{{ SpecificMemberData?.member_name }}
								</span>
								<p class="dark:text-jacarta-300 mb-4">
									{{ SpecificMemberData?.member_designation }}
								</p>

								<app-social-media-icons [member_social_links]="
                    SpecificMemberData?.member_social_links
                  " [pageClass]="true">
								</app-social-media-icons>
							</div>
						</div>

						<!-- Related -->
						<h2 *ngIf="blogsData?.length > 0" class="font-display text-jacarta-700 mb-8 text-3xl dark:text-white">
							Publisher Posts
						</h2>
						<!-- Posts -->
						<div class="grid grid-cols-1 gap-[1.875rem] md:grid-cols-2 lg:grid-cols-2 sm:grid-cols-2">
							<article *ngFor="let blog of blogsData; let j = index">
								<app-blog-card [blog]="blog"> </app-blog-card>
							</article>
						</div>
					</div>
					<!-- end related -->
				</div>
			</section>
		</ng-template>
	</ng-template>
</main>