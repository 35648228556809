import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable({
  providedIn: "root",
})
export class AboutUsService {
  website_apiKey: any;
  constructor(private http: HttpClient) {
    this.website_apiKey = environment.website_api_key;
  }

  getAboutus() {
    return this.http.get(this.website_apiKey + "/aboutus");
  }

  // Primary Team
  getprimaryTeam() {
    return this.http.get(this.website_apiKey + "/team/primary");
  }

  // Secondary Team
  getsecondaryTeam() {
    return this.http.get(this.website_apiKey + "/team/secondary");
  }
}
