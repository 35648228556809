import { Component } from "@angular/core";
import { AnalyticsApiService } from "./shared/services/analytics-api.service";
import { HttpClient } from "@angular/common/http";
import { ToastrService } from "ngx-toastr";
import { DeviceDetectorService, DeviceInfo } from "ngx-device-detector";
import { SharingService } from "./shared/services/sharing.service";
import { NavigationEnd, Router } from "@angular/router";
import { filter } from "rxjs";

declare var gtag: any;

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "website";
  deviceInfo!: DeviceInfo;

  visitor_Status = sessionStorage.getItem("visitorStatus");

  constructor(
    public visitorService: AnalyticsApiService,
    private httpClient: HttpClient,
    public toastr: ToastrService,
    private deviceService: DeviceDetectorService,
    private sharingService: SharingService,
    private route: Router
  ) {
    this.route.events
      .pipe(filter((event: any) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        gtag("config", "G-HXTNYP04DT", {
          page_path: event.urlAfterRedirects,
        });
      });
  }

  ngOnInit() {
    // this.getCurrentLocation();
    this.deviceInfo = this.deviceService.getDeviceInfo();
    this.sessionActive();
  }

  sessionActive() {
    let sessionStatus = sessionStorage.getItem("sessionStatus");
    if (sessionStatus !== "active") {
      this.webSiteVisitors();
      this.visitorDeviceDetails();
    }
  }

  // Site Visitors
  siteVisitors: any;
  visitorLocation: any = [];
  webSiteVisitors() {
    this.visitorService.webSiteVisitors(0).subscribe({
      next: (val: any) => {
        this.siteVisitors = val;
      },
      error: (err: any) => {
        // console.log("Visitor error", err);
        // this.toastr.error("Visitor error");
      },
      complete: () => {
        if (this.siteVisitors.status === 1) {
          console.log(this.siteVisitors.message);
        }
      },
    });
  }

  //Visitor Device Details
  visitorOsType: any;
  visitorBrowserType: any;
  deviceDetailsData: any;
  visitorDeviceDetails() {
    // OS type
    if (this.deviceInfo.os === "Android") {
      this.visitorOsType = 0;
    } else if (this.deviceInfo.os === "iOS") {
      this.visitorOsType = 1;
    } else {
      this.visitorOsType = 2;
    }

    // Browser Type
    if (this.deviceInfo.browser === "Chrome") {
      this.visitorBrowserType = 0;
    } else if (this.deviceInfo.browser === "Safari") {
      this.visitorBrowserType = 1;
    } else {
      this.visitorBrowserType = 2;
    }
    this.visitorService
      .visitorDeviceDetails(this.visitorOsType, this.visitorBrowserType)
      .subscribe({
        next: (val: any) => {
          this.deviceDetailsData = val;
        },
        error: (err: any) => {
          console.log(err);
          this.toastr.error("Device APi");
        },
        complete: () => {
          if (this.deviceDetailsData.status === 1) {
            console.log(this.deviceDetailsData.message);
            sessionStorage.setItem("sessionStatus", "active");
          }
        },
      });
  }
}
