<section class="relative pt-10 pb-20 md:pt-32">
  <picture class="pointer-events-none absolute inset-x-0 top-0 -z-10 dark:hidden">
    <img [src]="gradient" alt="gradient" />
  </picture>
  <picture class="pointer-events-none absolute inset-x-0 top-0 -z-10 hidden dark:block">
    <img [src]="gradient_dark" alt="gradient dark" />
  </picture>

  <div class="container h-full">
    <div class="grid h-full items-center gap-4 md:grid-cols-12">
      <div
        class="pt-8rem col-span-6 flex h-full flex-col items-center justify-center py-10 md:items-start md:py-20 xl:col-span-4 pt-20">
        <h1
          class="text-jacarta-700 font-display mb-6 text-center text-5xl dark:text-white md:text-left lg:text-6xl xl:text-7xl"
          style="text-shadow: 1px 1px #000000">
          Immersion <br />Software Labs <br />Pvt. Ltd.
        </h1>
        <p class="dark:text-jacarta-300 mb-8 text-lg leading-normal">
          The Next Big Immersive Experience
        </p>
        <div class="">
          <a [href]="ardemoSiteUrl" target="_blank"
            class="bg-accent shadow-accent-volume hover:bg-accent-dark w-36 rounded-full py-3 px-8 text-center font-semibold text-white transition-all">
            Visit our Studio
          </a>
        </div>
      </div>

      <!-- Hero image -->
      <div *ngIf="afterInit" class="col-span-6 xl:col-span-8">
        <div class="relative text-center md:pl-8 md:text-right">
          <model-viewer [src]="glbPath" [ios-src]="" ar-modes="webxr scene-viewer quick-look" camera-controls
            [poster]="" shadow-intensity="1" disable-zoom autoplay animation-name="saber_action"
            min-camera-orbit="auto auto 40m" max-camera-orbit="auto auto 1000m" camera-orbit="0rad 90deg 40m"
            touch-action="pan-y" field-of-view="90deg" min-field-of-view="auto" max-field-of-view="auto" disable-tap>
          </model-viewer>
        </div>
      </div>
    </div>
  </div>
</section>