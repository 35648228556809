import { Component, OnInit } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import {
  FormBuilder,
  FormGroup,
  FormControl,
  Validators,
} from "@angular/forms";
import { SharingService } from "../shared/services/sharing.service";

@Component({
  selector: "app-booking-form",
  templateUrl: "./booking-form.component.html",
  styleUrls: ["./booking-form.component.css"],
})
export class BookingFormComponent implements OnInit {
  isSelected: boolean = false;
  submitted: boolean = false;
  bookingForm!: FormGroup;

  focus1: any;
  focus2: any;
  focus: any;
  name!: string;
  email!: string;
  mobilenumber!: string;
  cname!: string;
  cemail!: string;
  cmobilenumber!: string;
  emailRegex = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$";

  constructor(
    private fb: FormBuilder,
    private http: HttpClient,
    private service: SharingService
  ) {
    this.bookingForm = this.fb.group({
      request_name: ["", [Validators.required]],
      request_email: ["", [Validators.required, Validators.email]],
      request_phone: ["", [Validators.required]],
      contact_name: ["", [Validators.required]],
      contact_email: ["", [Validators.required, Validators.email]],
      contact_phone: ["", [Validators.required]],
      user_type: [""],
      contact_company_name: "",
      request_description: ["", [Validators.required]],
      newSettler: [""],
    });
  }

  onChange(event: any) {
    if (event.target.checked === true) {
      if (this.name != null || this.name != undefined) {
        this.cname = this.name;
      }
      if (this.email != null || this.email != undefined) {
        this.cemail = this.email;
      }
      if (this.mobilenumber != null || this.mobilenumber != undefined) {
        this.cmobilenumber = this.mobilenumber;
      }
    } else {
      this.cname = "";
      this.email = "";
      this.mobilenumber = "";
    }
  }

  enable() {
    this.isSelected = true;
  }

  disable() {
    this.isSelected = false;
  }

  ngOnInit(): void { }

  get request_name() {
    return this.bookingForm.get("request_name");
  }

  get fvalid() {
    return this.bookingForm.controls;
  }

  reponse_msg: any;
  bookingSubmit() {
    let contact_type: number = Object.assign(this.bookingForm.value, {
      contact_type: Number(this.bookingForm.value["user_type"]),
    });
    let request_subscribe: number = Object.assign(this.bookingForm.value, {
      request_subscribe: Number(this.bookingForm.value["newSettler"]),
    });
    let contact_phone: number = Object.assign(this.bookingForm.value, {
      contact_phone: Number(this.bookingForm.value["contact_phone"]),
    });
    let request_phone: number = Object.assign(this.bookingForm.value, {
      request_phone: Number(this.bookingForm.value["request_phone"]),
    });

    //console.log("booking form",this.bookingForm.value);
    this.service.bookDemo(this.bookingForm.value).subscribe({
      next: (res: any) => {
        this.bookingForm.reset();
        this.reponse_msg = res.message;
        this.service.success(
          "Your booking has been registered. Our team will contact us as quickly as possible. We appreciate your patience."
        );
      },
      error: (err: any) => {
        this.service.failure(this.reponse_msg);
        // console.log(err);
      },
      complete: () => { },
    });
  }

  get request_description() {
    return this.bookingForm.get("request_description");
  }
}
