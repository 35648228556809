<div class="container">
  <h2 class="font-display text-jacarta-700 mb-8 text-center text-5xl dark:text-white"
    style="text-shadow: 1px 1px #000000">
    Products & Features
  </h2>
  <div class="relative lg:mt-24 lg:pt-12 lg:pb-24 pt-12 pb-12">
    <!-- <picture class="pointer-events-none absolute inset-0 -z-10 dark:hidden"><img src="/images/gradient_light.jpg"
        alt="gradient" class="h-full" /></picture> -->
    <div class="container">
      <div class="md:flex md:flex-wrap">
        <figure class="mb-8 md:w-2/5 md:flex-shrink-0 md:flex-grow-0 md:basis-auto lg:w-1/2 w-full">
          <model-viewer [src]="glbPath" [ios-src]="" ar-modes="webxr scene-viewer quick-look" disable-tap disable-zoom
            autoplay animation-name="sheild" [poster]="" shadow-intensity="1" min-camera-orbit="auto auto 40m"
            max-camera-orbit="auto auto 1000m" camera-orbit="0rad 90deg 40m" touch-action="pan-y" field-of-view="90deg"
            min-field-of-view="auto" max-field-of-view="auto" class="mobile-d-none">
          </model-viewer>
        </figure>
        <div class="md:w-3/5 md:basis-auto md:pl-8 lg:w-1/2 lg:pl-[3.75rem]">
          <div
            class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-2lg border bg-white p-8 mb-4">
            <div class="text-center">
              <h2 class="text-jacarta-700 font-display mb-8 text-center text-3xl font-semibold dark:text-white">
                What we have!
              </h2>
            </div>
            <div class="mt-3 flex justify-between flex-wrap">
              <figure class="w-25">
                <img [src]="admin_manage" alt="" class="rounded-2lg" loading="lazy" />
              </figure>
              <div class="w-75 p-8px">
                <div class="p-8px">
                  <h3 class="font-display text-jacarta-700 text-lg dark:text-white">
                    A Complete Admin Manageable AR Cards
                  </h3>
                </div>
                <div class="p-8px">
                  <p class="dark:text-jacarta-300 text-sm">
                    Suitable for <b>Business</b> to have numerous AR Cards for
                    their organizations
                  </p>
                </div>
              </div>
            </div>
            <div class="border p-8px mb-4 text-center">
              <p class="font-display text-jacarta-700 text-sm dark:text-white">
                We also develop and support 3D modeling & prototyping
              </p>
            </div>
            <div class="py-3 text-center">
              <button
                class="rounded-full mb-4 bg-accent py-3 px-8 text-center font-semibold text-white shadow-accent-volume transition-all hover:bg-accent-dark mr-3"
                (click)="toContact()">
                Contact Us
              </button>
              <a class="text-white mr-3" href="#">Or</a>
              <button
                class="rounded-full bg-white py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
                data-bs-toggle="modal" data-bs-target="#bookingformModal">
                Book a Demo
              </button>
            </div>
          </div>
          <div
            class="dark:bg-jacarta-700 dark:border-jacarta-600 border-jacarta-100 rounded-2lg border bg-white p-8 mb-4">
            <div class="text-center">
              <h2 class="text-jacarta-700 font-display mb-8 text-center text-3xl font-semibold dark:text-white">
                What is coming!
              </h2>
            </div>
            <div class="mt-3 flex justify-between flex-wrap">
              <figure class="w-25">
                <img [src]="user_manage" alt="" class="rounded-2lg" loading="lazy" />
              </figure>
              <div class="w-75 p-8px">
                <div class="p-8px">
                  <h3 class="font-display text-jacarta-700 text-lg dark:text-white">
                    A User Cutomizable & Manageable AR Cards System
                  </h3>
                </div>
                <div class="p-8px">
                  <p class="dark:text-jacarta-300 text-sm">
                    Suitable for <b>Individuals</b> to have numerous AR Cards
                    for themselves based on the subscription
                  </p>
                </div>
              </div>
            </div>
            <div class="py-3 text-center">
              <button
                class="rounded-full bg-white py-3 px-8 text-center font-semibold text-accent shadow-white-volume transition-all hover:bg-accent-dark hover:text-white hover:shadow-accent-volume"
                data-bs-toggle="modal" data-bs-target="#SubscribeModal">
                Subscribe to hear from us
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="text-center lg:pb-12 pb-12">
    <a class="bg-accent shadow-accent-volume hover:bg-accent-dark inline-block rounded-full py-3 px-8 text-center font-semibold text-white transition-all"
      [href]="ardemoSiteUrl" target="_blank">
      Visit our AR Site to experience Admin Created Sample AR Cards
    </a>
  </div>
</div>
<!-- AR FEATURES START -->
<div class="container">
  <h2 class="font-display text-jacarta-700 mb-8 text-center text-5xl dark:text-white"
    style="text-shadow: 1px 1px #000000">
    AR Cards overall Features!
  </h2>
  <div class="flex flex-col space-y-5 lg:flex-row lg:space-y-0 lg:space-x-7">
    <div class="dark:bg-jacarta-800 bg-light-base rounded-2.5xl p-12 lg:w-1/3">
      <h2 class="text-jacarta-700 font-display mb-8 text-center text-3xl font-semibold dark:text-white">
        AR Rendering
      </h2>
      <div class="flex flex-col space-y-5">
        <div
          class="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent align-items-center">
          <figure class="mr-4 shrink-0">
            <img [src]="video_rendering" alt="" class="rounded-2lg h-12 w-12" />
          </figure>
          <div>
            <span class="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
              Video Rendering
            </span>
            <span class="text-sm dark:text-jacarta-300 block">
              Regular/Curved/Aplha
            </span>
          </div>
        </div>
        <div
          class="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent align-items-center">
          <figure class="mr-4 shrink-0">
            <img [src]="image_rendering" alt="" class="rounded-2lg h-12 w-12" />
          </figure>
          <div>
            <span class="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
              Image Rendering
            </span>
            <span class="text-sm dark:text-jacarta-300 block">
              Regular/Gif/Transp.
            </span>
          </div>
        </div>
        <div
          class="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent align-items-center">
          <figure class="mr-4 shrink-0">
            <img [src]="threed_rendering" alt="" class="rounded-2lg h-12 w-12" />
          </figure>
          <div>
            <span class="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
              3D Rendering
            </span>
            <span class="text-sm dark:text-jacarta-300 block">
              Android/Apple Compat.
            </span>
          </div>
        </div>
        <div
          class="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent align-items-center">
          <figure class="mr-4 shrink-0">
            <img [src]="css_rendering" alt="" class="rounded-2lg h-12 w-12" />
          </figure>
          <div>
            <span class="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
              Text/CSS Rendering
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="dark:bg-jacarta-800 bg-light-base rounded-2.5xl p-12 lg:w-1/3">
      <h2 class="text-jacarta-700 font-display mb-8 text-center text-3xl font-semibold dark:text-white">
        AR Catering
      </h2>
      <div class="flex flex-col space-y-5">
        <div
          class="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent align-items-center">
          <figure class="mr-4 shrink-0">
            <img [src]="image_detect" alt="" class="rounded-2lg h-12 w-12" />
          </figure>
          <div>
            <span class="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
              Image Detection
            </span>
          </div>
        </div>
        <div
          class="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent align-items-center">
          <figure class="mr-4 shrink-0">
            <img [src]="surface_detect" alt="" class="rounded-2lg h-12 w-12" />
          </figure>
          <div>
            <span class="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
              Surface Detection
            </span>
          </div>
        </div>
        <div
          class="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent align-items-center">
          <figure class="mr-4 shrink-0">
            <img [src]="live_preview" alt="" class="rounded-2lg h-12 w-12" />
          </figure>
          <div>
            <span class="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
              Live 3D Preview
            </span>
          </div>
        </div>
        <div
          class="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent align-items-center">
          <figure class="mr-4 shrink-0">
            <img [src]="cross_platform" alt="" class="rounded-2lg h-12 w-12" />
          </figure>
          <div>
            <span class="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
              Custom Device Detection to render AR on any Mobile/Tablet
            </span>
          </div>
        </div>
      </div>
    </div>
    <div class="dark:bg-jacarta-800 bg-light-base rounded-2.5xl p-12 lg:w-1/3">
      <h2 class="text-jacarta-700 font-display mb-8 text-center text-3xl font-semibold dark:text-white">
        AR Analytics
      </h2>
      <div class="flex flex-col space-y-5">
        <div
          class="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent align-items-center">
          <figure class="mr-4 shrink-0">
            <img [src]="organic_analytics" alt="NFT stars" class="rounded-2lg h-12 w-12" />
          </figure>
          <div>
            <span class="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
              Organic AR Data
            </span>
            <span class="text-sm dark:text-jacarta-300 block">
              Real-Time Analytics
            </span>
          </div>
        </div>
        <div
          class="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent align-items-center">
          <figure class="mr-4 shrink-0">
            <img [src]="cross_platform" alt="NFT stars" class="rounded-2lg h-12 w-12" />
          </figure>
          <div>
            <span class="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
              Device Based
            </span>
            <span class="text-sm dark:text-jacarta-300 block"> Analytics </span>
          </div>
        </div>
        <div
          class="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent align-items-center">
          <figure class="mr-4 shrink-0">
            <img [src]="location_based" alt="NFT stars" class="rounded-2lg h-12 w-12" />
          </figure>
          <div>
            <span class="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
              Location Based
            </span>
            <span class="text-sm dark:text-jacarta-300 block"> Analytics </span>
          </div>
        </div>
        <div
          class="border-jacarta-100 dark:bg-jacarta-700 rounded-2xl flex border bg-white py-4 px-7 transition-shadow hover:shadow-lg dark:border-transparent align-items-center">
          <figure class="mr-4 shrink-0">
            <img [src]="browser_based" alt="NFT stars" class="rounded-2lg h-12 w-12" />
          </figure>
          <div>
            <span class="font-display text-jacarta-700 hover:text-accent font-semibold dark:text-white">
              Browser Based
            </span>
            <span class="text-sm dark:text-jacarta-300 block"> Analytics </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- AR FEATURES END -->