<form [formGroup]="bookingForm" (ngSubmit)="bookingSubmit()">
  <div class="mb-4">
    <label for="nameInput" class="form-label" class="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">
      Name<span class="color-red">*</span>
    </label>
    <input name="request_name"
      class="contact-form-input dark:bg-jacarta-700 border-jacarta-300 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
      id="nameInput" type="text" formControlName="request_name" placeholder="Name" [(ngModel)]="name" />
    <div *ngIf="submitted && fvalid.request_name.errors" class="invalid-feedback">
      <div *ngIf="fvalid.request_name.errors.required"></div>
    </div>
    <div *ngIf="submitted && fvalid.request_name.errors" class="invalid-feedback">
      <div *ngIf="fvalid.request_name.errors.name"></div>
    </div>
    <!-- <span *ngIf="request_name?.invalid && request_name?.touched" class="error">Name required</span> -->
  </div>
  <div class="flex space-x-7">
    <div class="mb-4 w-1/2">
      <label for="emailaddress" class="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">Email Address
        <span class="text-red">*</span>
      </label>
      <input name="request_email" type="email" id="emailaddress" formControlName="request_email" name="request_email"
        placeholder="EMail" [(ngModel)]="email"
        class="contact-form-input dark:bg-jacarta-700 border-jacarta-300 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white" />
      <div *ngIf="submitted && fvalid.request_email.errors" class="invalid-feedback">
        <div *ngIf="fvalid.request_email.errors.required">
          Email is required
        </div>
      </div>
      <div *ngIf="submitted && fvalid.request_email.errors" class="invalid-feedback">
        <div *ngIf="fvalid.request_email.errors.email">
          Email must be a valid email address
        </div>
      </div>
    </div>
    <div class="mb-4 w-1/2">
      <label for="phoneInput" class="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">Phone Number
        <span class="text-red">*</span>
      </label>
      <input type="text" autocomplete="off" maxlength="10" pattern="^[0-9()\-+\s]+$"
        class="contact-form-input dark:bg-jacarta-700 border-jacarta-300 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
        id="phoneInput" formControlName="request_phone" name="mobilenumber" placeholder="Phone Number"
        [(ngModel)]="mobilenumber" />
      <div *ngIf="submitted && fvalid.request_phone.errors" class="invalid-feedback">
        <div *ngIf="fvalid.request_phone.errors.required"></div>
      </div>
      <div *ngIf="submitted && fvalid.request_phone.errors" class="invalid-feedback">
        <div *ngIf="fvalid.request_phone.errors.phone"></div>
      </div>
    </div>
  </div>
  <div class="col-lg-8 mb-4">
    <div class="flex space-x-7">
      <div class="mb-4 w-1/2">
        <div class="form-check me-3">
          <input class="form-check-input" type="radio" value="1" formControlName="user_type" (change)="disable()" />
          <label class="form-check-label font-display text-jacarta-700 text-sm dark:text-white">
            Individual
          </label>
        </div>
      </div>
      <div class="mb-4 w-1/2">
        <div class="form-check">
          <input class="form-check-input" type="radio" value="0" formControlName="user_type" (change)="enable()" />
          <label class="form-check-label font-display text-jacarta-700 text-sm dark:text-white">
            Organization
          </label>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="isSelected">
    <div class="col-lg-12">
      <div>
        <div class="mb-3">
          <label for="nameInput" class="form-label font-display text-jacarta-700 mb-1 block text-sm dark:text-white">
            Enterprise/Organization <span class="color-red">*</span></label>
          <input type="text" autocomplete="off"
            class="contact-form-input dark:bg-jacarta-700 border-jacarta-300 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
            id="enterprisenameInput" formControlName="contact_company_name" name="contact_company_name"
            placeholder="Enterprise Name" />
        </div>
      </div>
    </div>
    <div class="mb-5">
      <label for="contactInput" class="form-label"
        class="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">
        Contact Name <span class="color-red">*</span>
      </label>
      <input name="request_name"
        class="contact-form-input dark:bg-jacarta-700 border-jacarta-300 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
        id="contactInput" type="text" formControlName="contact_name" name="contact_name" placeholder="Contact Name"
        [(ngModel)]="cname" />
    </div>
    <div class="flex space-x-7">
      <div class="mb-6 w-1/2">
        <label for="contactemailInput" class="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">Contact
          Email Address
          <span class="text-red">*</span>
        </label>
        <input name="contact_email"
          class="contact-form-input dark:bg-jacarta-700 border-jacarta-300 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
          id="contactemailInput" type="email" formControlName="contact_email" placeholder="Contact Email Address"
          [(ngModel)]="cemail" />
        <div *ngIf="submitted && fvalid.contact_email.errors" class="invalid-feedback">
          <div *ngIf="fvalid.contact_email.errors.required">
            Email is required
          </div>
        </div>
        <div *ngIf="submitted && fvalid.contact_email.errors" class="invalid-feedback">
          <div *ngIf="fvalid.contact_email.errors.email">
            Email must be a valid email address
          </div>
        </div>
      </div>
      <div class="mb-6 w-1/2">
        <label for="contactphoneInput" class="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">Contact
          Phone Number<span class="text-red">*</span>
        </label>
        <input type="text" autocomplete="off" maxlength="10" pattern="^[0-9()\-+\s]+$"
          class="contact-form-input dark:bg-jacarta-700 border-jacarta-300 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
          id="contactphoneInput" formControlName="contact_phone" name="contact_phone" placeholder="Contact Phone Number"
          [(ngModel)]="cmobilenumber" />
      </div>
    </div>
    <div class="col-lg-12 mb-5">
      <div class="form-check">
        <input class="form-check-input" type="checkbox" (change)="onChange($event)" />
        <label class="form-check-label">
          <span class="font-display text-jacarta-700 text-sm dark:text-white">
            Same as above
          </span></label>
      </div>
    </div>
  </div>
  <div class="mb-4">
    <label for="suggestionsBox" class="font-display text-jacarta-700 mb-2 block text-sm dark:text-white">
      Give us a gist on what you are looking for:
      <span class="text-red">*</span>
    </label>
    <input type="text"
      class="contact-form-input dark:bg-jacarta-700 border-jacarta-300 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
      autocomplete="off" id="messageBox" formControlName="request_description" name="request_description" rows="3" />
    <span *ngIf="request_description?.invalid && request_description?.touched" class="error"></span>
  </div>
  <div class="row">
    <div class="col-lg-12">
      <div class="form-check my-3">
        <input class="form-check-input" type="checkbox" value="1" formControlName="newSettler" />
        <label class="form-check-label">
          <span class="font-display text-jacarta-700 text-sm dark:text-white">
            Subscribe Newsettler</span>
        </label>
      </div>
    </div>
  </div>

  <div class="mb-3 flex">
    <div class="flex items-center">
      <div class="col-lg-12"></div>
    </div>
    <div class="ml-auto flex space-x-2">
      <div>
        <button type="button" class="bg-red rounded-full py-2 px-4 text-center font-semibold text-white transition-all"
          data-bs-dismiss="modal">
          Close
        </button>
      </div>
      <div>
        <button type="submit"
          class="bg-green rounded-full py-2 px-4 text-center font-semibold text-white transition-all">
          Submit
        </button>
      </div>
    </div>
  </div>
</form>