import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { ModelService } from "../shared/services/models.service";
import { Title, Meta, DomSanitizer } from "@angular/platform-browser";
import { environment } from "../../environments/environment";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { HttpClient } from "@angular/common/http";
import { SharingService } from "../shared/services/sharing.service";
import { ToastrService } from "ngx-toastr";
import { DeviceDetectorService, DeviceInfo } from "ngx-device-detector";
import { AnalyticsApiService } from "../shared/services/analytics-api.service";

@Component({
  selector: "app-threed-model-detail",
  templateUrl: "./threed-model-detail.component.html",
  styleUrls: ["./threed-model-detail.component.css"],
})
export class ThreedModelDetailComponent implements OnInit {
  id: any;
  host_url: string = "";
  ratingform!: FormGroup;
  ratingModelform!: FormGroup;
  addLikesform!: FormGroup;
  rating!: number;
  deviceInfo!: DeviceInfo;

  ardemos_url: any;
  constructor(
    private modelService: ModelService,
    private activatedRoute: ActivatedRoute,
    private metaTagService: Meta,
    private titleService: Title,
    private formBuilder: FormBuilder,
    private http: HttpClient,
    private toaster: ToastrService,
    private deviceService: DeviceDetectorService,
    public visitorService: AnalyticsApiService
  ) {
    //this.host_url = environment.ardemos_host_url;
    this.host_url = window.location.href;
    this.rating = 0;
    this.ratingform = this.formBuilder.group({
      rating: 0,
    });

    this.ratingModelform = this.formBuilder.group({
      rating_model_id: [""],
      rating_ip: [""],
      rating: 0,
    });

    this.addLikesform = this.formBuilder.group({
      like_model_id: [""],
      like_ip: [""],
      like: 0,
    });
  }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.params["id"];
    this.getModelbyId(this.id);
    this.getIPAddress();

    // Device Details
    this.deviceInfo = this.deviceService.getDeviceInfo();
    // console.log(this.deviceInfo);
    this.ardemos_url = environment.ardemos_host_url;
  }

  //  GetModel by id
  specific_model_json_data: any;
  err_specific_model_json_data: any;
  specific_models_data: any = [];
  modelAnimationNames: any = [];
  getModelbyId(model_id: any) {
    this.modelService.getModelbyId(model_id).subscribe({
      next: (val) => {
        this.specific_model_json_data = val;
        // console.log(this.specific_model_json_data);
      },
      error: (err) => {
        // console.log(err);
        this.err_specific_model_json_data = err;
      },
      complete: () => {
        if (this.specific_model_json_data.status == 1) {
          this.specific_models_data = this.specific_model_json_data.models[0];
          this.modelAnimationNames =
            this.specific_models_data.model_animation_names;
          console.log(this.specific_models_data);

          this.specific_models_data.modelSurfaceQRURL =
            this.ardemos_url + "/" + this.specific_models_data.model_s_qr_url;
          this.specific_models_data.modelImageQRURL =
            this.ardemos_url + "/" + this.specific_models_data.model_i_qr_url;

          if (environment.production == false) {
            // Surface AR
            this.specific_models_data.modelSurfaceQRImage =
              this.specific_models_data.model_s_qr_d;
            // ImageAR
            this.specific_models_data.modelImageQRImage =
              this.specific_models_data.model_i_qr_d;
          } else if (environment.production == true) {
            // Surface AR
            this.specific_models_data.modelSurfaceQRImage =
              this.specific_models_data.model_s_qr_p;
            // Image AR
            this.specific_models_data.modelImageQRImage =
              this.specific_models_data.model_i_qr_p;
          }
          //console.log(" this.specific_models_data", this.specific_models_data)
          //this.addMetaTags(this.specific_models_data);
        }
      },
    });
  }

  // title: any;
  // addMetaTags(modeldata: any) {
  //   this.titleService.setTitle(modeldata.model_name);
  //   //og
  //   this.metaTagService.updateTag({
  //     property: "og:title",
  //     content: modeldata.model_name,
  //   });

  //   this.metaTagService.updateTag({
  //     property: "og:description",
  //     content: "Powered by Immersion Software Labs Pvt. Ltd.",
  //   });

  //   this.metaTagService.updateTag({
  //     property: "og:image",
  //     content: modeldata.model_marker,
  //   });

  //   this.metaTagService.updateTag({
  //     property: "og:url",
  //     content: this.host_url,
  //   });
  //   //twitter
  //   this.metaTagService.updateTag({
  //     name: "twitter:title",
  //     content: modeldata.model_name,
  //   });

  //   this.metaTagService.updateTag({
  //     name: "twitter:url",
  //     content: this.host_url,
  //   });

  //   this.metaTagService.updateTag({
  //     name: "twitter:image",
  //     content: modeldata.model_marker,
  //   });

  //   this.metaTagService.updateTag({
  //     name: "twitter:description",
  //     content: "Powered by Immersion Software Labs Pvt. Ltd.",
  //   });

  //   this.metaTagService.updateTag({
  //     name: "title",
  //     content: modeldata.model_name,
  //   });

  //   this.metaTagService.updateTag({
  //     name: "image",
  //     content: modeldata.model_marker,
  //   });

  //   this.metaTagService.updateTag({
  //     name: "description",
  //     content: "Powered by Immersion Software Labs Pvt. Ltd.",
  //   });

  //   this.metaTagService.updateTag({
  //     name: "keywords",
  //     content: modeldata.model_tags,
  //   });
  // }

  // Threed button click popup calls
  surfacearStatus() { }

  ratingResponse: any;
  sendStarRating() {
    let rating_Value = this.ratingform.value.rating;

    if (rating_Value != 0 || rating_Value != "") {
      let rating = Object.assign(this.ratingModelform.value, {
        rating: Number(rating_Value),
      });
      let rating_model_id = Object.assign(this.ratingModelform.value, {
        rating_model_id: this.id,
      });
      let rating_ip = Object.assign(this.ratingModelform.value, {
        rating_ip: this.ipAddress,
      });
      //console.log("formValue:",this.ratingModelform.value);
      this.modelService.addRating(this.ratingModelform.value).subscribe({
        next: (val: any) => {
          this.ratingResponse = val;
        },
        error: (err: any) => {
          // console.log(err);
          this.err_specific_model_json_data = err;
        },
        complete: () => {
          if (this.ratingResponse.status == 1) {
            this.toaster.success(this.ratingResponse.message);
            this.ratingform.patchValue({
              rating: 0,
            });
          } else {
            this.toaster.error(this.ratingResponse.message);
          }
        },
      });
    }
  }

  likeModel() {
    let like = Object.assign(this.addLikesform.value, {
      like: Number(1),
    });
    this.addLikesandDislikes();
  }

  disLikeModel() {
    let like = Object.assign(this.addLikesform.value, {
      like: Number(0),
    });
    this.addLikesandDislikes();
  }

  addLikesResponse: any;
  addLikesandDislikes() {
    let like_model_id = Object.assign(this.addLikesform.value, {
      like_model_id: this.id,
    });
    let like_ip = Object.assign(this.addLikesform.value, {
      like_ip: this.ipAddress,
    });
    //console.log("likes form Value:",this.addLikesform.value);
    this.modelService.addModelLikes(this.addLikesform.value).subscribe({
      next: (val: any) => {
        this.addLikesResponse = val;
      },
      error: (err: any) => {
        // console.log(err);
        this.err_specific_model_json_data = err;
      },
      complete: () => {
        if (this.addLikesResponse.status == 1) {
          console.log(this.addLikesResponse.message);
        } else {
          console.log(this.addLikesResponse.message);
        }
      },
    });
  }

  visitorIpResponse: any;
  ipAddress: any;
  getIPAddress() {
    this.visitorService.getIp().subscribe({
      next: (res: any) => {
        this.visitorIpResponse = res;
        if (this.visitorIpResponse.status == 1) {
          this.ipAddress = this.visitorIpResponse.ip;
          // console.log("visitor ip", this.ipAddress);
        }
      },
      error: (err: any) => {
        // console.log("error",err);
      },
    });
  }

  addImageARClick() {
    // this.visitorIpService.add2DdemoVisitor(this.ipAddress, this.id).subscribe({
    //   next:(val: any)=>{
    //     console.log(val)
    //   },
    //   error:(err)=>{
    //     console.log(err)
    //   }
    // })
  }

  addSurfaceARClick() {
    //   this.visitorIpService.add2DdemoVisitor(this.ipAddress, this.id).subscribe({
    //     next:(val: any)=>{
    //       console.log(val)
    //     },
    //     error:(err)=>{
    //       console.log(err)
    //     }
    //   })
  }
}
