<form novalidate [formGroup]="feedbackForm" (ngSubmit)="feedbackData()" class="mt-5">
  <div class="mb-4">
    <label for="nameInput" class="form-label" class="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">
      Name
      <span class="color-red">*</span>
    </label>
    <input name="feedback_name"
      class="contact-form-input dark:bg-jacarta-700 border-jacarta-300 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
      id="nameInput" type="text" formControlName="feedback_name" placeholder="Name" required />
    <span *ngIf="feedback_name?.invalid && feedback_name?.touched" class="error">
      Name required
    </span>
  </div>

  <div class="mb-4">
    <label for="emailaddress" class="form-label"
      class="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">
      Email Address
      <span class="color-red">*</span>
    </label>
    <input name="feedback_email"
      class="contact-form-input dark:bg-jacarta-700 border-jacarta-300 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
      id="emailaddress" type="email" formControlName="feedback_email" placeholder="Email" required />
    <span *ngIf="feedback_email?.invalid && feedback_email?.touched" class="error">
      Email required
    </span>
  </div>

  <div class="col-lg-4 mb-4">
    <p class="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">
      Did you like the demo?
    </p>
  </div>

  <div class="col-lg-8 mb-4">
    <div class="flex space-x-7">
      <div class="mb-6 w-1/2">
        <div class="form-check me-3">
          <input class="form-check-input" type="radio" value="1" formControlName="demo_like" />
          <label class="form-check-label font-display text-jacarta-700 text-sm dark:text-white">
            Yes
          </label>
        </div>
      </div>
      <div class="mb-6 w-1/2">
        <div class="form-check">
          <input class="form-check-input" type="radio" value="0" formControlName="demo_like" />
          <label class="form-check-label font-display text-jacarta-700 text-sm dark:text-white">
            No
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="mb-4">
    <label for="suggestionsBox" class="font-display text-jacarta-700 mb-2 block text-sm dark:text-white">
      Any feedbacks or Suggestions that you would like to provide ?
      <span class="text-red">*</span>
    </label>
    <textarea id="suggestionsBox"
      class="contact-form-input dark:bg-jacarta-700 border-jacarta-300 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
      required name="feedback" type="text" formControlName="feedback" rows="2">
    </textarea>
  </div>

  <div class="col-lg-4 mb-4">
    <p class="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">
      Would you like to have this demo for yourself?
    </p>
  </div>

  <div class="col-lg-8 mb-2">
    <div class="flex space-x-7">
      <div class="mb-6 w-1/2">
        <div class="form-check me-3">
          <input class="form-check-input" type="radio" value="1" formControlName="demo_type" (change)="enable()" />
          <label class="form-check-label font-display text-jacarta-700 text-sm dark:text-white">
            Yes
          </label>
        </div>
      </div>
      <div class="mb-6 w-1/2">
        <div class="form-check">
          <input class="form-check-input" type="radio" value="0" formControlName="demo_type" (change)="disable()" />
          <label class="form-check-label font-display text-jacarta-700 text-sm dark:text-white">
            No
          </label>
        </div>
      </div>
    </div>
  </div>

  <div class="col-lg-12">
    <div *ngIf="!isSelected"></div>
    <div *ngIf="isSelected">
      <div class="mb-5">
        <p class="font-display text-jacarta-700 mb-1 block text-md" style="color: rgb(18, 247, 163)">
          Our sales team will come in touch with you the earliest to the email address provided.
        </p>
      </div>
    </div>
  </div>

  <div class="col-lg-4 mb-4">
    <p class="font-display text-jacarta-700 mb-1 block text-sm dark:text-white">
      Would you like to have this use-case implemented for any of your peers ?
      (Optional)
    </p>
  </div>

  <div class="col-lg-8 mb-4">
    <div class="flex space-x-7">
      <div class="mb-6 w-1/2">
        <div class="form-check me-3">
          <input class="form-check-input" type="radio" value="1" formControlName="refertype"
            (change)="referalEnable()" />
          <label class="form-check-label font-display text-jacarta-700 text-sm dark:text-white">
            Yes
          </label>
        </div>
      </div>
      <div class="mb-6 w-1/2">
        <div class="form-check">
          <input class="form-check-input" type="radio" value="0" formControlName="refertype"
            (change)="referalDisable()" />
          <label class="form-check-label font-display text-jacarta-700 text-sm dark:text-white">
            No
          </label>
        </div>
      </div>
    </div>
    <div class="row" *ngIf="peersSelected">
      <div formArrayName="feedback_referals">
        <div class="mb-3">
          <button type="button" (click)="addReferal()"
            class="bg-accent shadow-accent-volume hover:bg-accent-dark rounded-full py-2 px-8 text-center font-semibold text-white transition-all">
            Add Referal <i class="fa fa-plus"></i>
          </button>
        </div>
        <div class="grid grid-cols-1 gap-[1.875rem] sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-3" *ngFor="
            let quantity of referals().controls.slice(0, 5);
            let i = index
          " [formGroupName]="i">
          <div class="col-lg-5">
            <div class="mb-3">
              <label for="referalnameInput3" class="form-label font-display text-jacarta-700 text-sm dark:text-white">
                Referal Name
              </label>
              <input name="name" autocomplete="off"
                class="contact-form-input dark:bg-jacarta-700 border-jacarta-300 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
                type="text" formControlName="name" placeholder="Referal Name" />
            </div>
          </div>
          <div class="col-lg-5">
            <div class="mb-3">
              <label for="referalEmail" class="form-label font-display text-jacarta-700 text-sm dark:text-white">
                Referal Email Address
              </label>
              <input name="email" autocomplete="off" id="referalEmail"
                class="contact-form-input dark:bg-jacarta-700 border-jacarta-300 hover:ring-accent/10 focus:ring-accent dark:border-jacarta-600 dark:placeholder:text-jacarta-300 w-full rounded-lg py-3 hover:ring-2 dark:text-white"
                type="text" formControlName="email" placeholder="Referal Email" />
            </div>
          </div>
          <div class="col-lg-2" style="margin-top: 1.5rem">
            <div class="mb-3">
              <button (click)="removeReferal(i)"
                class="bg-red rounded-full py-1 px-2 text-center font-semibold text-white transition-all">
                X
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-12">
        <div class="form-check my-3">
          <input class="form-check-input" type="checkbox" value="1" formControlName="newSettler" />
          <label class="form-check-label">
            <span class="font-display text-jacarta-700 text-sm dark:text-white">
              Subscribe to Newsettler
            </span>
          </label>
        </div>
      </div>
    </div>
  </div>
  <div class="mb-3 flex">
    <div class="flex items-center">
      <div class="col-lg-12">
        <!-- <div class="form-check my-3">
          <input
            class="form-check-input"
            type="checkbox"
            value="1"
            formControlName="newSettler" />
          <label class="form-check-label">
            <span class="font-display text-jacarta-700 text-sm dark:text-white">
              Subscribe Newsettler
            </span>
          </label>
        </div> -->
      </div>
    </div>
    <div class="ml-auto flex space-x-2">
      <div>
        <button type="button" class="bg-red rounded-full py-2 px-4 text-center font-semibold text-white transition-all"
          data-bs-dismiss="modal">
          Close
        </button>
      </div>
      <div>
        <button type="submit"
          class="bg-green rounded-full py-2 px-4 text-center font-semibold text-white transition-all">
          Submit
        </button>
      </div>
    </div>
  </div>
</form>