import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Router } from "@angular/router";
// import { ThreejsGlbService } from "../shared/threejs-glb.service";
import { environment } from "../../environments/environment";
import { S3ResourceService } from "../shared/services/s3-resource.service";

@Component({
  selector: "app-landing-section",
  templateUrl: "./landing-section.component.html",
  styleUrls: ["./landing-section.component.css"],
})
export class LandingSectionComponent implements OnInit {
  ardemoSiteUrl: any;
  constructor(
    // private threeService: ThreejsGlbService,
    private router: Router,
    public s3serviceurl: S3ResourceService
  ) {
    this.ardemoSiteUrl = environment.ardemos_host_url;
  }

  modelId = "whiterobo";
  glbPath =
    environment.cloudFrontURL +
    "common/assets/glb/white_robot_uncompressed.glb";
  // animationNames: any = {
  //   animation1: "Logo",
  //   animation2: "saber_action",
  //   animation3: "sheild",
  // };

  gradient_dark: string = "";
  gradient: string = "";

  ngOnInit(): void {
    this.gradient = this.s3serviceurl.gradient;
    this.gradient_dark = this.s3serviceurl.gradient_dark;
    // this.threeService.loadGlb(this.modelId, this.glbPath, "sheild");
  }

  afterInit: boolean = false;
  ngAfterViewInit() { }
  ngAfterContentInit() {
    this.afterInit = true;
  }
}
